import { COLORS } from "../../common/constants";

const styles = {
  formControl: {
    "& .MuiInputBase-formControl": {
      border: "none",
      color: "#181B20",
      font: "normal normal 500 16px/24px SofiaPro",
      alignItems: "center",
      display: "flex",
      height: "55px",
      "& fieldset": {
        borderRadius: "0px",
        borderColor: COLORS.black,
      },
      "&:hover fieldset": {
        borderColor: COLORS.borderGrey,
      },
      "& .MuiSelect-select::placeholder fieldset": {
        borderColor: "#181B20",
      },
      "&.Mui-focused fieldset": {
        borderColor: COLORS.borderGrey,
      },
    },
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
      border: "2px solid #E71515",
    },
  },
  select: {
    "& .search-item.Mui-selected": {
      background: "white",
    },
    "& .search-item.Mui-focusVisible": {
      background: "white",
    },
    "& .search-item.Mui-selected:hover": {
      background: "white",
    },
    "& li": {
      padding: 0,
    },
  },
  error: {
    color: "#d32f2f",
    font: "normal normal 400 14px/18px SofiaPro !important",
  },
  searchBox: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    background: "white",
    borderRadius: "6px",
    border: "1px solid #000000",
    justifyContent: "space-between",
    "& img": {
      height: "25px",
      width: "60px",
    },
  },
  searchInput: {
    width: "100%",
    height: "42px",
    border: "none",
    outline: "none",
    cursor: "pointer",
    borderRadius: "6px",
    fontSize: "16px",
    font: "normal normal 400 16px/18px SofiaPro",
  },
  menuItem: {
    "& div": {
      "& span": {
        font: "normal normal 400 16px/18px SofiaPro",
      },
    },
  },
  errorIcon: {
    color: `${COLORS.themeError} !important`,
    right: "26px !important",
  },
  errorTooltip: {
    maxWidth: "250px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    padding: "20px",
    background: COLORS.themeError,
    font: "normal normal 500 16px/16px SofiaPro",
  },
  label: {
    font: "normal normal 500 14px/14px SofiaPro",
    letterSpacing: "0.59px",
    transition: "ease-in 0.5s",
    color: COLORS.inputHead,
  },
  none: {
    visibility: "hidden",
  },
  paper: {
    maxHeight: "175px",
  },
};

export default styles;
