import { Box } from "@mui/material";
import { styles } from "./style";

/**
 * Renders a container for cards with custom styles.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {React.ReactNode} props.childComp - The child components to be rendered within the container.
 * @returns {React.ReactNode} The rendered component.
 */
const Cards = ({ childComp }: any) => {
  return <Box sx={styles.cardMainContainer}>{childComp}</Box>;
};

export default Cards;
