import { AxiosResponse } from "axios";
import { Dispatch } from "@reduxjs/toolkit";
import { EndPoint } from "../../../common/constants";
import { postApiCall } from "../../../common/constants/api";
import { apiSuccessCode } from "../../../common/constants/axios.instance";
import { showAlert } from "../../../common/utils/alert";
import { setLoading } from "../../../redux/global.slice";

/**
 * Action function to get link to reset password.
 * @param {object} values - User login form values.
 * @param {function} setSubmitting - Function to set the submitting state.
 * @param {function} callback - callback function on success.
 */
export const forgotPassword =
  (values: any, setSubmitting: any, callback?: () => void) =>
  (dispatch: Dispatch<any>) => {
    if (!navigator.onLine) {
      showAlert(2, "No internet connection!");
      return;
    }
    setSubmitting(true);
    dispatch(setLoading(false));
    postApiCall(
      EndPoint.forgetPassword,
      values,
      (respData: AxiosResponse<any>) => {
        const { data } = respData;
        if (data?.statusCode === apiSuccessCode.success) {
          if (callback) callback();
          localStorage.setItem("email", values.email);
          localStorage.setItem("userId", data?.data?.userId);
        }
        dispatch(setLoading(false));
        setSubmitting(false);
      },
      (error: any) => {
        dispatch(setLoading(false));
        setSubmitting(false);
        showAlert(2, error.message);
      }
    );
  };
