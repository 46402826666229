import { useEffect, useState } from "react";

/**
 * Custom hook for managing a timer in a React component.
 * It provides functions to create timer.
 */

const useTimer = (min: number, sec: number) => {
  const [minutes, setMinutes] = useState(min);
  const [seconds, setSeconds] = useState(sec);

  const updateTime = () => {
    if (minutes === 0 && seconds === 0) {
      return;
    } else {
      if (seconds === 0) {
        setMinutes((minutes) => minutes - 1);
        setSeconds(59);
      } else {
        setSeconds((seconds) => seconds - 1);
      }
    }
  };

  useEffect(() => {
    // use set timeout and be confident because updateTime will cause rerender
    // rerender mean re call this effect => then it will be similar to how setinterval works
    // but with easy to understand logic
    const token = setTimeout(updateTime, 1000);

    return () => {
      clearTimeout(token);
    };
  });

  return { minutes, seconds, setMinutes, setSeconds };
};

export default useTimer;
