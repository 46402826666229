// This file contains an action function for resetting a user's password.

import { AxiosResponse } from "axios";
import { Dispatch } from "@reduxjs/toolkit";
import { EndPoint } from "../../../common/constants";
import { postApiCall } from "../../../common/constants/api";
import { apiSuccessCode } from "../../../common/constants/axios.instance";
import { showAlert } from "../../../common/utils/alert";
import { setLoading } from "../../../redux/global.slice";

/**
 * Action function to handle resetting a user's password.
 * @param {object} values - Password reset form values.
 * @param {function} setSubmitting - Function to set the submitting state.
 * @param {function} callback - Optional callback function.
 */
export const resetPassword =
  (values: any, setSubmitting: any, callback?: () => void) =>
  (dispatch: Dispatch<any>) => {
    const userId = localStorage.getItem("userId") || "";
    const dataToSend = { password: values.password };

    // Check for internet connection
    if (!navigator.onLine) {
      showAlert(2, "No internet connection!");
      return;
    }

    setSubmitting(true);
    dispatch(setLoading(false));

    // Make API call to reset password
    postApiCall(
      `${EndPoint.resetPassword}/${userId}`,
      dataToSend,
      (respData: AxiosResponse<any>) => {
        const { data } = respData;

        if (data?.statusCode === apiSuccessCode.success) {
          if (callback) callback(); // Invoke the callback if provided
        }

        localStorage.removeItem("userId");
        dispatch(setLoading(false));
        setSubmitting(false);
      },
      (error: any) => {
        dispatch(setLoading(false));
        setSubmitting(false);
        showAlert(2, error.message); // Display error alert
      }
    );
  };
