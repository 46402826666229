export const styles = {
  personalDetails_mainContainer: {
    "& > div": {
      width: "100%",
      "& > div": {
        border: "none",
        boxShadow: "none",
      },
    },
  },
};
