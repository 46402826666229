import { removeSession } from "../utils/session";
import { apiErrorCode, $axios, API_URL, $axios1 } from "./axios.instance";

const StatusCode = apiErrorCode;

/**
 * Function for checking user validation based on the provided data object.
 * @param {any} data - The data object to be checked.
 * @returns {boolean} - Indicates whether the user's data is valid.
 */
export const checkUserValidation = (data: any) => {
  if (data && data.statusCode) {
    const { statusCode: stc } = data;
    return (
      stc === StatusCode.SESSION_EXPIRED ||
      stc === StatusCode.INVALID_TOKEN ||
      stc === StatusCode.ACCOUNT_BLOCKED
    );
  }
  return false;
};

/**
 * Function for making a GET request to an API using Axios.
 * @param {string} endPoint - The API endpoint URL.
 * @param {any} params - The request parameters.
 * @param {Function} successCallback - The callback function to handle successful response.
 * @param {Function} errorCallback - The callback function to handle error response.
 * @returns {void}
 */
const getApiCall = (
  endPoint: string,
  params: any,
  successCallback: Function,
  errorCallback: Function
) => {
  $axios
    .get(API_URL + endPoint + params, {})
    .then((res: any) => {
      successCallback(res);
    })
    .catch((err: any) => {
      if (err.code === "ECONNABORTED") {
        errorCallback({
          data: {
            statusCode: 408,
          },
        });
      } else if (checkUserValidation(err.response.data)) {
        removeSession();
        errorCallback(err.response.data);
      } else if (
        err.response.data &&
        err.response.data.statusCode === apiErrorCode.USER_BLOCKED
      ) {
        localStorage.clear();
        errorCallback(err.response.data);
      } else if (err.response) {
        errorCallback(err.response.data);
      } else if (!err.response) {
        errorCallback({
          data: {
            statusCode: "",
            message: "Please try again later",
          },
        });
      }
    });
};

/**
 * Function for making a POST request to an API using Axios.
 * @param {string} endPoint - The API endpoint URL.
 * @param {object} dataToSend - The data to send in the request body.
 * @param {Function} successCallback - The callback function to handle successful response.
 * @param {Function} errorCallback - The callback function to handle error response.
 * @returns {void}
 */
const postApiCall = (
  endPoint: string,
  dataToSend: { [key: string]: any },
  successCallback: Function,
  errorCallback: Function
) => {
  $axios
    .post(API_URL + endPoint, dataToSend)
    .then((res: any) => {
      successCallback(res);
    })
    .catch((err: any) => {
      if (err.code === "ECONNABORTED") {
        errorCallback({
          data: {
            statusCode: 408,
          },
        });
      } else if (checkUserValidation(err.response?.data)) {
        removeSession();
        errorCallback(err.response?.data);
      } else if (
        err.response?.data &&
        err.response?.data.statusCode === apiErrorCode.USER_BLOCKED
      ) {
        localStorage.clear();
        errorCallback(err.response?.data);
      } else if (err.response) {
        errorCallback(err.response?.data);
      } else if (!err.response) {
        errorCallback({
          data: {
            statusCode: "",
            message: "Please try again later",
          },
        });
      }
    });
};

/**
 * Function for making a PUT request to an API using Axios.
 * @param {string} endPoint - The API endpoint URL.
 * @param {object} params - The parameters to send in the request body.
 * @param {Function} successCallback - The callback function to handle successful response.
 * @param {Function} errorCallback - The callback function to handle error response.
 * @returns {void}
 */
const putApiCall = (
  endPoint: string,
  params: { [key: string]: any },
  successCallback: Function,
  errorCallback: Function
) => {
  $axios
    .put(API_URL + endPoint, params)
    .then((res: any) => {
      successCallback(res);
    })
    .catch((err: any) => {
      if (err.code === "ECONNABORTED") {
        errorCallback({
          data: {
            statusCode: 408,
          },
        });
      } else if (checkUserValidation(err.response.data)) {
        removeSession();
        errorCallback(err.response.data);
      } else if (
        err.response.data &&
        err.response.data.statusCode === apiErrorCode.USER_BLOCKED
      ) {
        localStorage.clear();
        errorCallback(err.response.data);
      } else if (err.response) {
        errorCallback(err.response.data);
      } else if (!err.response) {
        errorCallback({
          data: {
            statusCode: "",
            message: "Please try again later",
          },
        });
      }
    });
};

/**
 * Function for making a PATCH request to an API using Axios.
 * @param {string} endPoint - The API endpoint URL.
 * @param {object|string} params - The parameters to send in the request body.
 * @param {Function} successCallback - The callback function to handle successful response.
 * @param {Function} errorCallback - The callback function to handle error response.
 * @returns {void}
 */
const patchApiCall = (
  endPoint: string,
  params: { [key: string]: any } | string,
  successCallback: Function,
  errorCallback: Function
) => {
  $axios
    .patch(API_URL + endPoint, params)
    .then((res: any) => {
      successCallback(res);
    })
    .catch((err: any) => {
      if (err.code === "ECONNABORTED") {
        errorCallback({
          data: {
            statusCode: 408,
          },
        });
      } else if (checkUserValidation(err.response.data)) {
        removeSession();
        errorCallback(err.response.data);
      } else if (
        err.response.data &&
        err.response.data.statusCode === apiErrorCode.USER_BLOCKED
      ) {
        localStorage.clear();
        errorCallback(err.response.data);
      } else if (err.response) {
        errorCallback(err.response.data);
      } else if (!err.response) {
        errorCallback({
          data: {
            statusCode: "",
            message: "Please try again later",
          },
        });
      }
    });
};

/**
 * Function for making a DELETE request to an API using Axios.
 * @param {string} endPoint - The API endpoint URL.
 * @param {object} params - The parameters to send in the request URL.
 * @param {Function} successCallback - The callback function to handle successful response.
 * @param {Function} errorCallback - The callback function to handle error response.
 * @returns {void}
 */
const deleteApiCall = (
  endPoint: string,
  params: any,
  successCallback: Function,
  errorCallback: Function
) => {
  $axios
    .delete(API_URL + endPoint + params, {})
    .then((res: any) => {
      successCallback(res);
    })
    .catch((err: any) => {
      if (err.code === "ECONNABORTED") {
        errorCallback({
          data: {
            statusCode: 408,
          },
        });
      } else if (checkUserValidation(err.response.data)) {
        removeSession();
        errorCallback(err.response.data);
      } else if (
        err.response.data &&
        err.response.data.statusCode === apiErrorCode.USER_BLOCKED
      ) {
        localStorage.clear();
        errorCallback(err.response.data);
      } else if (err.response) {
        errorCallback(err.response.data);
      } else if (!err.response) {
        errorCallback({
          data: {
            statusCode: "",
            message: "Please try again later",
          },
        });
      }
    });
};

/**
 * Function for making a PUT request to an S3 API using Axios.
 * @param {string} endPoint - The API endpoint URL.
 * @param {object} params - The request parameters and data.
 * @param {Function} successCallback - The callback function to handle successful response.
 * @param {Function} errorCallback - The callback function to handle error response.
 * @returns {void}
 */

const putS3ApiCall = (
  endPoint: string,
  params: { [key: string]: any },
  successCallback: Function,
  errorCallback: Function
) => {
  $axios1
    .post(endPoint, params, { headers: { "Content-Type": params.type } })
    .then((res: any) => {
      successCallback(res);
    })
    .catch((err: any) => {
      if (err.code === "ERR_NETWORK") {
        errorCallback({
          statusCode: "",
          message: err.message,
        });
      } else if (err.code === "ECONNABORTED") {
        errorCallback({
          data: {
            statusCode: 408,
          },
        });
      } else if (checkUserValidation(err.response.data)) {
        removeSession();
        errorCallback(err.response.data);
      } else if (err.response) {
        errorCallback(err.response.data);
      } else if (!err.response) {
        errorCallback({
          data: {
            statusCode: "",
            message: "Please try again later",
          },
        });
      }
    });
};

export {
  getApiCall,
  postApiCall,
  putApiCall,
  patchApiCall,
  deleteApiCall,
  putS3ApiCall,
};
