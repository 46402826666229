import { Box } from "@mui/material";
import { Form, Formik } from "formik";

import Input from "../../formControls/input";
import ContainedButton from "../../button/contained-button";
import OutlinedButton from "../../button/outlined-button";
import styles from "./styles";

import Schema from "../../../common/constants/schema";
import { addExternalCard } from "../../../pages/fund.card/services/action";
import { Paths } from "../../../common/constants";
import AddCardhelper from "./helper";

/**
 * Renders a Add Card Form for adding card.
 * @returns {React.ReactNode} The rendered component.
 */
const AddCardForm = () => {
  const {
    id,
    type,
    dispatch,
    navigate,
    initialValues,
    custodialAccountDetails,
  } = AddCardhelper();

  return (
    <Box sx={styles.addForm_mainContainer}>
      <Formik
        initialValues={initialValues}
        validationSchema={Schema.AddCardSchema}
        onSubmit={(values, { setSubmitting }) => {
          dispatch(
            addExternalCard(
              values,
              custodialAccountDetails.ownerIdentityId,
              setSubmitting,
              () =>
                navigate(
                  `${Paths.FORTRESS_ADD_FUND_CARD}?type=${type}&id=${id}`
                )
            )
          );
        }}
      >
        {() => {
          return (
            <Form>
              <Box sx={styles.formContainer}>
                <Input
                  name="firstName"
                  type="text"
                  label="First Name"
                  placeholder="First Name"
                  fullWidth
                  isRequired
                  externalStyles={styles.textField}
                />
                <Input
                  name="lastName"
                  type="text"
                  label="Last Name"
                  placeholder="Last Name"
                  fullWidth
                  isRequired
                  externalStyles={styles.textField}
                />

                <Input
                  name="street1"
                  type="text"
                  label="Street 1"
                  placeholder="Street 1"
                  fullWidth
                  isRequired
                  externalStyles={styles.textField}
                />
                <Input
                  name="street2"
                  type="text"
                  label="Street 2"
                  placeholder="Street 2"
                  fullWidth
                  isRequired
                  externalStyles={styles.textField}
                />
                <Input
                  name="postalCode"
                  type="text"
                  label="Postal Code"
                  placeholder="Postal Code"
                  fullWidth
                  isRequired
                  externalStyles={styles.textField}
                />
                <Input
                  name="city"
                  type="text"
                  label="City"
                  placeholder="City"
                  fullWidth
                  isRequired
                  externalStyles={styles.textField}
                />
                <Input
                  name="state"
                  type="text"
                  label="State"
                  placeholder="State"
                  fullWidth
                  isRequired
                  externalStyles={styles.textField}
                />

                <Input
                  name="country"
                  type="text"
                  label="Country"
                  placeholder="Country"
                  fullWidth
                  isRequired
                  externalStyles={styles.textField}
                />
                <Input
                  name="number"
                  type="text"
                  label="Card Number"
                  placeholder="Card Number"
                  fullWidth
                  isRequired
                  externalStyles={styles.textField}
                  inputProps={{ maxLength: 16 }}
                />

                <Input
                  name="expirationMonth"
                  type="number"
                  label="Expiration Month"
                  placeholder="Expiration Month"
                  fullWidth
                  isRequired
                  externalStyles={styles.textField}
                />

                <Input
                  name="expirationYear"
                  type="number"
                  label="Expiration Year"
                  placeholder="Expiration Year"
                  fullWidth
                  isRequired
                  externalStyles={styles.textField}
                />

                <Input
                  name="cvv"
                  type="text"
                  label="CVV"
                  placeholder="CVV"
                  fullWidth
                  isRequired
                  externalStyles={styles.textField}
                />
              </Box>

              <Box sx={styles.btnContainer}>
                <ContainedButton
                  title={"Save"}
                  externalStyles={styles.btn}
                  size="large"
                  type="submit"
                  fullWidth
                />
                <OutlinedButton
                  title="Cancel"
                  externalStyles={styles.btn}
                  size="large"
                  fullWidth
                  onClick={() => {
                    navigate(-1);
                  }}
                />
              </Box>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default AddCardForm;
