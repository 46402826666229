import axios, { AxiosResponse } from "axios";
import { Dispatch } from "@reduxjs/toolkit";

import { EndPoint, Env } from "../../../common/constants";
import { postApiCall, getApiCall } from "../../../common/constants/api";
import { apiSuccessCode } from "../../../common/constants/axios.instance";
import { showAlert } from "../../../common/utils/alert";
import { setLoading } from "../../../redux/global.slice";
import { setPersonalDetails } from "./slice";
import { getBusinessCustodialAccountListingFortress } from "../../fo.business.id/services/action";
import { getProfile } from "../../login/services/action";

// Action function to add custodial fortress account.
export const addCustodialFortressAccount =
  (values: any, setSubmitting: any) => (dispatch: Dispatch<any>) => {
    dispatch(setLoading(true));
    const {
      dateOfBirth,
      ssn,
      street1,
      street2,
      postalCode,
      city,
      state,
      country,
    } = values;

    const dataToSend = {
      dateOfBirth,
      ssn,
      street1,
      street2,
      postalCode,
      city,
      state,
      country,
      platformType: 1,
    };

    postApiCall(
      EndPoint.custodialAccountFortress,
      dataToSend,
      (respData: AxiosResponse<any>) => {
        if (respData.data.statusCode === apiSuccessCode.success) {
          showAlert(1, respData.data.message);
          dispatch(getProfile());
        }
        setSubmitting(false);
        dispatch(setLoading(false));
      },
      (error: any) => {
        dispatch(setLoading(false));
        setSubmitting(false);
        showAlert(2, error.message);
      }
    );
  };

// Action function to get oauth token fortress.
export const getOAuthTokenFortress = () => (dispatch: Dispatch<any>) => {
  if (!navigator.onLine) {
    showAlert(2, "No internet connection!");
    return;
  }

  dispatch(setLoading(true));
  getApiCall(
    EndPoint.OAuthTokenClientFortress,
    "",
    (response: AxiosResponse<any>) => {
      const { statusCode, data } = response.data;
      if (statusCode === apiSuccessCode.success) {
        localStorage.setItem("auth_token_client_fortress", data?.access_token);
      }
      dispatch(setLoading(false));
    },
    (error: any) => {
      dispatch(setLoading(false));
      showAlert(2, error.message);
    }
  );
};

// Action function to upload document.
export const uploadDocumentFortress =
  (values: any, identityId?: any, callback?: () => void) =>
  (dispatch: Dispatch<any>) => {
    dispatch(setLoading(true));

    const token = localStorage.getItem("auth_token");

    const formdata = new FormData();
    formdata.append("DocumentType", values.doc_type);
    formdata.append("DocumentFront", values.front);
    formdata.append("DocumentBack", values.back);
    formdata.append("identityId", identityId);

    const config = {
      method: "post",
      url: Env.BASE_URL + EndPoint.uploadFile,
      headers: {
        Authorization: `Bearer ${token} `,
      },
      data: formdata,
    };

    axios(config)
      .then((response: AxiosResponse<any>) => {
        showAlert(1, "Documenets Uploaded Sucessfully");
        if (callback) callback();
        dispatch(setLoading(false));
      })
      .catch((err) => {
        showAlert(2, err.message);
        dispatch(setLoading(false));
      });
  };

// Action function to get identity container.
export const getIdentityContainerFortress = () => (dispatch: Dispatch<any>) => {
  if (!navigator.onLine) {
    showAlert(2, "No internet connection!");
    return;
  }

  dispatch(setLoading(true));
  getApiCall(
    EndPoint.GetIdentityContainer,
    "",
    (response: AxiosResponse<any>) => {
      const { statusCode } = response.data;
      if (statusCode === apiSuccessCode.success) {
      }
      dispatch(setLoading(false));
    },
    (error: any) => {
      dispatch(setLoading(false));
      showAlert(2, error.message);
    }
  );
};

// Action function to get personal container.
export const getPersonalContainerFortress = () => (dispatch: Dispatch<any>) => {
  if (!navigator.onLine) {
    showAlert(2, "No internet connection!");
    return;
  }

  dispatch(setLoading(true));
  getApiCall(
    EndPoint.GetPersonalContainer,
    "",
    (response: AxiosResponse<any>) => {
      const { statusCode, data } = response.data;
      if (statusCode === apiSuccessCode.success) {
        dispatch(setPersonalDetails(data));
      }
      dispatch(setLoading(false));
    },
    (error: any) => {
      dispatch(setLoading(false));
      showAlert(2, error.message);
    }
  );
};

// Action function to create custodial account.
export const createCustodialAccount =
  (values: any, id: any, callback?: () => void) =>
  (dispatch: Dispatch<any>) => {
    dispatch(setLoading(true));

    postApiCall(
      EndPoint.FortressCustodialAccount,
      values,
      (respData: AxiosResponse<any>) => {
        if (respData.data.statusCode === apiSuccessCode.success) {
          showAlert(1, "Custodial Account Created!");
          dispatch(getBusinessCustodialAccountListingFortress(id));
          if (callback) callback();
        }
        dispatch(setLoading(false));
      },
      (error: any) => {
        dispatch(setLoading(false));
        showAlert(2, error.message);
      }
    );
  };
