import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch } from "../../../hooks/useAppDispatch";

// Helper function for the Create Wallet component
const useCreateWalletHelper = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [searchParams] = useSearchParams();
  const type = searchParams.get("type");
  const id = searchParams.get("id");

  const initialAssetsValue = {
    chain: "",
    assetType: "",
  };

  const initialValues = {
    walletName: "",
    assets: [initialAssetsValue],
  };

  const chainOptions = [
    { id: "mainnet", name: "Mainnet" },
    { id: "goerli", name: "Goerli" },
    { id: "bnbSmartChainMainnet", name: "BnbSmart Chain Mainnet" },
    { id: "bnbSmartChainTestnet", name: "BnbSmart Chain Testnet" },
    { id: "polygonMainnet", name: "Polygon Mainnet" },
    { id: "bitcoinMainnet", name: "Bitcoin Mainnet" },
    { id: "bitcoinTestnet", name: "Bitcoin Testnet" },
    { id: "solanaMainnet", name: "Solana Mainnet" },
    { id: "solanaDevnet", name: "Solana Devnet" },
    { id: "solanaTestnet", name: "Solana Testnet" },
    { id: "polkadotMainnet", name: "Polkadot Mainnet" },
    { id: "polkadotWestend", name: "Polkadot Westend" },
    { id: "cardanoMainnet", name: "Cardano Mainnet" },
    { id: "cardanoTestnet", name: "Cardano Testnet" },
    { id: "avalancheFuji", name: "Avalanche Fuji" },
    { id: "avalancheMainnet", name: "Avalanche Mainnet" },
    { id: "polygonMumbai", name: "Polygon Mumbai" },
    { id: "nearMainnet", name: "Near Mainnet" },
    { id: "nearTestnet", name: "Near Testnet" },
  ];

  const assetsOptions = [
    { id: "eth", name: "ETH" },
    { id: "matic", name: "MATIC" },
    { id: "btc", name: "BTC" },
    { id: "usdc", name: "USDC" },
    { id: "flrns", name: "FLRNS" },
    { id: "sol", name: "SOL" },
    { id: "weth", name: "WETH" },
    { id: "ada", name: "ADA" },
    { id: "avax", name: "AVAX" },
    { id: "dot", name: "DOT" },
    { id: "bat", name: "BAT" },
    { id: "shib", name: "SHIB" },
    { id: "bnb", name: "BNB" },
    { id: "busd", name: "BUSD" },
    { id: "aPE", name: "APE" },
    { id: "dAI", name: "DAI" },
    { id: "uSDT", name: "USDT" },
    { id: "nEAR", name: "NEAR" },
  ];

  return {
    navigate,
    dispatch,
    initialValues,
    initialAssetsValue,
    chainOptions,
    assetsOptions,
    id,
    type,
  };
};

export default useCreateWalletHelper;
