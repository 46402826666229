import axios, { AxiosResponse } from "axios";
import { Dispatch } from "react";
import { setLoading } from "../../redux/global.slice";
import { EndPoint, Env } from "../constants";

/**
 * Function for generating CSS font property value based on provided parameters.
 * @param {number} fontWeight - The font weight.
 * @param {number|string} fontSize - The font size.
 * @param {number|string} lineHeight - The line height.
 * @param {string} fontStyle - The font style (optional, defaults to "normal").
 * @param {string} fontFamily - The font family (optional, defaults to "SofiaPro").
 * @returns {string} The CSS font property value.
 */
export const FontFamily = (
  fontWeight: number,
  fontSize: number | string,
  lineHeight: number | string,
  fontStyle = "normal",
  fontFamily = `SofiaPro`
) => {
  let fontsize = "";
  let lineheight = "";

  if (typeof fontSize === "string") fontsize = fontSize;
  else fontsize = `${fontSize}px`;

  if (typeof lineHeight === "string") lineheight = lineHeight;
  else lineheight = `${lineHeight}px`;

  return `${fontStyle} ${fontWeight} ${fontsize}/${lineheight} ${fontFamily}`;
};

/**
 * Function for logging out the user.
 * It performs the necessary steps to clear local storage.
 */
export const logout = () => {
  localStorage.clear();
  window.location.reload();
};

/**
 * Function for uploading a file to a server endpoint.
 * It uses the FormData API and an HTTP client library (e.g., Axios).
 * @param {File} file - The file to be uploaded.
 * @param {Function} callback - This callback will be called on successful upload.
 */
export const uploadFile =
  (file: any, callback?: any) => (dispatch: Dispatch<any>) => {
    dispatch(setLoading(true));
    const token = localStorage.getItem("auth_token");

    const formdata = new FormData();
    formdata.append("file", file);

    const config = {
      method: "post",
      url: Env.BASE_URL + EndPoint.S3upload,
      headers: {
        Authorization: `Bearer ${token} `,
        accept: "application/json",
      },
      data: formdata,
    };

    axios(config)
      .then((response: AxiosResponse<any>) => {
        dispatch(setLoading(false));
        if (callback) callback(response.data.data);
      })
      .catch((err) => {
        console.log(err);
        dispatch(setLoading(false));
      });
  };
