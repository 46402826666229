import { Box, Typography } from "@mui/material";

import useBuyPropertyHelper from "./helper";
import BuyPropertyTable from "../../../components/tables/buyPropertyListing";
import styles from "../add-property/styles";
import PaginationSection from "../../../components/pagination";

/**
 * Defines Buy Property Listing Page
 * @returns The Buy Property Listing Component
 */
const BuyPropertyListing = () => {
  const { assetOnSellListing, handlePageChange } = useBuyPropertyHelper();

  return (
    <Box sx={styles.addForm_mainContainer}>
      <Typography sx={styles.heading}>Buy Asset</Typography>
      <BuyPropertyTable data={assetOnSellListing.result} />
      <PaginationSection
        listData={assetOnSellListing}
        handleChangePage={(val: number) => handlePageChange(val)}
      />
    </Box>
  );
};

export default BuyPropertyListing;
