import { COLORS } from "../../common/constants/colors";
import { FontFamily } from "../../common/utils/common.fns";

const styles = {
  mainContainer: {},
  heading: {
    font: FontFamily(700, 25, 30),
    padding: "0 0 10px 0",
  },
  fieldContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  btnContainer: {
    margin: "35px 0",
    "& .MuiButton-root.Mui-disabled": {
      color: COLORS.white,
    },
  },
  btn: {
    backgroundColor: COLORS.primary,
    color: COLORS.white,
    padding: "12px",
    font: "normal normal 700 20px/25px SofiaPro",
    "&:hover": {
      backgroundColor: COLORS.hoverOver,
      color: COLORS.white,
    },
  },
  textField: { margin: "10px 0" },
};

export default styles;
