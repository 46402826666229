// This file contains action functions for OTP verification and resend OTP functionality.

import { AxiosResponse } from "axios";
import { Dispatch } from "@reduxjs/toolkit";
import { EndPoint, Paths } from "../../../common/constants";
import { postApiCall, putApiCall } from "../../../common/constants/api";
import { apiSuccessCode } from "../../../common/constants/axios.instance";
import { showAlert } from "../../../common/utils/alert";
import { setLoading } from "../../../redux/global.slice";

/**
 * Action function to submit the OTP for verification.
 * @param {object} values - OTP values.
 * @param {function} setSubmitting - Function to set the submitting state.
 * @param {function} navigate - Navigation function.
 * @param {function} resetForm - Function to reset the form.
 */
export const OnOtpSubmit =
  (values: any, setSubmitting: any, navigate: any, resetForm: any) =>
  (dispatch: Dispatch<any>) => {
    // Check for internet connection
    if (!navigator.onLine) {
      showAlert(2, "No internet connection!");
      return;
    }

    const dataToSend = {
      ...values,
      email: localStorage.getItem("email") || "",
    };

    dispatch(setLoading(true));

    // Make API call to verify the OTP
    postApiCall(
      EndPoint.verifyOTP,
      dataToSend,
      (respData: AxiosResponse<any>) => {
        const { data } = respData;

        if (data.statusCode === apiSuccessCode.success) {
          // Save authentication token and name in local storage
          localStorage.setItem("auth_token", data?.data?.authToken);
          localStorage.setItem("name", data?.data?.name);

          showAlert(1, data.type); // Display success alert
          navigate(Paths.HOME); // Navigate to home page
        }

        dispatch(setLoading(false));
        setSubmitting(false);
      },
      (error: any) => {
        setSubmitting(false);
        showAlert(2, error?.type); // Display error alert
        resetForm(); // Reset the form
        dispatch(setLoading(false));
      }
    );
  };

/**
 * Action function to resend the OTP.
 * @param {string} email - Email address.
 * @param {function} navigate - Navigation function.
 */
export const OnResendOtp =
  (email: string, navigate: any) => (dispatch: Dispatch<any>) => {
    // Check for internet connection
    if (!navigator.onLine) {
      showAlert(2, "No internet connection!");
      return;
    }

    const dataToSend = { email };

    dispatch(setLoading(true));

    // Make API call to resend the OTP
    putApiCall(
      EndPoint.resendOTP,
      dataToSend,
      (respData: AxiosResponse<any>) => {
        const { data } = respData;

        if (data.statusCode === apiSuccessCode.success) {
          navigate(Paths.VERIFICATION); // Navigate to verification page
        }
      },
      (error: any) => {
        showAlert(2, error?.type); // Display error alert
      }
    );
  };
