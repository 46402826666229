import { Box } from "@mui/material";
import { Form, Formik } from "formik";
import styles from "./styles";
import Schema from "../../common/constants/schema";
import LoginHelper from "./helper";
import ContainedButton from "../../components/button/contained-button";
import OtpInput from "../../components/formControls/otpInput";
import { OnOtpSubmit } from "./services/action";

/**
 * Defines Verification Page
 * @returns The rendered Component
 */
const Verification = () => {
  const { initialValue, dispatch, navigate } = LoginHelper();

  return (
    <Box sx={styles.mainContainer}>
      <Box sx={styles.heading}>Verification</Box>
      <Box sx={styles.subHeading}>Please enter the 4-digit OTP</Box>
      <Formik
        initialValues={initialValue}
        initialTouched={{ otp: true }}
        validationSchema={Schema.VerifyOtpSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          dispatch(OnOtpSubmit(values, setSubmitting, navigate, resetForm));
        }}
      >
        {({ isValid }) => {
          return (
            <Form>
              <Box sx={styles.fieldContainer}>
                <OtpInput name="otp" />
              </Box>
              <Box sx={styles.btnContainer}>
                <ContainedButton
                  title="Verify OTP"
                  disabled={!isValid}
                  externalStyles={styles.btn}
                  fullWidth
                  size="large"
                  type="submit"
                />
              </Box>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default Verification;
