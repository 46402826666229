import { COLORS } from "../../common/constants";
import { FontFamily } from "../../common/utils";

const styles = {
  paginationContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "15px 0",
  },
  countContainer: {},
  pageContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& button": {
      color: COLORS.sidebarGray,
      font: "normal normal 600 14px/18px SofiaPro",
    },
    "& .Mui-selected": {
      border: `1px solid ${COLORS.primaryBlue}`,
      color: COLORS.sidebarGray,
      backgroundColor: `transparent !important`,
    },
  },
  previous: {
    font: "normal normal 600 14px/18px SofiaPro",
    cursor: "pointer",
    marginRight: "15px",
    color: COLORS.primaryBlue,
  },
  next: {
    font: "normal normal 600 14px/18px SofiaPro",
    cursor: "pointer",
    marginLeft: "10px",
    color: COLORS.primaryBlue,
  },
  count: {
    font: FontFamily(600, 14, 14),
    color: "#000000",
  },
  disabled: {
    color: COLORS.actionPopupBorder,
    cursor: "auto",
  },
  pageBlock: {},
};

export default styles;
