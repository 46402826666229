import { RootState } from "./../../../redux/store";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Paths } from "../../../common/constants";
import { showAlert } from "../../../common/utils";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { useAppSelector } from "../../../hooks/useAppSelector";

// Helper Component for Business Detail Component
const BusinessDetailHelper = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const id = location.search.split("=")[1];
  const name = localStorage.getItem("name") || "";
  const { userDetails } = useAppSelector((state: RootState) => state.login);
  const { walletListing } = useAppSelector(
    (state: RootState) => state.dashboard
  );

  const { custodialAccountListing, businessDetails } = useAppSelector(
    (state: RootState) => state.business
  );

  const { identitiesListing } = useAppSelector(
    (state: RootState) => state.dashboard
  );

  const { beneficialOwners } = businessDetails;

  const [showCustodianOptions, setShowCustodianOptions] = useState(false);
  const [accountType, setAccountType] = useState("");
  const [isConfirmationOpen, setConfirmationOpen] = useState(false);

  const handleConfirmationOpen = () => {
    setConfirmationOpen(true);
  };

  const handleConfirmationClose = () => {
    setConfirmationOpen(false);
  };

  const handleAccount = () => {
    handleConfirmationClose();
    showAlert(2, "Please Create your Business ID first");
    navigate(Paths.FORTRESS_GET_BUSINESS_ID);
  };

  const getBeneficialOwners = () => {
    return identitiesListing.filter((list) =>
      beneficialOwners.includes(list.personalIdentity)
    );
  };

  return {
    navigate,
    dispatch,
    name,
    userDetails,
    showCustodianOptions,
    setShowCustodianOptions,
    accountType,
    setAccountType,
    isConfirmationOpen,
    handleConfirmationClose,
    handleConfirmationOpen,
    custodialAccountListing,
    handleAccount,
    businessDetails,
    id,
    getBeneficialOwners,
    walletListing,
  };
};

export default BusinessDetailHelper;
