import { useEffect } from "react";
import moment from "moment";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import { Paths } from "../../../common/constants";
import { commonTableStyles as styles } from "../styles";

import { getWalletListing } from "../../../pages/fo.dashboard/services/action";
// import { useDashboardHelper } from "../../../pages/fo.dashboard/helper";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { RootState } from "../../../redux/store";

/**
 * Component for rendering wallet table.
 *
 * @param {object} data - The data for the table.
 * @returns {JSX.Element} The rendered component.
 */
export default function WalletListing({ id, type }: any) {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (id) dispatch(getWalletListing(id));
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  const { walletListing } = useAppSelector(
    (state: RootState) => state.dashboard
  );

  return (
    <>
      {walletListing && walletListing.length > 0 ? (
        <TableContainer>
          <Table sx={styles.table}>
            <TableHead sx={{ ...styles.tableHeader }}>
              <TableRow sx={styles.headerRow}>
                <TableCell>Crypto Wallet ID</TableCell>
                <TableCell>Wallet Name</TableCell>
                <TableCell>Wallet Type</TableCell>
                <TableCell>Owner Identity ID</TableCell>
                <TableCell>Created At</TableCell>
              </TableRow>
            </TableHead>
            {walletListing && walletListing.length > 0 && (
              <TableBody>
                {walletListing &&
                  walletListing.length > 0 &&
                  walletListing.map((data: any) => {
                    return (
                      <TableRow
                        key={data.cryptoWalletId}
                        sx={{ ...styles.row }}
                      >
                        <TableCell>
                          <Link
                            to={`${Paths.FORTRESS_WALLET_DETAIL}?id=${data.cryptoWalletId}&type=${type}`}
                          >
                            <Typography sx={{ ...styles.link }}>
                              {data.cryptoWalletId}
                            </Typography>
                          </Link>
                        </TableCell>
                        <TableCell>
                          <Typography>{data.walletName}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography>{data.walletType}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography>{data.ownerIdentityId}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography>
                            {moment(data.createdAt).format("YYYY-MM-DD")}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      ) : (
        <>
          <Typography>No Wallet created yet</Typography>
        </>
      )}
    </>
  );
}
