import { Box } from "@mui/material";

import { styles } from "./styles";
import AccountDetailFortress from "../accountSummaryFortress";

/**
 * Defines Personal Details Page
 * @returns The Personal Details Component
 */
const PersonalDetails = () => {
  return (
    <Box sx={styles.personalDetails_mainContainer}>
      <AccountDetailFortress />
    </Box>
  );
};

export default PersonalDetails;
