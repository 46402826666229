import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../hooks/useAppDispatch";

// Helper Component for Forget Password Component
const ForgotPasswordHelper = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const initialValue = { email: "" };

  return { navigate, initialValue, dispatch };
};

export default ForgotPasswordHelper;
