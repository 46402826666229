import { FC, memo, useState } from "react";
import { useField } from "formik";
import { Box, TextField, TextFieldProps, Typography } from "@mui/material";
import { InputProps } from "./type";
import styles from "./styles";
import { pasteHandler } from "./helper";
import PasswordIcon from "./password";

// Define a custom Input component
const Input: FC<InputProps & TextFieldProps> = ({
  name,
  label,
  placeholder,
  externalStyles = {},
  errortipPlacement = "left",
  isRequired = false,
  isPassword = false,
  isMobile = false,
  ...otherProps
}) => {
  // Use formik's `useField` hook to manage the input field's state and validation
  const [field, meta] = useField(name);
  const [showPassword, setShowPassword] = useState(false);

  // Define attributes for the TextField component
  const attributes: TextFieldProps = {
    ...field,
    ...otherProps,
    placeholder: `${placeholder}${isRequired ? "*" : ""}`,
    error: meta.touched && Boolean(meta.error),
    sx: [
      styles.authInput,
      meta.value && Boolean(!meta.error) ? styles.filled : styles.notFilled,
    ],
    autoComplete: "off",
    onPaste: (evt: any) => pasteHandler(evt, name),
    type: isPassword && !showPassword ? "password" : "text",
  };

  // Set error and helper text when there's a validation error
  if (meta.touched && meta.error) {
    attributes.error = true;
    attributes.helperText = meta.error;
  }

  // Clear helper text on mobile devices
  if (isMobile) {
    attributes.helperText = "";
  }

  // Add end adornment for password inputs
  if (isPassword) {
    attributes.InputProps = {
      endAdornment: (
        <PasswordIcon
          showPassword={showPassword}
          setShowPassword={setShowPassword}
          value={field.value}
        ></PasswordIcon>
      ),
    };
  }

  // Render the component
  return (
    <Box sx={[externalStyles]}>
      {/* Render label if provided */}
      {label && (
        <Typography
          component="div"
          variant="h6"
          color="primary"
          sx={{ ...styles.label, ...(!field.value && { ...styles.none }) }}
        >
          {`${label}${isRequired ? "*" : ""}`}
        </Typography>
      )}
      <TextField {...attributes} />
    </Box>
  );
};

export default memo(Input);
