import { Box, Typography } from "@mui/material";
import { Form, Formik } from "formik";

import Schema from "../../common/constants/schema";
import Input from "../formControls/input";
import { styles } from "./styles";
import ContainedButton from "../button/contained-button";
import OutlinedButton from "../button/outlined-button";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { showAlert } from "../../common/utils";
import { setSellTokenInfo } from "../../pages/fo.property/services/slice";

/**
 * Component for rendering the PropertySellModal.
 *
 * @param {object} details - The details of the property.
 * @param {function} closeModal - The function to close the modal.
 * @param {function} handleOpenSellCertModal - The function to open the payment modal.
 * @returns {JSX.Element} PropertySellModal component.
 */
const PropertySellModal = ({
  details,
  closeModal,
  handleOpenSellCertModal,
}: any) => {
  const initialValues = {
    tokenValue: "",
    noOfTokenToSell: "",
  };

  const dispatch = useAppDispatch();

  return (
    <Box sx={styles.modalStyle}>
      <Box>
        <Typography sx={styles.modalHeading}>Sell Tokens</Typography>

        <Formik
          initialValues={initialValues}
          validationSchema={Schema.PropertySellSchema}
          onSubmit={(values, { setSubmitting }) => {
            if (details.tokenLeftTosellByOwner < values.noOfTokenToSell)
              showAlert(2, "Insufficient Token Quantity");
            else {
              dispatch(setSellTokenInfo(values));
              handleOpenSellCertModal();
              closeModal();
            }
          }}
        >
          {(props) => {
            return (
              <Form>
                <Box sx={styles.formContainer}>
                  <Input
                    name="tokenValue"
                    type="text"
                    label="Token Amount"
                    placeholder="Token Amount"
                    fullWidth
                    isRequired
                    externalStyles={styles.textField}
                  />
                  <Input
                    name="noOfTokenToSell"
                    type="text"
                    label="Number of Tokens to sell"
                    placeholder="Number of Tokens to sell"
                    fullWidth
                    isRequired
                    externalStyles={styles.textField}
                  />
                </Box>

                <Box sx={styles.btnContainer}>
                  <ContainedButton
                    title={"Save"}
                    externalStyles={styles.btn}
                    size="large"
                    type="submit"
                    fullWidth
                    primary
                  />
                  <OutlinedButton
                    title="Cancel"
                    externalStyles={styles.btn}
                    size="large"
                    fullWidth
                    onClick={() => {
                      closeModal();
                    }}
                  />
                </Box>
              </Form>
            );
          }}
        </Formik>
      </Box>
    </Box>
  );
};

export default PropertySellModal;
