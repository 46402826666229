import { Backdrop, Box, CircularProgress } from "@mui/material";
import { useAppSelector } from "../../hooks/useAppSelector";
import { RootState } from "../../redux/store";
import styles from "./loader.styles";

// Defines the Loader Component
const Spinner = () => {
  const { loading } = useAppSelector((state: RootState) => state.global);

  return (
    <Backdrop sx={styles.loaderContainer} open={loading || false}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress />
      </Box>
    </Backdrop>
  );
};

export default Spinner;
