import codes from "country-calling-code";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FormControl, MenuItem, Select, SelectProps } from "@mui/material";

import styles from "./styles";
import { CountryPickerProps } from "./type";

// Component for selecting a country code
const CountryPicker = ({
  selectStyles,
  formControlStyles,
  ...otherProps
}: CountryPickerProps & SelectProps) => {
  // Sort the country codes alphabetically
  const countryCodes = codes.sort((a: any, b: any) => {
    if (a.isoCode3 > b.isoCode3) return 1;
    if (b.isoCode3 > a.isoCode3) return -1;
    return 0;
  });

  return (
    <FormControl sx={[styles.formControl]}>
      <Select
        // Custom styling for the select component
        sx={{ ...selectStyles, ...styles.select }}
        IconComponent={ExpandMoreIcon}
        MenuProps={{
          PaperProps: { sx: styles.select },
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        }}
        placeholder="Select"
        {...otherProps}
      >
        {countryCodes.map((item: any) => {
          return (
            // Render a menu item for each country code
            <MenuItem
              sx={styles.menutItem}
              value={item.countryCodes[0]}
              key={item.country}
            >
              {`+${item.countryCodes[0]}`}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default CountryPicker;
