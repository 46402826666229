import RoutesManager from "./routes";
import { ToastContainer } from "react-toastify";

/**
 * This is the main entry point of our React application.
 * It renders the main components and handles the overall app logic.
 * ToastContainer - It is used to show alerts
 */

const App = () => {
  return (
    <>
      <RoutesManager />
      <ToastContainer
        closeButton={false}
        autoClose={1500}
        newestOnTop
        hideProgressBar
      />
    </>
  );
};

export default App;
