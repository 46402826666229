import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { getCustodialAccountDetailFortress } from "../../../pages/fo.business.id/services/action";
import { RootState } from "../../../redux/store";

// Helper function for the Add Card component
const AddPropertyhelper = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [searchParams] = useSearchParams();
  const type = searchParams.get("type");
  const id = searchParams.get("id");

  const { userDetails } = useAppSelector((state: RootState) => state.login);
  const { custodialAccountDetails } = useAppSelector(
    (state: RootState) => state.business
  );

  const initialValues = {
    firstName: "",
    lastName: "",
    street1: "",
    street2: "",
    postalCode: "",
    city: "",
    state: "",
    country: "",
    number: "",
    expirationMonth: "",
    expirationYear: "",
    cvv: "",
  };

  // Fetch custodial account details when the component mounts
  useEffect(() => {
    if (id) dispatch(getCustodialAccountDetailFortress(id));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    navigate,
    dispatch,
    id,
    type,
    initialValues,
    userDetails,
    custodialAccountDetails,
  };
};

export default AddPropertyhelper;
