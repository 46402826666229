import { Box, Tabs, Tab } from "@mui/material";
import { useNavigate } from "react-router-dom";

import styles from "./styles";

interface TabItems {
  label: string;
  href: string;
  // value: string | number;
}

interface Props {
  tabItems: TabItems[];
  type: number;
}

interface LinkTabProps {
  label?: string;
  href?: string;
}

/**
 * Component for rendering a tab with a link to a specific route.
 *
 * @param {LinkTabProps} props - Component properties.
 * @param {string} [props.label] - Label text for the tab.
 * @param {string} [props.href] - URL path for the tab link.
 * @returns {JSX.Element} LinkTab component.
 */
const LinkTab = (props: LinkTabProps) => {
  const navigate = useNavigate();
  return (
    <Tab
      component="a"
      sx={{ textTransform: "capitalize" }}
      onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
        if (props.href) navigate(props.href);
      }}
      {...props}
    />
  );
};

/**
 * Component for rendering a general tab with multiple links.
 *
 * @param {Props} props - Component properties.
 * @param {TabItems[]} props.tabItems - Array of tab items with labels and hrefs.
 * @param {number} props.type - The selected tab type.
 * @returns {JSX.Element} GeneralTab component.
 */
export default function GeneralTab({ tabItems, type }: Props) {
  return (
    <Box sx={{ width: "100%" }}>
      <Tabs sx={styles.tab} value={type} aria-label="nav tabs example">
        {tabItems &&
          tabItems.length > 0 &&
          tabItems.map((tabValue) => (
            <LinkTab
              label={tabValue.label}
              href={tabValue.href}
              key={tabValue.href}
            />
          ))}
      </Tabs>
    </Box>
  );
}
