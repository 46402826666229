import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../hooks/useAppDispatch";

// Helper Component for Get Business Id Component
const FortressGetBusinessIdHelper = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const initialValues = {
    companyName: "",
    ein: "",
    website: "",
    phoneNumber: "",
    email: "",
    street1: "",
    street2: "",
    postalCode: "",
    city: "",
    state: "",
    country: "",
    regionOfFormation: "",
    description: "",
    establishedOn: "",
    legalStructure: "",
    naics: "",
    naicsDescription: "",
    mstreet1: "",
    mstreet2: "",
    mpostalCode: "",
    mcity: "",
    mstate: "",
    mcountry: "",
    beneficialOwners: [],
  };

  return { navigate, dispatch, initialValues };
};

export default FortressGetBusinessIdHelper;
