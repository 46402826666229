import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { RootState } from "../../../redux/store";
import { getCustodialAccountDetailFortress } from "../../fo.business.id/services/action";
import {
  createLinkToken,
  createPlaidExternalAccount,
  getAllExternalAccounts,
} from "../services/action";
// import { getAllExternalAccounts } from "./services/action";

// Helper Component for Add Fund Plaid Component
const AddFundPlaidhelper = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [openPaymentModal, setOpenPaymentModal] = useState(false);
  const [openAddPlaidModal, setOpenAddPlaidModal] = useState(false);

  const [searchParams] = useSearchParams();
  // const type = searchParams.get("type");
  const id = searchParams.get("id");

  const { fundListing, linkToken } = useAppSelector(
    (state: RootState) => state.fund
  );
  const { custodialAccountDetails } = useAppSelector(
    (state: RootState) => state.business
  );

  const handleOpenModal = () => setOpenPaymentModal(true);

  const handleCloseModal = () => setOpenPaymentModal(false);

  const handleOpenAddModal = () => dispatch(createLinkToken());

  const handleCloseAddModal = () => setOpenAddPlaidModal(false);

  useEffect(() => {
    if (id) dispatch(getCustodialAccountDetailFortress(id));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (custodialAccountDetails.ownerIdentityId)
      dispatch(
        getAllExternalAccounts(
          custodialAccountDetails.ownerIdentityId,
          "financial"
        )
      );
  }, [custodialAccountDetails.ownerIdentityId]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCreatePlaid = (processorToken: any) => {
    if (custodialAccountDetails.ownerIdentityId)
      dispatch(
        createPlaidExternalAccount(
          custodialAccountDetails.ownerIdentityId,
          processorToken,
          () => {
            handleCloseAddModal();
            dispatch(
              getAllExternalAccounts(
                custodialAccountDetails.ownerIdentityId,
                "financial"
              )
            );
          }
        )
      );
  };

  return {
    navigate,
    dispatch,
    id,
    fundListing,
    openPaymentModal,
    setOpenPaymentModal,
    handleOpenModal,
    handleCloseModal,
    handleCreatePlaid,
    openAddPlaidModal,
    setOpenAddPlaidModal,
    handleOpenAddModal,
    handleCloseAddModal,
    linkToken,
  };
};

export default AddFundPlaidhelper;
