/**
 * Environment variables
 */
export const Env = {
  MODE: process.env.NODE_ENV || "development",
  BASE_URL: process.env.REACT_APP_BASE_URL,
  PRIME_URL: process.env.REACT_APP_PRIME_TRUST_URL,
  FORTRESS_BASE_URL: process.env.REACT_APP_FORTRESS_BASE_URL,
  HENRY_ACCOUNT_ID: process.env.REACT_APP_HENRY_ACCOUNT_ID,
  HENRY_MATIC_WALLET_ADDRESS: process.env.REACT_APP_HENRY_MATIC_WALLET_ADDRESS,
};
