import { COLORS } from "../../../common/constants";
import { FontFamily } from "../../../common/utils";

export const styles = {
  mainContainer: {
    width: "85%",
    margin: "20px auto",
    backgroundColor: COLORS.white,
    border: `1px solid #F4F6FA`,
    borderRadius: "6px",
    boxShadow: "0 0.278vw 0.417vw rgba(0, 0, 0, 0.02)",
    padding: "20px",
    boxSizing: "border-box",
  },
  heading: { font: FontFamily(600, 20, 25), margin: "20px 0 10px" },
  btn: {
    font: FontFamily(700, 18, 23),
    padding: "15px",
    margin: "10px 0",
  },
  selectComp: { width: "47%", marginRight: "20px" },
  selectContainer: {
    display: "flex",
    alignItems: "center",
    margin: "15px 0",
  },
  submitButton: {
    width: "200px",
    height: "60px",
    margin: "1rem",
  },
  btnContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};
