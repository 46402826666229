import { Box, Typography } from "@mui/material";
import { Form, Formik } from "formik";

import Input from "../../components/formControls/input";
import ContainedButton from "../../components/button/contained-button";
import OutlinedButton from "../../components/button/outlined-button";
import styles from "./styles";
import Schema from "../../common/constants/schema";
import { Paths } from "../../common/constants";
import FortressGetBusinessIdHelper from "./helper";
import { getBusinessID } from "./services/action";
import MultipleSelectCheckmarks from "../../components/multiSelect";
import { useDashboardHelper } from "../fo.dashboard/helper";

/**
 * Defines Get Business Id Page
 * @returns The Get Business Id Component
 */
const GetBusinessIdForm = () => {
  const { navigate, initialValues, dispatch } = FortressGetBusinessIdHelper();
  const { identitiesListing } = useDashboardHelper();

  return (
    <Box sx={styles.mainContainer}>
      <Formik
        initialValues={initialValues}
        validationSchema={Schema.FortressBusinessID}
        onSubmit={(values, { setSubmitting }) => {
          dispatch(
            getBusinessID(values, () => {
              navigate(Paths.FORTRESS_DASHBOARD);
            })
          );
        }}
      >
        {({ values, setFieldValue }) => {
          return (
            <Form>
              <Typography sx={styles.heading}>Get Your Business ID</Typography>
              <Box sx={styles.formContainer}>
                <Input
                  name="companyName"
                  type="text"
                  label="Company Name"
                  placeholder="Company Name"
                  fullWidth
                  isRequired
                  externalStyles={styles.textField}
                />
                <Input
                  name="ein"
                  type="text"
                  label="EIN"
                  placeholder="EIN"
                  fullWidth
                  externalStyles={styles.textField}
                />
                <Input
                  name="website"
                  type="text"
                  label="Website"
                  placeholder="Website"
                  fullWidth
                  externalStyles={styles.textField}
                />
                <Input
                  name="email"
                  type="email"
                  label="Email Address"
                  placeholder="Email Address"
                  fullWidth
                  isRequired
                  externalStyles={styles.textField}
                />
                <Input
                  name="phoneNumber"
                  type="text"
                  label="Phone Number"
                  placeholder="Phone Number"
                  fullWidth
                  isRequired
                  externalStyles={styles.textField}
                />

                <Input
                  name="regionOfFormation"
                  type="text"
                  label="Region of Formation"
                  placeholder="Region of Formation"
                  fullWidth
                  isRequired
                  externalStyles={styles.textField}
                />
                <Input
                  name="description"
                  type="text"
                  label="Description"
                  placeholder="Description"
                  fullWidth
                  isRequired
                  externalStyles={styles.textField}
                />
                <Input
                  name="legalStructure"
                  type="text"
                  label="Legal Structure"
                  placeholder="Legal Structure"
                  fullWidth
                  isRequired
                  externalStyles={styles.textField}
                />
                <Input
                  name="establishedOn"
                  type="text"
                  label="Established on"
                  placeholder="Established on"
                  fullWidth
                  isRequired
                  externalStyles={styles.textField}
                />
                <Input
                  name="naics"
                  type="text"
                  label="Naics"
                  placeholder="Naics"
                  fullWidth
                  externalStyles={styles.textField}
                />
                <Input
                  name="naicsDescription"
                  type="text"
                  label="Naics Description"
                  placeholder="Naics Description"
                  fullWidth
                  externalStyles={styles.textField}
                />
              </Box>
              <MultipleSelectCheckmarks
                id="beneficialOwners"
                name="beneficialOwners"
                valKey="name"
                placeholder="Beneficial Owners"
                externalStyles={styles.textField}
                values={values.beneficialOwners}
                options={identitiesListing}
                setValue={(
                  valueArr: Array<string | { [key: string]: any }>
                ) => {
                  setFieldValue("beneficialOwners", valueArr);
                }}
              />
              <Typography sx={styles.heading}>Address : </Typography>
              <Box sx={styles.formContainer}>
                <Input
                  name="street1"
                  type="text"
                  label="Street 1"
                  placeholder="Street 1"
                  fullWidth
                  isRequired
                  externalStyles={styles.textField}
                />
                <Input
                  name="street2"
                  type="text"
                  label="Street 2"
                  placeholder="Street 2"
                  fullWidth
                  isRequired
                  externalStyles={styles.textField}
                />
                <Input
                  name="postalCode"
                  type="text"
                  label="Postal Code"
                  placeholder="Postal Code"
                  fullWidth
                  isRequired
                  externalStyles={styles.textField}
                />
                <Input
                  name="city"
                  type="text"
                  label="City"
                  placeholder="City"
                  fullWidth
                  isRequired
                  externalStyles={styles.textField}
                />
                <Input
                  name="country"
                  type="text"
                  label="Country"
                  placeholder="Country"
                  fullWidth
                  isRequired
                  externalStyles={styles.textField}
                />
                <Input
                  name="state"
                  type="text"
                  label="State"
                  placeholder="State"
                  fullWidth
                  isRequired
                  externalStyles={styles.textField}
                />
              </Box>
              <Typography sx={styles.heading}>Mailing Address : </Typography>
              <Box sx={styles.formContainer}>
                <Input
                  name="mstreet1"
                  type="text"
                  label="Street 1"
                  placeholder="Street 1"
                  fullWidth
                  isRequired
                  externalStyles={styles.textField}
                />
                <Input
                  name="mstreet2"
                  type="text"
                  label="Street 2"
                  placeholder="Street 2"
                  fullWidth
                  isRequired
                  externalStyles={styles.textField}
                />
                <Input
                  name="mpostalCode"
                  type="text"
                  label="Postal Code"
                  placeholder="Postal Code"
                  fullWidth
                  isRequired
                  externalStyles={styles.textField}
                />
                <Input
                  name="mcity"
                  type="text"
                  label="City"
                  placeholder="City"
                  fullWidth
                  isRequired
                  externalStyles={styles.textField}
                />
                <Input
                  name="mcountry"
                  type="text"
                  label="Country"
                  placeholder="Country"
                  fullWidth
                  isRequired
                  externalStyles={styles.textField}
                />
                <Input
                  name="mstate"
                  type="text"
                  label="State"
                  placeholder="State"
                  fullWidth
                  isRequired
                  externalStyles={styles.textField}
                />
              </Box>
              <Box sx={styles.btnContainer}>
                <ContainedButton
                  title={"Save"}
                  externalStyles={styles.btn}
                  type="submit"
                  // disabled={!isValid}
                />
                <OutlinedButton
                  title="Cancel"
                  externalStyles={styles.btn}
                  onClick={() => {
                    navigate(Paths.HOME);
                  }}
                />
              </Box>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default GetBusinessIdForm;
