import { COLORS } from "../../../common/constants/colors";

const styles = {
  btnPrimary: {
    textTransform: "capitalize",
    cursor: "pointer",
    borderRadius: "0",
    backgroundColor: COLORS.white,
    boxShadow: `0px 1px 5px rgba(0, 0, 0, 0.10)`,
    fontFamily: "SofiaPro",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "20px",
    lineHeight: "22px",
    color: COLORS.primaryBlue,
    border: "0px !important",
    "&:hover": {
      backgroundColor: COLORS.white,
      color: COLORS.primaryBlue,
      border: "0px !important",
      boxShadow: `0px 10px 14px rgba(0, 0, 0, 0.12)`,
    },
  },
  btnNormal: {
    textTransform: "capitalize",
    cursor: "pointer",
    borderRadius: "0",
    boxShadow: `0px 10px 14px rgba(0, 0, 0, 0.15)`,
    "&.MuiButtonBase-root-MuiButton-root": {
      border: "0px !important",
    },
  },
};
export default styles;
