import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { Paths } from "../../../common/constants";
import { AssetDetails } from "../../../pages/fo.property/services/type";

import { commonTableStyles as styles, buyPropertyListing } from "../styles";

/**
 * Component for rendering buy property table.
 *
 * @param {object} data - The data for the table.
 * @returns {JSX.Element} The rendered component.
 */
const BuyPropertyTable = ({ data }: any) => {
  const { search } = useLocation();

  return (
    <TableContainer>
      <Table
        sx={{ ...styles.table, ...buyPropertyListing.table }}
        stickyHeader
        aria-label="customized table"
      >
        <TableHead
          sx={{ ...styles.tableHeader, ...buyPropertyListing.tableHeader }}
        >
          <TableRow sx={styles.headerRow}>
            <TableCell>Property Id</TableCell>
            <TableCell>No Of Token To Sell</TableCell>
            <TableCell>Left No Of Token To Sell</TableCell>
            <TableCell>Token Value</TableCell>
            <TableCell>Property Name</TableCell>
            <TableCell>Property Symbol</TableCell>
            <TableCell>Property Address</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {data && data.length > 0 ? (
            data.map((data: AssetDetails, index: number) => (
              <TableRow
                key={index}
                sx={{ ...styles.row, ...buyPropertyListing.row }}
              >
                <TableCell>
                  <Link
                    to={`${Paths.PROPERTY_ON_SELL_DETAILS}${search}&propId=${data._id}`}
                  >
                    <Typography sx={{ ...styles.link }}>{data._id}</Typography>
                  </Link>
                </TableCell>
                <TableCell>
                  <Typography>{data.noOfTokenToSell ?? "-"}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{data.leftNoOfTokenToSell ?? "-"}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{data.tokenValue}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{data?.properties?.name ?? "-"}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{data?.properties?.symbol ?? "-"}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{data?.properties?.address ?? "-"}</Typography>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={14} align="center">
                <h3>No Records</h3>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BuyPropertyTable;
