// This file contains an action function for user signup.

import { AxiosResponse } from "axios";
import { Dispatch } from "@reduxjs/toolkit";
import { EndPoint, Paths } from "../../../common/constants";
import { postApiCall } from "../../../common/constants/api";
import { apiSuccessCode } from "../../../common/constants/axios.instance";
import { showAlert } from "../../../common/utils/alert";
import { setLoading } from "../../../redux/global.slice";

/**
 * Action function to handle user signup.
 * @param {object} values - User signup form values.
 * @param {function} setSubmitting - Function to set the submitting state.
 * @param {function} navigate - Navigation function.
 */
export const onSignup =
  (values: any, setSubmitting: any, navigate: any) =>
  (dispatch: Dispatch<any>) => {
    const dataToSend = {
      countryCode: `+${values.countryCode}`,
      name: `${values.firstname} ${values.lastname}`,
      email: values.email,
      phoneNo: values.phoneNo,
      password: values.password,
    };

    // Check for internet connection
    if (!navigator.onLine) {
      showAlert(2, "No internet connection!");
      return;
    }

    dispatch(setLoading(false));

    // Make API call to signup user
    postApiCall(
      EndPoint.signup,
      dataToSend,
      (respData: AxiosResponse<any>) => {
        const { data } = respData;

        if (data?.statusCode === apiSuccessCode.success) {
          localStorage.setItem("email", values.email);
          navigate(Paths.VERIFICATION); // Navigate to verification page
        }

        dispatch(setLoading(false));
        setSubmitting(false);
      },
      (error: any) => {
        dispatch(setLoading(false));
        setSubmitting(false);
        showAlert(2, error?.type); // Display error alert
      }
    );
  };
