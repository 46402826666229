import { Box, Popover, Typography } from "@mui/material";
// import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Link } from "react-router-dom";

import styles from "../styles";
import Cards from "../../../components/card";
import ContainedButton from "../../../components/button/contained-button";
import { Paths } from "../../../common/constants";
import TransactionListing from "../../../components/tables/transactionListing";
import CustodialAccountHelper from "./helper";
import SimpleModal from "../../../components/modal";
import TransferSelfCard from "../../../components/modal/transferSelfCard";
import TransferExternalModal from "../../../components/modal/transferExternalModal";
import ConfirmationContainer from "../../../components/confimation";
import PaginationSection from "../../../components/pagination";

/**
 * Defines Balance Modal Page
 * @returns The Balance Modal Component
 */
const BalanceModal = ({ val, onClick }: any) => {
  return (
    <Box sx={styles.checkBalContainer}>
      <Typography>{`Account Balance: ${val[0]?.total ?? 0} USD`}</Typography>
      <ContainedButton title="Close" primary onClick={onClick} />
    </Box>
  );
};

/**
 * Defines Custodial Account Detail Page
 * @returns The Custodial Account Detail Component
 */
const CustodialAccountDetail = () => {
  const {
    type,
    id,
    CD,
    transactionListing,
    fundListing,
    anchorEl,
    modalType,
    handleClick,
    handleClose,
    openTransferSelfModal,
    openTransferExternalModal,
    handleCloseSelfModal,
    handleCloseExternalModal,
    handleTransferBtnClick,
    handleCustodialAccountListing,
    openBalanceModal,
    handleCloseBalanceModal,
    handleOpenBalanceModal,
    accountBalance,
    handlePageChange,
  } = CustodialAccountHelper();

  const open = Boolean(anchorEl);
  const popoverId = open ? "addFundBtn" : undefined;

  const getAccountBalanceData = () => {
    return accountBalance.filter((bal: any) => bal.assetFiatType === "usd");
  };

  return (
    <Box sx={styles.accountSummary_mainContainer}>
      <Box sx={styles.accountDetail_btnContainer}>
        <ContainedButton
          title={"Check Account Balance"}
          externalStyles={styles.btn}
          size="large"
          fullWidth
          onClick={handleOpenBalanceModal}
        />
        <ContainedButton
          title={"Add Fund"}
          externalStyles={styles.btn}
          size="large"
          fullWidth
          id={popoverId}
          onClick={handleClick}
        />
        <Popover
          id={popoverId}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          sx={styles.popoverContainer}
        >
          <Link to={`${Paths.FORTRESS_ADD_FUND_CARD}?type=${type}&id=${id}`}>
            <Typography sx={styles.popoverText}>Add Fund via Card</Typography>
          </Link>
          <Link to={`${Paths.FORTRESS_ADD_PLAID_CARD}?type=${type}&id=${id}`}>
            <Typography sx={styles.popoverText}>Add Fund via Plaid</Typography>
          </Link>
        </Popover>

        <ContainedButton
          title={"Transfer to Self Account"}
          externalStyles={styles.btn}
          size="large"
          fullWidth
          onClick={() => handleTransferBtnClick("financial")}
        />
      </Box>
      <Cards
        childComp={
          <>
            <Typography sx={styles.CD_heading}>
              Custodial Account Detail
            </Typography>

            <Box sx={styles.accountSummary_cardContentContainer}>
              <Box
                sx={{
                  ...styles.accountSummary_detailsContainer,
                  ...styles.CD_detailsContainer,
                }}
              >
                <Box sx={styles.textContainer}>
                  <Typography sx={styles.textHeading}>
                    Account Number
                  </Typography>
                  <Typography>{CD?.accountNumber}</Typography>
                </Box>
                <Box sx={styles.textContainer}>
                  <Typography sx={styles.textHeading}>Account Type</Typography>
                  <Typography>{CD?.accountType}</Typography>
                </Box>
                <Box sx={styles.textContainer}>
                  <Typography sx={styles.textHeading}>
                    Account Status
                  </Typography>
                  <Typography>{CD?.accountStatus}</Typography>
                </Box>
                <Box sx={styles.textContainer}>
                  <Typography sx={styles.textHeading}>
                    Owner Identity Id
                  </Typography>
                  <Typography>{CD?.ownerIdentityId}</Typography>
                </Box>
              </Box>
            </Box>
          </>
        }
      />
      <Box>
        <Cards
          childComp={
            <Box sx={styles.transaction_mainContainer}>
              <Typography sx={styles.heading}>Transaction</Typography>
              <TransactionListing listingData={transactionListing.data} />
              <PaginationSection
                listData={transactionListing}
                handleChangePage={(val: number) => handlePageChange(val)}
              />
            </Box>
          }
        />
      </Box>
      <SimpleModal
        isOpen={openTransferSelfModal}
        handleClose={handleCloseSelfModal}
        children={
          <TransferSelfCard
            optionListing={fundListing}
            CId={id}
            closeModal={handleCloseSelfModal}
          />
        }
      />
      <SimpleModal
        isOpen={openTransferExternalModal}
        handleClose={handleCloseExternalModal}
        children={
          <TransferExternalModal
            optionListing={handleCustodialAccountListing()}
            CId={id}
            closeModal={handleCloseExternalModal}
            type={modalType}
          />
        }
      />
      <SimpleModal
        isOpen={openBalanceModal}
        handleClose={handleCloseBalanceModal}
        children={
          <ConfirmationContainer>
            <BalanceModal
              val={getAccountBalanceData()}
              onClick={() => handleCloseBalanceModal()}
            />
          </ConfirmationContainer>
        }
      />
    </Box>
  );
};

export default CustodialAccountDetail;
