import { Box, Typography } from "@mui/material";

import styles from "./styles";
import HomeHelper from "./helper";
import ContainedButton from "../../components/button/contained-button";
import { Paths } from "../../common/constants";

/**
 * Defines Home Page
 * @returns The rendered Component
 */
const Home = () => {
  const { name, navigate } = HomeHelper();
  return (
    <Box sx={styles.mainContainer}>
      <Typography sx={styles.mainHeading}>{`Welcome , ${
        name || "User"
      }!`}</Typography>
      <Typography sx={styles.mainHeading}>
        Please Choose to continue.
      </Typography>
      <Box>
        <Box>
          <ContainedButton
            title="PRIME TRUST"
            externalStyles={styles.btn}
            onClick={() => navigate(Paths.PRIME_TRUST_DASHBOARD)}
          />
          <ContainedButton
            title="FORTRESS"
            externalStyles={styles.btn}
            onClick={() => navigate(Paths.FORTRESS_DASHBOARD)}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Home;
