import { Box, Typography } from "@mui/material";
import moment from "moment";

import styles from "./styles";
import ContainedButton from "../../../components/button/contained-button";
import Cards from "../../../components/card";
import useBuyPropertyDetailsHelper from "./helper";
import SimpleModal from "../../../components/modal";
import PropertyBuyModal from "../../../components/modal/buyPropertyModal";
import PropertyPaymentModal from "../../../components/modal/buyPropertyPaymentModal";
import BuyPropertyCertModal from "../../../components/modal/buyPropertyCertModal";
// import useBuyPropertyPaymentHelper from "./paymentHelper";

/**
 * Defines Buy Property Details Page
 * @returns The Buy Property Details Component
 */
const BuyPropertyDetails = () => {
  const {
    assetOnSellDetails: PD,
    sourceCusID,
    DestinationCusID,
    address,
    exchangeAmount,
    noOfToken,
    openSellModal,
    handleOpenSellModal,
    handleCloseSellModal,
    openPaymentModal,
    handleClosePaymentModal,
    handleOpenPaymentModal,
    openBuyCertModal,
    handleCloseBuyCertModal,
    handleOpenBuyCertModal,
    id,
  } = useBuyPropertyDetailsHelper();

  return (
    <Box sx={styles.accountSummary_mainContainer}>
      <Box sx={styles.btnContainer}>
        <ContainedButton
          title="Buy Property Token"
          externalStyles={styles.walletBtn}
          primary
          onClick={() => handleOpenSellModal()}
        />
      </Box>

      <Cards
        childComp={
          <Box sx={styles.accountSummary_cardContentContainer}>
            <Box width="100%">
              <Box sx={styles.accountDetailContainer}>
                <Box sx={styles.headingContainer}>
                  <Typography sx={styles.accountName}>
                    {PD?.propertyDetails?.name}
                  </Typography>
                </Box>
              </Box>
              <Box sx={styles.accountDetailContainer}>
                <Box sx={styles.headingContainer}>
                  <Typography sx={styles.accountName}>
                    {"Property Details"}
                  </Typography>
                </Box>
              </Box>
              <Box sx={styles.accountSummary_detailsContainer}>
                <Box sx={styles.textContainer}>
                  <Typography sx={styles.textHeading}>Name</Typography>
                  <Typography>{PD?.propertyDetails?.name}</Typography>
                </Box>
                <Box sx={styles.textContainer}>
                  <Typography sx={styles.textHeading}>Address</Typography>
                  <Typography>{PD?.propertyDetails?.address}</Typography>
                </Box>
                <Box sx={styles.textContainer}>
                  <Typography sx={styles.textHeading}>Symbol</Typography>
                  <Typography>{PD?.propertyDetails?.symbol}</Typography>
                </Box>
                <Box sx={styles.textContainer}>
                  <Typography sx={styles.textHeading}>Token Value</Typography>
                  <Typography>{PD?.tokenValue}</Typography>
                </Box>
                <Box sx={styles.textContainer}>
                  <Typography sx={styles.textHeading}>Minted Token</Typography>
                  <Typography>{PD?.propertyDetails?.mintedToken}</Typography>
                </Box>
                <Box sx={styles.textContainer}>
                  <Typography sx={styles.textHeading}>
                    Left No Of Token To Buy
                  </Typography>
                  <Typography>{PD?.leftNoOfTokenToSell}</Typography>
                </Box>
                <Box sx={styles.textContainer}>
                  <Typography sx={styles.textHeading}>Created At</Typography>
                  <Typography>
                    {moment(PD?.createdAt).format("YYYY-MM-DD")}
                  </Typography>
                </Box>
              </Box>
              <Box sx={styles.accountDetailContainer}>
                <Box sx={styles.headingContainer}>
                  <Typography sx={styles.accountName}>
                    {"User Details"}
                  </Typography>
                </Box>
              </Box>
              <Box sx={styles.accountSummary_detailsContainer}>
                <Box sx={styles.textContainer}>
                  <Typography sx={styles.textHeading}>Name</Typography>
                  <Typography>{PD?.userDetails?.name}</Typography>
                </Box>
                <Box sx={styles.textContainer}>
                  <Typography sx={styles.textHeading}>Email</Typography>
                  <Typography>{PD?.userDetails?.email}</Typography>
                </Box>
                <Box sx={styles.textContainer}>
                  <Typography sx={styles.textHeading}>Phone No</Typography>
                  <Typography>
                    {PD?.userDetails?.countryCode} {PD?.userDetails?.phoneNo}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        }
      />

      <SimpleModal
        isOpen={openSellModal}
        handleClose={handleCloseSellModal}
        children={
          <PropertyBuyModal
            details={PD}
            closeModal={handleCloseSellModal}
            handleOpenPaymentModal={handleOpenBuyCertModal}
          />
        }
      />

      <SimpleModal
        isOpen={openBuyCertModal}
        handleClose={handleCloseBuyCertModal}
        children={
          <BuyPropertyCertModal
            details={PD}
            closeModal={handleCloseBuyCertModal}
            handleOpenPaymentModal={handleOpenPaymentModal}
          />
        }
      />

      <SimpleModal
        isOpen={openPaymentModal}
        handleClose={handleClosePaymentModal}
        children={
          <PropertyPaymentModal
            closeModal={handleClosePaymentModal}
            sourceCusID={sourceCusID}
            DestinationCusID={DestinationCusID}
            address={address}
            details={PD}
            exchangeAmount={exchangeAmount}
            noOfToken={noOfToken}
            id={id}
          />
        }
      />
    </Box>
  );
};

export default BuyPropertyDetails;
