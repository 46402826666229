import { COLORS } from "../../common/constants";

const styles = {
  tab: {
    margin: "10px 0 20px 0",
    "& a": {
      textTransform: "uppercase",
      fontSize: "14px",
      lineHeight: "18px",
      fontWeight: 550,
      paddingLeft: 0,
      minWidth: "150px",
    },
    "& .Mui-selected": {
      color: `${COLORS.primary} !important`,
      fontWeight: 700,
    },
    "& .MuiTabs-indicator": {
      height: "4px",
      borderRadius: "2px",
      backgroundColor: COLORS.primary,
    },
  },
};

export default styles;
