import axios, { AxiosResponse } from "axios";
import { Dispatch } from "@reduxjs/toolkit";
import { Buffer } from "buffer";
import { utils } from "ethers";

import { EndPoint } from "../../../common/constants";
import { postApiCall, getApiCall } from "../../../common/constants/api";
import { apiSuccessCode } from "../../../common/constants/axios.instance";
import { showAlert } from "../../../common/utils/alert";
import { setLoading } from "../../../redux/global.slice";
import { getProfile } from "../../login/services/action";
import {
  setAgreementData,
  setAssetsOnSellDetails,
  setAssetsOnSellListing,
  setBuyTransactionListing,
  setExchangeAmount,
  setMyBuyPropertyListing,
  setMySellPropertyListing,
  setPropertyDetails,
  setPropertyListing,
  setRetrievalAddress,
  setSellTransactionListing,
  setSignedPropertyInfo,
} from "./slice";
import { RootState } from "../../../redux/store";

// Action function to add property.
export const addProperty =
  (values: any, id: string, callback?: () => void) =>
  (dispatch: Dispatch<any>) => {
    dispatch(setLoading(true));
    const {
      propertyName,
      tokenValue,
      symbol,
      address,
      fileURL,
      wallet_address,
    } = values;

    const dataToSend = {
      identityId: id,
      name: propertyName,
      address,
      symbol,
      mintedToken: tokenValue,
      documentUrl: fileURL,
      walletAddress: wallet_address,
    };

    postApiCall(
      EndPoint.AddProperty,
      dataToSend,
      (respData: AxiosResponse<any>) => {
        if (respData.data.statusCode === apiSuccessCode.success) {
          showAlert(1, respData.data.message);
          dispatch(getProfile());
          if (callback) callback();
        }
        dispatch(setLoading(false));
      },
      (error: any) => {
        dispatch(setLoading(false));
        showAlert(2, error.message);
      }
    );
  };

// Action function to get assets on sell.
export const AssetsOnSellListing =
  () => (dispatch: Dispatch<any>, getState: () => RootState) => {
    dispatch(setLoading(true));
    const { page, limit } = getState().property.assetOnSellParameters;
    const params = `?page=${page}&limit=${limit}`;

    getApiCall(
      EndPoint.GetAssetsOnSellListing + params,
      "",
      (respData: AxiosResponse<any>) => {
        const { data } = respData.data;

        dispatch(setAssetsOnSellListing(data));
        dispatch(setLoading(false));
      },
      (error: any) => {
        dispatch(setLoading(false));
        showAlert(2, error.message);
        dispatch(setLoading(false));
      }
    );
  };

// Action function to details of assets on sell.
export const AssetsOnSellDetails =
  (id: string) => (dispatch: Dispatch<any>) => {
    dispatch(setLoading(true));

    getApiCall(
      `${EndPoint.GetAssetsOnSellDetails}?id=${id}`,
      "",
      (respData: AxiosResponse<any>) => {
        const { data } = respData.data;

        dispatch(setAssetsOnSellDetails(data));
        dispatch(setLoading(false));
      },
      (error: any) => {
        dispatch(setLoading(false));
        showAlert(2, error.message);
        dispatch(setLoading(false));
      }
    );
  };

// Action function to get all properties.
export const getAllProperties =
  (id: string) => (dispatch: Dispatch<any>, getState: () => RootState) => {
    dispatch(setLoading(true));
    const { page, limit } = getState().property.propertyListingQueryParameters;
    const params = `?identityId=${id}&page=${page}&limit=${limit}`;

    getApiCall(
      `${EndPoint.GetAllProperties}${params}`,
      "",
      (response: AxiosResponse<any>) => {
        const { data } = response.data;
        if (response.data.statusCode === apiSuccessCode.success) {
          dispatch(setPropertyListing(data.result));
        }
        dispatch(setLoading(false));
      },
      (error: any) => {
        dispatch(setLoading(false));
        showAlert(2, error.message);
      }
    );
  };

// Action function to get property details.
export const getPropertyDetails = (id: any) => (dispatch: Dispatch<any>) => {
  dispatch(setLoading(true));

  getApiCall(
    `${EndPoint.GetPropertyDetails}?propertyId=${id}`,
    "",
    (response: AxiosResponse<any>) => {
      const { data } = response.data;
      if (response.data.statusCode === apiSuccessCode.success) {
        dispatch(setPropertyDetails(data));
      }
      dispatch(setLoading(false));
    },
    (error: any) => {
      dispatch(setLoading(false));
      showAlert(2, error.message);
    }
  );
};

// Action function to create sell order
export const createSellOrder =
  (values: any, data: any, callback?: () => void) =>
  (dispatch: Dispatch<any>) => {
    dispatch(setLoading(true));

    const dataToSend = {
      ...values,
      ...data,
    };

    postApiCall(
      EndPoint.CreateSellOrder,
      dataToSend,
      (respData: AxiosResponse<any>) => {
        if (respData.data.statusCode === apiSuccessCode.success) {
          showAlert(1, respData.data.message);
          if (callback) callback();
        }
        dispatch(setLoading(false));
      },
      (error: any) => {
        dispatch(setLoading(false));
        showAlert(2, error.message);
      }
    );
  };

// Action function to get property on sell listing
export const getMySellListing =
  () => (dispatch: Dispatch<any>, getState: () => RootState) => {
    dispatch(setLoading(true));
    const { page, limit } = getState().property.mySellPropertyParamteres;
    const params = `?page=${page}&limit=${limit}`;

    getApiCall(
      `${EndPoint.GetMySellOrderListing}${params}`,
      "",
      (response: AxiosResponse<any>) => {
        const { data } = response.data;
        if (response.data.statusCode === apiSuccessCode.success) {
          dispatch(setMySellPropertyListing(data.result));
        }
        dispatch(setLoading(false));
      },
      (error: any) => {
        dispatch(setLoading(false));
        showAlert(2, error.message);
      }
    );
  };

// Action function to get property on buy listing
export const getMyBuyListing =
  () => (dispatch: Dispatch<any>, getState: () => RootState) => {
    dispatch(setLoading(true));
    const { page, limit } = getState().property.myBuyPropertyParamteres;
    const params = `?page=${page}&limit=${limit}`;

    getApiCall(
      `${EndPoint.GetMyBuyOrderListing}${params}`,
      "",
      (response: AxiosResponse<any>) => {
        const { data } = response.data;
        if (response.data.statusCode === apiSuccessCode.success) {
          dispatch(setMyBuyPropertyListing(data.result));
        }
        dispatch(setLoading(false));
      },
      (error: any) => {
        dispatch(setLoading(false));
        showAlert(2, error.message);
      }
    );
  };

// Action function to create buy property order
export const createBuyProperty =
  (dataToSend: any, callback?: () => void) =>
  (dispatch: Dispatch<any>, getState: () => RootState) => {
    dispatch(setLoading(true));
    const { signedPropertyInfo } = getState().property;
    const data = {
      ...dataToSend,
      ...signedPropertyInfo,
    };
    postApiCall(
      EndPoint.CreateBuyOrder,
      data,
      (respData: AxiosResponse<any>) => {
        if (respData.data.statusCode === apiSuccessCode.success) {
          showAlert(1, respData.data.message);
          if (callback) callback();
        }
        dispatch(setLoading(false));
      },
      (error: any) => {
        dispatch(setLoading(false));
        showAlert(2, error.message);
      }
    );
  };

// Action function to get exchange amount
export const getExchangeAmount =
  (dataToSend: any, callback?: () => void) => (dispatch: Dispatch<any>) => {
    dispatch(setLoading(true));

    postApiCall(
      EndPoint.GetExchangeAmount,
      dataToSend,
      (respData: AxiosResponse<any>) => {
        if (respData.data.statusCode === apiSuccessCode.success) {
          const { data } = respData.data;
          dispatch(setExchangeAmount(data));
          if (callback) callback();
        }
        dispatch(setLoading(false));
      },
      (error: any) => {
        dispatch(setLoading(false));
        showAlert(2, error.message);
      }
    );
  };

// Action function to retrieve address
export const retrieveAddress =
  (values: any, callback?: () => void) => (dispatch: Dispatch<any>) => {
    dispatch(setLoading(true));
    const { accountId, network, assetType, addressFrom, comment, amount } =
      values;

    getApiCall(
      `${EndPoint.RetrieveAddress}`,
      `/${accountId}/${network}/${assetType}`,
      (response: AxiosResponse<any>) => {
        const { data } = response.data;
        if (response.data.statusCode === apiSuccessCode.success) {
          dispatch(setRetrievalAddress(data.address));

          const sendData = {
            network,
            data: {
              addressFrom,
              addressTo: data.address,
              value: `${amount}`,
            },
          };

          const sellCryptoData = {
            accountId,
            fromAsset: assetType,
            toNetwork: network,
            fromAmount: amount,
            comment,
          };

          dispatch(
            sendCrypto(sendData, () => {
              dispatch(sellCrypto(sellCryptoData, callback));
            })
          );
        }
        dispatch(setLoading(false));
      },
      (error: any) => {
        dispatch(setLoading(false));
        showAlert(2, error.message);
      }
    );
  };

// Action function to send crypto
export const sendCrypto =
  (values: any, handleReleaseToken: any, callback?: () => void) =>
  (dispatch: Dispatch<any>, getState: () => RootState) => {
    const { walletListing } = getState().dashboard;
    const walletId = walletListing[0].cryptoWalletId;

    dispatch(setLoading(true));
    const { network, data } = values;
    postApiCall(
      `${EndPoint.SendCrypto}/${walletId}?network=${network}`,
      { ...data },
      (response: AxiosResponse<any>) => {
        if (response.data.statusCode === apiSuccessCode.success) {
          showAlert(
            1,
            "Your transaction is processing, please see the status on wallet screen"
          );
          if (callback) callback();
        } else {
          handleReleaseToken();
        }
        dispatch(setLoading(false));
      },
      (error: any) => {
        dispatch(setLoading(false));
        handleReleaseToken();
        showAlert(2, error.message);
      }
    );
  };

// Action function to sell crypto
export const sellCrypto =
  (values: any, callback?: () => void) => (dispatch: Dispatch<any>) => {
    dispatch(setLoading(true));

    postApiCall(
      `${EndPoint.SellCrypto}`,
      { ...values },
      (response: AxiosResponse<any>) => {
        if (response.data.statusCode === apiSuccessCode.success) {
          if (callback) callback();
        }
        dispatch(setLoading(false));
      },
      (error: any) => {
        dispatch(setLoading(false));
        showAlert(2, error.message);
      }
    );
  };

// Action function to get buy transaction listing
export const getMyBuyTransactionListing =
  (id: string, type: number) =>
  (dispatch: Dispatch<any>, getState: () => RootState) => {
    dispatch(setLoading(true));
    const { page, limit } = getState().property.myBuyTransactionParamteres;

    const params = `?${
      type === 1 ? "buyer" : "seller"
    }IdentityId=${id}&page=${page}&limit=${limit}`;

    getApiCall(
      `${EndPoint.GetMyBuyTransactionListing}${params}`,
      "",
      (response: AxiosResponse<any>) => {
        const { data } = response.data;
        if (response.data.statusCode === apiSuccessCode.success) {
          if (type === 1) dispatch(setBuyTransactionListing(data.result));
          else dispatch(setSellTransactionListing(data.result));
        }
        dispatch(setLoading(false));
      },
      (error: any) => {
        dispatch(setLoading(false));
        showAlert(2, error.message);
      }
    );
  };

// Action function to post lock token
export const LockToken =
  (values: any, callback?: () => void) => (dispatch: Dispatch<any>) => {
    dispatch(setLoading(true));

    postApiCall(
      EndPoint.LockToken,
      values,
      (response: AxiosResponse<any>) => {
        if (response.data.statusCode === apiSuccessCode.success) {
          if (callback) callback();
        }
        dispatch(setLoading(false));
      },
      (error: any) => {
        dispatch(setLoading(false));
        showAlert(2, error.message);
      }
    );
  };

// Action function to revert lock token
export const RevertToken =
  (values: any, callback?: () => void) => (dispatch: Dispatch<any>) => {
    dispatch(setLoading(true));

    postApiCall(
      EndPoint.RevertToken,
      values,
      (response: AxiosResponse<any>) => {
        if (response.data.statusCode === apiSuccessCode.success) {
          if (callback) callback();
        }
        dispatch(setLoading(false));
      },
      (error: any) => {
        dispatch(setLoading(false));
        showAlert(2, error.message);
      }
    );
  };

// Action function for sign transaction
export const confirmSignTransaction =
  (walletId: any, network: string, callback?: any) =>
  (dispatch: Dispatch<any>, getState: () => RootState) => {
    dispatch(setLoading(true));
    const { sellPropertyPDFLink } = getState().property;
    let docString = "";
    axios
      .get(sellPropertyPDFLink, { responseType: "arraybuffer" })
      .then((response: any) => {
        const file = Buffer.from(response.data, "utf-8");
        docString = file.toString("base64");
        var message = utils.solidityKeccak256(["string"], [docString]);

        const dataToSend = {
          message,
        };

        postApiCall(
          `${EndPoint.signTransaction}/${walletId}?network=${network}`,
          dataToSend,
          (response: AxiosResponse<any>) => {
            if (response.data.statusCode === apiSuccessCode.success) {
              const { data } = response.data;
              const addedPropertyInfo = {
                signTransactionDocUrl: sellPropertyPDFLink,
                docHash: message,
                signature: data.signedMessage,
              };
              dispatch(setSignedPropertyInfo(addedPropertyInfo));

              if (callback) callback(addedPropertyInfo);
            }
            dispatch(setLoading(false));
          },
          (error: any) => {
            dispatch(setLoading(false));
            showAlert(2, error.message);
          }
        );
      })
      .catch(() => {
        showAlert(2, "Buffer not created");
      });
  };

// Action function to get agreement details
export const getAgreementDetails =
  (agreementAddress: any, callback?: any) => (dispatch: Dispatch<any>) => {
    dispatch(setLoading(true));

    const values = {
      agreementAddress,
    };

    postApiCall(
      EndPoint.getAgreementDetail,
      values,
      (response: AxiosResponse<any>) => {
        if (response.data.statusCode === apiSuccessCode.success) {
          const { data } = response.data;
          dispatch(setAgreementData(data));
          if (callback) callback();
        }
        dispatch(setLoading(false));
      },
      (error: any) => {
        dispatch(setLoading(false));
        showAlert(2, error.message);
      }
    );
  };
