// This file defines a Redux slice for managing the property detail state.

import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  PropertyModel,
  AssetOnListingTable,
  Parameters,
  PropertyDetails,
  AssetOnSellDetails,
  MySellProperty,
  ExchangeAmount,
  SellTokenInfo,
  SignedPropertyInfoDetails,
  AgreementData,
} from "./type";

// Initial state for the property detail slice
const propertyDetailsInitialValue = {
  _id: "",
  identityId: "",
  name: "",
  address: "",
  symbol: "",
  tokenValue: "",
  granularity: "",
  bdApproved: true,
  documentUrl: "",
  walletAddress: "",
  tokenHolderAddress: "",
  receiptHash: "",
  userId: "",
  createdAt: "",
  updatedAt: "",
  mintedToken: "",
  tokenLeftTosellByOwner: "",
  sellOrderDetails: {
    _id: "",
    identityId: "",
    tokenValue: "",
    noOfTokenToSell: "",
    leftNoOfTokenToSell: "",
    bdApproved: true,
    sellerAddress: "",
    targetAddress: "",
    userId: "",
    propertyId: "",
    createdAt: "",
    updatedAt: "",
    agreementAddress: "",
  },
};

const initialState: PropertyModel = {
  assetOnSellListing: { result: [], next: false, page: 1, total: 10 },
  assetOnSellParameters: { page: 1, limit: 10 },
  propertyListing: [],
  propertyDetails: propertyDetailsInitialValue,
  propertyListingQueryParameters: { page: 1, limit: 10 },
  assetOnSellDetails: {
    _id: "",
    identityId: "",
    tokenValue: "",
    noOfTokenToSell: "",
    leftNoOfTokenToSell: "",
    bdApproved: true,
    sellerAddress: "",
    targetAddress: "",
    createdAt: "",
    updatedAt: "",
    userDetails: {
      _id: "",
      phoneNo: "",
      email: "",
      name: "",
      countryCode: "",
      password: "",
      otp: "",
      otpTimeStamp: "",
      accountVerify: false,
      businessIdentities: [],
      createdAt: "",
      updatedAt: "",
      personalIdentity: "",
      platformType: 1,
      identityContainerId: "",
      accountId: "",
      accountNumber: "",
      personalAccountId: "",
      personalAccountNumber: "",
      cryptoWalletId: [],
    },
    propertyDetails: propertyDetailsInitialValue,
  },
  mySellPropertyListing: [],
  mySellPropertyParamteres: { page: 1, limit: 10 },
  myBuyPropertyListing: [],
  myBuyPropertyParamteres: { page: 1, limit: 10 },
  exchangeAmount: { priceInUsd: 0, priceInCrypto: 0 },
  noOfToken: "",
  addressRetrieved: "",
  buyTransactionListing: [],
  myBuyTransactionParamteres: { page: 1, limit: 10 },
  sellTransactionListing: [],
  mySellTransactionParamteres: { page: 1, limit: 10 },
  sellPropertyPDFLink: "",
  sellTokenInfo: {
    tokenValue: "",
    noOfTokenToSell: "",
  },
  signedPropertyInfo: {
    signTransactionDocUrl: "",
    docHash: "",
    signature: "",
  },

  agreementData: {
    addressSeller: "",
    addressTokenAddress: "",
    uint256TokenValue: {
      type: "",
      hex: "",
    },
    string_docName: "",
    bytes32_docHash: "",
    addressSigner: "",
    uint8_v: 0,
    bytes32_r: "",
    bytes32_s: "",
  },
};

// Create the property detail slice using createSlice from Redux Toolkit
const propertySlice = createSlice({
  name: "property",
  initialState,
  reducers: {
    setAssetsOnSellListing: (
      state: PropertyModel,
      action: PayloadAction<AssetOnListingTable>
    ) => {
      state.assetOnSellListing = action.payload;
    },
    setAssetsOnSellDetails: (
      state: PropertyModel,
      action: PayloadAction<AssetOnSellDetails>
    ) => {
      state.assetOnSellDetails = action.payload;
    },
    setAssetOnSellParameters: (
      state: PropertyModel,
      action: PayloadAction<Parameters>
    ) => {
      state.assetOnSellParameters = action.payload;
    },
    setPropertyListing: (
      state: PropertyModel,
      action: PayloadAction<PropertyDetails[]>
    ) => {
      state.propertyListing = [...action.payload];
    },

    setPropertyDetails: (
      state: PropertyModel,
      action: PayloadAction<PropertyDetails>
    ) => {
      state.propertyDetails = action.payload;
    },

    setPropertyListingQueryParameters: (
      state: PropertyModel,
      action: PayloadAction<Parameters>
    ) => {
      state.propertyListingQueryParameters = action.payload;
    },

    setMySellPropertyListing: (
      state: PropertyModel,
      action: PayloadAction<MySellProperty[]>
    ) => {
      state.mySellPropertyListing = [...action.payload];
    },

    setMySellPropertyQueryParameters: (
      state: PropertyModel,
      action: PayloadAction<Parameters>
    ) => {
      state.mySellPropertyParamteres = action.payload;
    },

    setMyBuyPropertyListing: (
      state: PropertyModel,
      action: PayloadAction<MySellProperty[]>
    ) => {
      state.myBuyPropertyListing = [...action.payload];
    },

    setMyBuyPropertyQueryParameters: (
      state: PropertyModel,
      action: PayloadAction<Parameters>
    ) => {
      state.myBuyPropertyParamteres = action.payload;
    },
    setExchangeAmount: (
      state: PropertyModel,
      action: PayloadAction<ExchangeAmount>
    ) => {
      state.exchangeAmount = action.payload;
    },

    setNoOfToken: (state: PropertyModel, action: PayloadAction<string>) => {
      state.noOfToken = action.payload;
    },

    setRetrievalAddress: (
      state: PropertyModel,
      action: PayloadAction<string>
    ) => {
      state.addressRetrieved = action.payload;
    },

    setBuyTransactionListing: (
      state: PropertyModel,
      action: PayloadAction<[]>
    ) => {
      state.buyTransactionListing = [...action.payload];
    },

    setBuyTransactionListingParameters: (
      state: PropertyModel,
      action: PayloadAction<Parameters>
    ) => {
      state.myBuyTransactionParamteres = action.payload;
    },

    setSellTransactionListing: (
      state: PropertyModel,
      action: PayloadAction<[]>
    ) => {
      state.sellTransactionListing = [...action.payload];
    },

    setSellTransactionListingParameters: (
      state: PropertyModel,
      action: PayloadAction<Parameters>
    ) => {
      state.mySellTransactionParamteres = action.payload;
    },

    setSellTokenInfo: (
      state: PropertyModel,
      action: PayloadAction<SellTokenInfo>
    ) => {
      state.sellTokenInfo = action.payload;
    },

    setSellPropertyPDFLink: (
      state: PropertyModel,
      action: PayloadAction<string>
    ) => {
      state.sellPropertyPDFLink = action.payload;
    },

    setSignedPropertyInfo: (
      state: PropertyModel,
      action: PayloadAction<SignedPropertyInfoDetails>
    ) => {
      state.signedPropertyInfo = action.payload;
    },

    setAgreementData: (
      state: PropertyModel,
      action: PayloadAction<AgreementData>
    ) => {
      state.agreementData = action.payload;
    },
  },
});

// Export the action creators generated by createSlice
export const {
  setAssetsOnSellListing,
  setAssetOnSellParameters,
  setPropertyDetails,
  setPropertyListing,
  setPropertyListingQueryParameters,
  setAssetsOnSellDetails,
  setMySellPropertyListing,
  setMySellPropertyQueryParameters,
  setMyBuyPropertyListing,
  setMyBuyPropertyQueryParameters,
  setExchangeAmount,
  setNoOfToken,
  setRetrievalAddress,
  setBuyTransactionListing,
  setBuyTransactionListingParameters,
  setSellTransactionListing,
  setSellTransactionListingParameters,
  setSellTokenInfo,
  setSellPropertyPDFLink,
  setSignedPropertyInfo,
  setAgreementData,
} = propertySlice.actions;

// Export the reducer function generated by createSlice
export default propertySlice.reducer;
