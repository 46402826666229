import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import moment from "moment";

import { commonTableStyles as styles } from "../styles";
import ContainedButton from "../../button/contained-button";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { checkBalance } from "../../../pages/fo.dashboard/services/action";
import { Paths } from "../../../common/constants";

/**
 * Component for rendering property table.
 *
 * @param {object} data - The data for the table.
 * @returns {JSX.Element} The rendered component.
 */
const PropertyListing = ({ listingData, handleOpen }: any) => {
  const dispatch = useAppDispatch();

  // Function to check account balance
  const handleCheckBalClick = (tokenAddress: string, walletAddress: string) => {
    dispatch(checkBalance(tokenAddress, walletAddress, () => handleOpen()));
  };

  return (
    <TableContainer>
      <Table sx={styles.table} stickyHeader aria-label="customized table">
        <TableHead sx={styles.tableHeader}>
          <TableRow sx={styles.headerRow}>
            <TableCell>Name</TableCell>
            <TableCell>Address</TableCell>
            <TableCell>Symbol</TableCell>
            <TableCell>Token Quantity</TableCell>
            <TableCell>Created At</TableCell>
            <TableCell>Wallet Address</TableCell>
            <TableCell>Token Contract Address</TableCell>
            <TableCell>Transactions</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {listingData && listingData.length > 0 ? (
            listingData.map((data: any) => (
              <TableRow key={data._id} sx={styles.row}>
                <TableCell>
                  <Link
                    style={styles.link}
                    to={`${Paths.PROPERTY_DETAIL}?id=${data._id}`}
                  >
                    <Typography>{data.name}</Typography>
                  </Link>
                </TableCell>
                <TableCell>
                  <Typography>{data.address}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{data.symbol}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{data.mintedToken}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>
                    {moment(data.createdAt).format("YYYY-MM-DD")}
                  </Typography>
                </TableCell>
                <TableCell sx={styles.tableCell}>
                  <Typography>{data.walletAddress}</Typography>
                </TableCell>
                <TableCell sx={styles.tableCell}>
                  <Typography>{data.tokenHolderAddress}</Typography>
                </TableCell>
                <TableCell>
                  <Link
                    to={`https://mumbai.polygonscan.com/tx/${data.receiptHash}`}
                    target="_blank"
                    style={styles.link}
                  >
                    View Transaction
                  </Link>
                </TableCell>
                <TableCell>
                  <ContainedButton
                    title="Check Balance"
                    primary
                    onClick={() =>
                      handleCheckBalClick(
                        data.tokenHolderAddress,
                        data.walletAddress
                      )
                    }
                  />
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={14} align="center">
                <h3>No Records</h3>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PropertyListing;
