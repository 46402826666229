import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { GlobalState } from "./global.types";

const initialState: GlobalState = {
  loading: false,
};

/**
 * This file defines the global slice for managing loading-related state.
 * It includes the reducer function and action creators for loading-related actions.
 */
const globalSlice = createSlice({
  name: "globalSlice",
  initialState,
  reducers: {
    setLoading: (state: GlobalState, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setLoading } = globalSlice.actions;

export default globalSlice.reducer;
