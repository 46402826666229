import { Box, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import styles from "./styles";
import Schema from "../../common/constants/schema";
import SignupHelper from "./helper";
import Input from "../../components/formControls/input";
import ContainedButton from "../../components/button/contained-button";
import CountryPicker from "../../components/countryPicker";
import { onSignup } from "./services/action";
import { Paths } from "../../common/constants";

/**
 * Defines Sign up Page
 * @returns The rendered Component
 */
const SignUp = () => {
  const { navigate, initialValue, dispatch } = SignupHelper();

  return (
    <Box sx={styles.mainContainer}>
      <Box sx={styles.heading}>Create Your Account</Box>
      <Formik
        initialValues={initialValue}
        validationSchema={Schema.RegisterSchema}
        onSubmit={(values, { setSubmitting }) => {
          dispatch(onSignup(values, setSubmitting, navigate));
        }}
      >
        {({ isValid, values, setFieldValue, errors, touched }) => {
          return (
            <Form>
              <Box sx={styles.fieldContainer}>
                <Box sx={styles.nameContainer}>
                  <Input
                    name="firstname"
                    label="First Name"
                    placeholder="First Name"
                    fullWidth
                    externalStyles={styles.nameField}
                    isRequired
                  />
                  <Input
                    name="lastname"
                    label="Last Name"
                    placeholder="Last Name"
                    fullWidth
                    externalStyles={styles.nameField}
                    isRequired
                  />
                </Box>
                <Input
                  name="email"
                  type="email"
                  label="Email Address"
                  placeholder="Email Address"
                  fullWidth
                  externalStyles={styles.textField}
                  isRequired
                />
                <Typography
                  component="div"
                  variant="h6"
                  color="primary"
                  sx={styles.label}
                >
                  Mobile Number*
                </Typography>
                <Box width="100%" display="flex" alignItems="center">
                  <CountryPicker
                    name="countryCode"
                    sx={styles.countryPicker}
                    value={values.countryCode}
                    onChange={(e) => {
                      setFieldValue("countryCode", e.target.value);
                    }}
                  />
                  <Input
                    isMobile
                    isRequired
                    name="phoneNo"
                    placeholder="Mobile Number"
                    fullWidth
                    externalStyles={{ width: "100%" }}
                  />
                </Box>
              </Box>
              {touched.phoneNo && (
                <Typography sx={styles.error}>{errors.phoneNo}</Typography>
              )}
              <Input
                label="Password"
                placeholder="Password"
                name="password"
                fullWidth
                isPassword
                externalStyles={styles.textField}
                isRequired
              />
              <Box sx={styles.btnContainer}>
                <ContainedButton
                  title="Sign up"
                  disabled={!isValid}
                  externalStyles={styles.btn}
                  fullWidth
                  size="large"
                  type="submit"
                />
              </Box>
              <Box sx={styles.footerContainer}>
                <Typography
                  sx={styles.footerLink}
                  onClick={() => {
                    navigate(Paths.LOGIN);
                  }}
                >
                  Back To Login
                </Typography>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default SignUp;
