import { ChangeEvent } from "react";
import { Formik, Form } from "formik";
import { Box, Typography } from "@mui/material";
import TaskAltRoundedIcon from "@mui/icons-material/TaskAltRounded";

import styles from "./styles";
import CompleteKYCHelper from "./helper";
import SelectComp from "../../../components/formControls/select/singleSelect";
import OutlinedButton from "../../../components/button/outlined-button";
import ContainedButton from "../../../components/button/contained-button";
import Schema from "../../../common/constants/schema";
import Upload from "../../../components/upload";
import { saveKYCDocs } from "../services/action";

/**
 * Defines Complete KYC Page
 * @returns The Complete KYC Component
 */
const CompleteKYC = () => {
  const { initialValues, uploadImage, dispatch, navigate, userDetails } =
    CompleteKYCHelper();

  return (
    <Box sx={styles.mainContainer}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={Schema.CompleteYourKYC}
        onSubmit={(values) => {
          dispatch(saveKYCDocs(navigate));
        }}
      >
        {({ isValid, setFieldValue, values }) => (
          <Form>
            <Box>
              <Typography sx={styles.mainHeading}>Complete Your KYC</Typography>
              <Box>
                <SelectComp
                  name="doc_type"
                  label="Document Type"
                  externalStyles={styles.fullWidth}
                  options={[
                    { id: "drivers_license", value: "Driving License" },
                    { id: "government_id", value: "Government ID" },
                    { id: "passport", value: "Passport" },
                    { id: "residence_permit", value: "Residence Permit" },
                    { id: "utility_bill", value: "Utility Bill" },
                    { id: "other", value: "Other" },
                  ]}
                  labelKey="value"
                  valKey="id"
                  fullWidth
                />
              </Box>
              <Box sx={styles.container}>
                <Typography>Upload Your Front Document</Typography>
                {userDetails.frontDocumentId ? (
                  <Box sx={styles.uploadAgainContainer}>
                    <TaskAltRoundedIcon />
                    <Upload
                      name={"front"}
                      accept={"image/png"}
                      handleImageChange={(
                        evt: ChangeEvent<HTMLInputElement>
                      ) => {
                        uploadImage(evt, setFieldValue, "front", values);
                      }}
                      imageName={"Upload Again"}
                    />
                  </Box>
                ) : (
                  <Upload
                    name={"front"}
                    accept={"image/png"}
                    handleImageChange={(evt: ChangeEvent<HTMLInputElement>) => {
                      uploadImage(evt, setFieldValue, "front", values);
                    }}
                    imageName={"Upload Front Image"}
                  />
                )}
              </Box>
              <Box sx={styles.container}>
                <Typography>Upload Your Back Document</Typography>
                {userDetails.backSideDocumentId ? (
                  <Box sx={styles.uploadAgainContainer}>
                    <TaskAltRoundedIcon />
                    <Upload
                      name={"back"}
                      accept={"image/png"}
                      handleImageChange={(
                        evt: ChangeEvent<HTMLInputElement>
                      ) => {
                        uploadImage(evt, setFieldValue, "back", values);
                      }}
                      imageName={"Upload Again"}
                    />
                  </Box>
                ) : (
                  <Upload
                    name={"back"}
                    accept={"image/png"}
                    handleImageChange={(evt: ChangeEvent<HTMLInputElement>) => {
                      uploadImage(evt, setFieldValue, "back", values);
                    }}
                    imageName={"Upload Back Image"}
                  />
                )}
              </Box>
              <Box sx={styles.btnContainer}>
                <ContainedButton
                  type="submit"
                  externalStyles={styles.btn}
                  title="Save"
                  disabled={!isValid}
                />
                <OutlinedButton externalStyles={styles.btn} title="Cancel" />
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default CompleteKYC;
