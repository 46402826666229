export const focusHandler = (setFocus: any) => setFocus(true);

export const blurHandler = (evt: any, field: any, setFocus: any) => {
  field.onBlur(evt);
  setFocus(false);
};

export const pasteHandler = (evt: any, name: string) => {
  if (name === "confirmPassword") evt.preventDefault();
};
