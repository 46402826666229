import { combineReducers } from "@reduxjs/toolkit";

import GlobalSlice from "./global.slice";
import HomeSlice from "../pages/pt.forms/services/slice";
import LoginSLice from "../pages/login/services/slice";
import BusinessSlice from "../pages/fo.business.id/services/slice";
import PersonalSlice from "../pages/fo.forms/services/slice";
import DashboardSlice from "../pages/fo.dashboard/services/slice";
import FundSlice from "../pages/fund.card/services/slice";
import PropertySlice from "../pages/fo.property/services/slice";

/**
 * This file defines the root reducer for the Redux store.
 * It combines multiple reducers into a single reducer.
 */

// Combine individual reducers into the root reducer
const rootReducer = combineReducers({
  global: GlobalSlice,
  home: HomeSlice,
  login: LoginSLice,
  business: BusinessSlice,
  personal: PersonalSlice,
  dashboard: DashboardSlice,
  fund: FundSlice,
  property: PropertySlice,
});

export default rootReducer;
