const styles = {
  mainContainer: {
    display: "flex",
    width: "100%",
  },
  imgContainer: {
    display: "flex",
    justifyContent: "center",
    padding: "50px 0",
  },
  logo: {
    width: "60%",
    height: "100vh",
  },
  childContainer: {
    margin: "auto",
    width: "30%",
  },
};

export default styles;
