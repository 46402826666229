import { Box, Typography } from "@mui/material";

import { styles } from "./styles";
import ContainedButton from "../../components/button/contained-button";
import AddFundCardhelper from "./helper";
import { Paths } from "../../common/constants";
import FundListing from "../../components/tables/fundListing";
import CardPaymentModal from "../../components/modal/cardPaymentModal";
import SimpleModal from "../../components/modal";
import { useState } from "react";

/**
 * Defines Add Fund Card Page
 * @returns The Add Fund Card Component
 */
const AddFundCard = () => {
  const {
    id,
    type,
    navigate,
    fundListing,
    openPaymentModal,
    handleOpenModal,
    handleCloseModal,
  } = AddFundCardhelper();

  const [cardId, setCardId] = useState("");

  return (
    <Box sx={styles.mainContainer}>
      <Box sx={styles.btnContainer}>
        <ContainedButton
          title="Add Card"
          externalStyles={styles.btn}
          primary
          onClick={() =>
            navigate(`${Paths.FORTRESS_ADD_CARD}?type=${type}&id=${id}`)
          }
        />
      </Box>
      <Box sx={styles.listingContainer}>
        <Typography>All Cards</Typography>

        <FundListing
          listingData={fundListing}
          handleOpenModal={handleOpenModal}
          setCardId={setCardId}
        />
      </Box>

      <SimpleModal
        isOpen={openPaymentModal}
        handleClose={handleCloseModal}
        children={
          <CardPaymentModal
            CId={id}
            externalId={cardId}
            closeModal={handleCloseModal}
          />
        }
      />
    </Box>
  );
};

export default AddFundCard;
