import { Box, Typography } from "@mui/material";

import CardDetailHelper from "./helper";
import Cards from "../../../components/card";
import { styles } from "./styles";
import moment from "moment";

/**
 * Defines Fund Card Details Page
 * @returns The Fund Card Details Component
 */
const FundCardDetail = () => {
  const { fundCardDetails: FD } = CardDetailHelper();

  return (
    <Box sx={styles.CD_mainContainer}>
      <Cards
        childComp={
          <>
            <Typography sx={styles.FD_heading}>
              External Account Detail
            </Typography>

            <Box sx={styles.accountSummary_cardContentContainer}>
              <Box
                sx={{
                  ...styles.accountSummary_detailsContainer,
                  ...styles.FD_detailsContainer,
                }}
              >
                <Box sx={styles.textContainer}>
                  <Typography sx={styles.textHeading}>Account ID</Typography>
                  <Typography>{FD?.externalAccountId}</Typography>
                </Box>
                <Box sx={styles.textContainer}>
                  <Typography sx={styles.textHeading}>
                    Owner Identity Id
                  </Typography>
                  <Typography>{FD?.ownerIdentityId}</Typography>
                </Box>
                <Box sx={styles.textContainer}>
                  <Typography sx={styles.textHeading}>Account Type</Typography>
                  <Typography>{FD?.type}</Typography>
                </Box>
                <Box sx={styles.textContainer}>
                  <Typography sx={styles.textHeading}>Network</Typography>
                  <Typography>{FD?.network}</Typography>
                </Box>

                <Box sx={styles.textContainer}>
                  <Typography sx={styles.textHeading}>Card Number</Typography>
                  <Typography>{`**** **** **** ${FD?.accountNumberLast4}`}</Typography>
                </Box>
                <Box sx={styles.textContainer}>
                  <Typography sx={styles.textHeading}>Created At</Typography>
                  <Typography>
                    {moment(FD?.createdAt).format("YYYY-MM-DD")}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </>
        }
      />
    </Box>
  );
};

export default FundCardDetail;
