import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { AssetsOnSellDetails } from "../services/action";
import { RootState } from "../../../redux/store";
import {
  getBusinessCustodialAccountListing,
  getBusinessCustodialAccountListingFortress,
} from "../../fo.business.id/services/action";
import { getWalletListing } from "../../fo.dashboard/services/action";

// Helper Component for Buy Property Details Component
const useBuyPropertyDetailsHelper = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const propId = searchParams.get("propId") || "";
  const address = searchParams.get("address") || "";
  const id = searchParams.get("id") || "";

  const [sourceCusID, setSourceCusId] = useState("");
  const [DestinationCusID, setDestinationCusId] = useState("");

  const { assetOnSellDetails, noOfToken, exchangeAmount } = useAppSelector(
    (state: RootState) => state.property
  );

  const { custodialAccountListing, custodialAccountListing1 } = useAppSelector(
    (state: RootState) => state.business
  );

  const [openSellModal, setOpenSellModal] = useState(false);

  useEffect(() => {
    dispatch(AssetsOnSellDetails(propId));
  }, [propId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (id) dispatch(getWalletListing(id));
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (id) {
      dispatch(getBusinessCustodialAccountListingFortress(id));
    }
    if (custodialAccountListing.length) {
      setSourceCusId(custodialAccountListing[0].id);
    }
  }, [custodialAccountListing.length]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (assetOnSellDetails.userDetails.personalIdentity) {
      dispatch(
        getBusinessCustodialAccountListing(
          assetOnSellDetails.userDetails.personalIdentity
        )
      );
    }
    if (custodialAccountListing1.length) {
      setDestinationCusId(custodialAccountListing1[0].id);
    }
  }, [assetOnSellDetails, custodialAccountListing1.length]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleOpenSellModal = () => {
    setOpenSellModal(true);
  };

  const handleCloseSellModal = () => {
    setOpenSellModal(false);
  };

  const [openPaymentModal, setOpenPaymentModal] = useState(false);
  const [openBuyCertModal, setOpenBuyCertModal] = useState(false);

  const handleOpenPaymentModal = () => {
    setOpenPaymentModal(true);
  };

  const handleClosePaymentModal = () => {
    setOpenPaymentModal(false);
  };

  const handleOpenBuyCertModal = () => {
    setOpenBuyCertModal(true);
  };

  const handleCloseBuyCertModal = () => {
    setOpenBuyCertModal(false);
  };

  return {
    navigate,
    dispatch,
    propId,
    assetOnSellDetails,
    address,
    sourceCusID,
    DestinationCusID,
    noOfToken,
    exchangeAmount,
    openSellModal,
    handleOpenSellModal,
    handleCloseSellModal,
    openPaymentModal,
    handleClosePaymentModal,
    handleOpenPaymentModal,
    openBuyCertModal,
    handleCloseBuyCertModal,
    handleOpenBuyCertModal,
    id,
  };
};

export default useBuyPropertyDetailsHelper;
