/**
 * Constant values.
 */

// Tab constants
const TAB = {
  details: 0,
  accounts: 1,
  wallets: 2,
};

// Wallet type constants
const WALLET_TYPE = {
  PERSONAL: 1,
  BUSINESS: 2,
};

// Enum object combining all constants
const ENUM = {
  TAB,
  WALLET_TYPE,
};

export default ENUM;
