import { COLORS } from "../../../common/constants/colors";

const styles = {
  btnPrimary: {
    textTransform: "capitalize",
    cursor: "pointer",
    borderRadius: "0",
    background: `linear-gradient(30.99deg, ${COLORS.primaryBlue} 0%, ${COLORS.lightBlue} 100%)`,
    font: `normal normal 20px/22px Roboto`,
    boxShadow: `0px 10px 14px rgba(0, 0, 0, 0.15)`,
    "&.MuiButtonBase-root": {
      fontFamily: "SofiaPro",
      color: `${COLORS.white} !important`,
      "&:hover": {
        background: `linear-gradient(30.99deg, ${COLORS.primaryBlue} 0%, ${COLORS.lightBlue} 100%)`,
        color: COLORS.white,
      },
      "&:disabled": {
        background: "#F3F3F3",
        color: "rgba(24, 27, 32, 0.87) !important",
        backdropFilter: "blur(20px)",
        boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.15)",
      },
    },
  },
  btnNormal: {
    textTransform: "capitalize",
    cursor: "pointer",
    borderRadius: "0",
  },
};
export default styles;
