import { Box, Typography } from "@mui/material";
import OutlinedButton from "../button/outlined-button";
import { useAppSelector } from "../../hooks/useAppSelector";
import { RootState } from "../../redux/store";
import { styles } from "./styles";

/**
 * Component for rendering the content container of the agreement details.
 *
 * @param {object} Key - The key of the content.
 * @param {object} Value - The value of the content.
 * @returns {JSX.Element} AgreementContentContainer component.
 */
const AgreementContentContainer = ({ Key, Value }: any) => {
  return (
    <Box>
      <Box sx={styles.agreementContent}>
        <Typography sx={styles.agreementHeading}>{Key}</Typography>
        <Typography sx={styles.agreementData}>{Value}</Typography>
      </Box>
    </Box>
  );
};

/**
 * Component for rendering the agreement detail modal.
 *
 * @param {function} closeModal - The function to close the modal.
 * @returns {JSX.Element} AgreementDetailModal component.
 */
const AgreementDetailModal = ({ closeModal }: any) => {
  const { agreementData: AD } = useAppSelector(
    (state: RootState) => state.property
  );

  return (
    <Box sx={styles.modalStyle}>
      <Box sx={styles.agreementDetailModal}>
        <Typography sx={styles.modalHeading}>Agreement Details</Typography>
        <AgreementContentContainer
          Key={"Doc Name:"}
          Value={AD.string_docName}
        />
        <AgreementContentContainer
          Key={"Signer Address:"}
          Value={AD.addressSigner}
        />
        <AgreementContentContainer
          Key={"Token Address:"}
          Value={AD.addressTokenAddress}
        />
        <AgreementContentContainer
          Key={"DocHash:"}
          Value={AD.bytes32_docHash}
        />
        <AgreementContentContainer Key={"V(UINT8):"} Value={AD.uint8_v} />
        <AgreementContentContainer Key={"S(BYTES32):"} Value={AD.bytes32_s} />
        <AgreementContentContainer Key={"R(BYTES32):"} Value={AD.bytes32_r} />

        <OutlinedButton
          title="Close"
          externalStyles={styles.btn}
          onClick={() => closeModal()}
        />
      </Box>
    </Box>
  );
};

export default AgreementDetailModal;
