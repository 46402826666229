import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../hooks/useAppDispatch";

// Helper Component for Verification Component
const VerificationHelper = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const initialValue = { otp: "" };

  return { navigate, initialValue, dispatch };
};

export default VerificationHelper;
