import { Box } from "@mui/material";
import { Form, Formik } from "formik";

import Input from "../../formControls/input";
import ContainedButton from "../../button/contained-button";
import OutlinedButton from "../../button/outlined-button";
import styles from "./styles";
import Schema from "../../../common/constants/schema";
import { Paths } from "../../../common/constants";
import { RootState } from "../../../redux/store";

import { addCustodialFortressAccount } from "../../../pages/fo.forms/services/action";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { useAppSelector } from "../../../hooks/useAppSelector";

/**
 * Renders a Add Custodial Account Form for creating custodial account.
 * @returns {React.ReactNode} The rendered component.
 */
const AddCustodialAccountFortress = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { userDetails } = useAppSelector((state: RootState) => state.login);

  const firstName = userDetails.name.split(" ")[0];
  const lastName = userDetails.name.split(" ")[1];

  const initialValues = {
    firstName: userDetails.name ? firstName : "",
    lastName: userDetails.name ? lastName : "",
    phone: userDetails.phoneNo,
    email: userDetails.email,
    dateOfBirth: "",
    ssn: "",
    street1: "",
    street2: "",
    postalCode: "",
    city: "",
    state: "",
    country: "",
  };

  return (
    <Box sx={styles.custodialForm_mainContainer}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={Schema.CreateCustodialFortressSchema}
        onSubmit={(values, { setSubmitting }) => {
          dispatch(addCustodialFortressAccount(values, setSubmitting));
        }}
      >
        {(props) => {
          return (
            <Form>
              <Box sx={styles.formContainer}>
                <Input
                  name="firstName"
                  type="text"
                  label="First Name"
                  placeholder="First Name"
                  fullWidth
                  isRequired
                  disabled
                  externalStyles={styles.textField}
                />
                {/* <Input
                  name="middleName"
                  type="text"
                  label="Middle Name"
                  placeholder="Middle Name"
                  fullWidth
                  externalStyles={styles.textField}
                /> */}
                <Input
                  name="lastName"
                  type="text"
                  label="Last Name"
                  placeholder="Last Name"
                  fullWidth
                  isRequired
                  disabled
                  externalStyles={styles.textField}
                />
                <Input
                  name="email"
                  type="email"
                  label="Email Address"
                  placeholder="Email Address"
                  fullWidth
                  isRequired
                  disabled
                  externalStyles={styles.textField}
                />
                <Input
                  name="phone"
                  type="text"
                  label="Phone Number"
                  placeholder="Phone Number"
                  fullWidth
                  isRequired
                  disabled
                  externalStyles={styles.textField}
                />
                <Input
                  name="dateOfBirth"
                  type="text"
                  label="Date of Birth"
                  placeholder="Date of Birth"
                  fullWidth
                  isRequired
                  externalStyles={styles.textField}
                />
                <Input
                  name="ssn"
                  type="text"
                  label="SSN Number"
                  placeholder="SSN Number"
                  fullWidth
                  isRequired
                  externalStyles={styles.textField}
                />

                <Input
                  name="street1"
                  type="text"
                  label="Street 1"
                  placeholder="Street 1"
                  fullWidth
                  isRequired
                  externalStyles={styles.textField}
                />
                <Input
                  name="street2"
                  type="text"
                  label="Street 2"
                  placeholder="Street 2"
                  fullWidth
                  isRequired
                  externalStyles={styles.textField}
                />
                <Input
                  name="postalCode"
                  type="text"
                  label="Postal Code"
                  placeholder="Postal Code"
                  fullWidth
                  isRequired
                  externalStyles={styles.textField}
                />
                <Input
                  name="city"
                  type="text"
                  label="City"
                  placeholder="City"
                  fullWidth
                  isRequired
                  externalStyles={styles.textField}
                />
                <Input
                  name="state"
                  type="text"
                  label="State"
                  placeholder="State"
                  fullWidth
                  isRequired
                  externalStyles={styles.textField}
                />
                <Input
                  name="country"
                  type="text"
                  label="Country"
                  placeholder="Country"
                  fullWidth
                  isRequired
                  externalStyles={styles.textField}
                />
              </Box>
              <Box sx={styles.btnContainer}>
                <ContainedButton
                  title={"Save"}
                  externalStyles={styles.btn}
                  size="large"
                  type="submit"
                  fullWidth
                />
                <OutlinedButton
                  title="Cancel"
                  externalStyles={styles.btn}
                  size="large"
                  fullWidth
                  onClick={() => {
                    navigate(Paths.HOME);
                  }}
                />
              </Box>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default AddCustodialAccountFortress;
