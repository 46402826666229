import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import { RootState } from "../../redux/store";
import { getAllExternalAccounts } from "./services/action";
// import { getProfile } from "../login/services/action";
import { getCustodialAccountDetailFortress } from "../fo.business.id/services/action";

// Helper Component for Add Fund Card Component
const AddFundCardhelper = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const type = searchParams.get("type");
  const id = searchParams.get("id");

  useEffect(() => {
    // dispatch(getProfile());
    if (id) dispatch(getCustodialAccountDetailFortress(id));
    // dispatch(getAllExternalAccounts(userDetails.personalIdentity, "card"));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // const { userDetails } = useAppSelector((state: RootState) => state.login);
  const { custodialAccountDetails: CD } = useAppSelector(
    (state: RootState) => state.business
  );

  useEffect(() => {
    if (CD.ownerIdentityId)
      dispatch(getAllExternalAccounts(CD.ownerIdentityId, "card"));
  }, [CD.ownerIdentityId]); // eslint-disable-line react-hooks/exhaustive-deps

  const { fundListing } = useAppSelector((state: RootState) => state.fund);

  const [openPaymentModal, setOpenPaymentModal] = useState(false);

  const handleOpenModal = () => setOpenPaymentModal(true);

  const handleCloseModal = () => setOpenPaymentModal(false);

  return {
    navigate,
    dispatch,
    id,
    type,
    fundListing,
    openPaymentModal,
    setOpenPaymentModal,
    handleOpenModal,
    handleCloseModal,
  };
};

export default AddFundCardhelper;
