import { Box, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";

import Schema from "../../common/constants/schema";
import Input from "../formControls/input";
import { styles } from "./styles";
import ContainedButton from "../button/contained-button";
import OutlinedButton from "../button/outlined-button";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { addPayment } from "../../pages/fund.card/services/action";
import { Paths } from "../../common/constants";
import SelectComp from "../formControls/select/singleSelect";

/**
 * Component for rendering the TransferExternalModal.
 *
 * @param {object} optionListing - The option Listing of transfer.
 * @param {function} closeModal - The function to close the modal.
 * @param {function} type - The type of transfer.
 * @returns {JSX.Element} TransferExternalModal component.
 */
const TransferExternalModal = ({
  optionListing,
  CId,
  closeModal,
  type,
}: any) => {
  const initialValues = {
    selectedId: "",
    funds: "",
    comment: "",
  };

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return (
    <Box sx={styles.modalStyle}>
      <Box>
        <Typography sx={styles.modalHeading}>
          {type === "cus"
            ? `Transfer to Custodial Account`
            : `Transfer to External Account`}
        </Typography>

        <Formik
          initialValues={initialValues}
          validationSchema={Schema.TransferSchema}
          onSubmit={(values, { setSubmitting }) => {
            if (type === "cus")
              dispatch(
                addPayment({
                  values,
                  setSubmitting,
                  SCAID: CId,
                  DCAID: values.selectedId,
                  callback: () => {
                    closeModal();
                    navigate(
                      `${Paths.FORTRESS_CUSTODIAL_ACCOUNT_DETAIL}?id=${CId}`
                    );
                  },
                })
              );

            if (type === "ext")
              dispatch(
                addPayment({
                  values,
                  setSubmitting,
                  SCAID: CId,
                  DEAID: values.selectedId,
                  callback: () => {
                    closeModal();
                    navigate(
                      `${Paths.FORTRESS_CUSTODIAL_ACCOUNT_DETAIL}?id=${CId}`
                    );
                  },
                })
              );
          }}
        >
          {(props) => {
            return (
              <Form>
                <Box sx={styles.formContainer}>
                  <SelectComp
                    name={"selectedId"}
                    label={
                      type === "cus"
                        ? `Select Custodial Account`
                        : `Select External Account`
                    }
                    fullWidth
                    options={optionListing}
                    labelKey={
                      type === "cus" ? "accountId" : "externalAccountId"
                    }
                    valKey={type === "cus" ? "accountId" : "externalAccountId"}
                    externalStyles={styles.textField}
                  />

                  <Input
                    name="funds"
                    type="text"
                    label="Amount"
                    placeholder="Amount"
                    fullWidth
                    isRequired
                    externalStyles={styles.textField}
                  />
                  <Input
                    name="comment"
                    type="text"
                    label="Comment"
                    placeholder="Comment"
                    fullWidth
                    isRequired
                    externalStyles={styles.textField}
                    multiline
                  />
                </Box>

                <Box sx={styles.btnContainer}>
                  <ContainedButton
                    title={"Save"}
                    externalStyles={styles.btn}
                    size="large"
                    type="submit"
                    fullWidth
                    primary
                  />
                  <OutlinedButton
                    title="Cancel"
                    externalStyles={styles.btn}
                    size="large"
                    fullWidth
                    onClick={() => {
                      closeModal();
                    }}
                  />
                </Box>
              </Form>
            );
          }}
        </Formik>
      </Box>
    </Box>
  );
};

export default TransferExternalModal;
