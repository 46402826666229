// This file defines a Redux slice for managing the dashboard state.

import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  BusinessIdentitylisting,
  DashboardModel,
  IdentitiesListing,
  WalletListing,
  WalletQueryParamters,
  AllIdentitiesQueryParameters,
  BusinessIdentitiesQueryParameters,
  WalletBalance,
} from "./type";

// Initial state for the dashboard slice
const initialState: DashboardModel = {
  identitiesListing: [],
  walletListing: [],
  walletQueryParameters: {
    page: 1,
    limit: 10,
  },
  walletBalance: [],

  allIdentityQueryParameters: {
    page: 1,
    limit: 10,
  },
  businessIdentityListing: [],
  businessIdentitiesQueryParameters: { page: 1, limit: 10 },

  tokenBalance: "",
};

// Create the dashboard slice using createSlice from Redux Toolkit
const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setAllIdentities: (
      state: DashboardModel,
      action: PayloadAction<IdentitiesListing[]>
    ) => {
      state.identitiesListing = [...action.payload];
    },

    setWalletListing: (
      state: DashboardModel,
      action: PayloadAction<WalletListing[]>
    ) => {
      state.walletListing = [...action.payload];
    },

    setWalletQueryParameters: (
      state: DashboardModel,
      action: PayloadAction<WalletQueryParamters>
    ) => {
      state.walletQueryParameters = action.payload;
    },

    setWalletBalance: (
      state: DashboardModel,
      action: PayloadAction<WalletBalance[]>
    ) => {
      state.walletBalance = [...action.payload];
    },

    setAllIdentityQueryParameters: (
      state: DashboardModel,
      action: PayloadAction<AllIdentitiesQueryParameters>
    ) => {
      state.walletQueryParameters = action.payload;
    },
    setBusinessIdentityListing: (
      state: DashboardModel,
      action: PayloadAction<BusinessIdentitylisting[]>
    ) => {
      state.businessIdentityListing = action.payload;
    },
    setBusinessIdentitiesQueryParameters: (
      state: DashboardModel,
      action: PayloadAction<BusinessIdentitiesQueryParameters>
    ) => {
      state.businessIdentitiesQueryParameters = action.payload;
    },

    setTokenBalance: (state: DashboardModel, action: PayloadAction<string>) => {
      state.tokenBalance = action.payload;
    },
  },
});

// Export the action creators generated by createSlice
export const {
  setAllIdentities,
  setWalletListing,
  setWalletQueryParameters,
  setBusinessIdentityListing,
  setBusinessIdentitiesQueryParameters,
  setTokenBalance,
  setWalletBalance,
} = dashboardSlice.actions;

// Export the reducer function generated by createSlice
export default dashboardSlice.reducer;
