import axios, { AxiosResponse } from "axios";
import { Dispatch } from "@reduxjs/toolkit";

import { EndPoint, Env } from "../../../common/constants";
import { postApiCall, getApiCall } from "../../../common/constants/api";
import { apiSuccessCode } from "../../../common/constants/axios.instance";
import { showAlert } from "../../../common/utils/alert";
import { setLoading } from "../../../redux/global.slice";
import {
  setAccountBalance,
  setBusinessDetails,
  setCustodialAccountDetails,
  setCustodialAccountListing,
  setCustodialAccountListing1,
  setTransactionDetails,
  setTransactionListing,
  setUsersCustodialAccountListing,
} from "./slice";
import { getProfile } from "../../login/services/action";
import { RootState } from "../../../redux/store";

// Action function to get business id
export const getBusinessID =
  (values: any, callback?: () => void) => (dispatch: Dispatch<any>) => {
    if (!navigator.onLine) {
      showAlert(2, "No internet connection!");
      return;
    }

    const {
      companyName,
      ein,
      website,
      street1,
      street2,
      postalCode,
      city,
      state,
      regionOfFormation,
      description,
      establishedOn,
      legalStructure,
      naics,
      naicsDescription,
      phoneNumber,
      email,
      mstreet1,
      mstreet2,
      mcity,
      mstate,
      mpostalCode,
      mcountry,
      country,
      beneficialOwners,
    } = values;

    const BO = beneficialOwners.map((data: any) => data.personalIdentity);

    const dataToSend = {
      companyName,
      ein,
      website,
      businessPhNumber: phoneNumber,
      businessEmail: email,
      street1,
      street2,
      postalCode,
      city,
      state,
      country: country,
      regionOfFormation,
      description,
      establishedOn,
      legalStructure,
      mailingAddressStreet1: mstreet1,
      mailingAddressStreet2: mstreet2,
      mailingAddressPostalCode: mpostalCode,
      mailingAddressCity: mcity,
      mailingAddressState: mstate,
      mailingAddressCountry: mcountry,
      naics,
      naicsDescription,
      beneficialOwners: BO,
    };

    dispatch(setLoading(true));
    postApiCall(
      EndPoint.GetBusinessID,
      dataToSend,
      (response: AxiosResponse<any>) => {
        const { statusCode } = response.data;
        if (statusCode === apiSuccessCode.success) {
          if (callback) callback();
          showAlert(1, "Business Account Created");
        }
        dispatch(setLoading(false));
      },
      (error: any) => {
        dispatch(setLoading(false));
        showAlert(2, error.message);
      }
    );
  };

// Action function to get business container
export const getBusinessContainerFortress =
  (id: string) => (dispatch: Dispatch<any>) => {
    if (!navigator.onLine) {
      showAlert(2, "No internet connection!");
      return;
    }

    dispatch(setLoading(true));
    getApiCall(
      EndPoint.GetBusinessContainer,
      `/${id}`,
      (response: AxiosResponse<any>) => {
        const { statusCode, data } = response.data;
        if (statusCode === apiSuccessCode.success) {
          dispatch(setBusinessDetails(data));
          dispatch(setLoading(false));
        }
      },
      (error: any) => {
        dispatch(setLoading(false));
        showAlert(2, error.message);
      }
    );
  };

// Action function to upload business document
export const uploadBusinessDocumentFortress =
  (values: any, identityId?: any) => (dispatch: Dispatch<any>) => {
    dispatch(setLoading(true));
    const token = localStorage.getItem("auth_token_client_fortress");

    const formdata = new FormData();
    formdata.append("DocumentType", values.doc_type);
    formdata.append("DocumentFront", values.front);
    if (values.back !== "") formdata.append("DocumentBack", values.back);
    if (values.self !== "") formdata.append("SelfPortrait", values.self);

    const config = {
      method: "post",
      url:
        Env.FORTRESS_BASE_URL +
        `${EndPoint.FortressBusinessImageUpload}/${identityId}/documents`,
      headers: {
        Authorization: `Bearer ${token} `,
        accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "*",
        "Access-Control-Allow-Headers": "*",
      },
      data: formdata,
    };

    axios(config)
      .then((response: AxiosResponse<any>) => {
        dispatch(setLoading(false));
        dispatch(UploadBusinessFilesFortress(values));
      })
      .catch((err) => {
        console.log(err);
        dispatch(setLoading(false));
      });
  };

// Action function to upload business files
export const UploadBusinessFilesFortress =
  (values: any) => (dispatch: Dispatch<any>) => {
    dispatch(setLoading(true));
    postApiCall(
      EndPoint.ImageUploadLocally,
      values,
      (respData: AxiosResponse<any>) => {
        dispatch(setLoading(false));
        dispatch(getProfile());
        showAlert(1, "File Uploaded Successfully");
        localStorage.removeItem("auth_token_client_fortress");
      },
      (error: any) => {
        dispatch(setLoading(false));
        showAlert(2, error.message);
      }
    );
  };

// Action function to get custodial account listing
export const getCustodialAccountListing =
  (callback?: any) => (dispatch: Dispatch<any>) => {
    dispatch(setLoading(true));

    getApiCall(
      EndPoint.FortressGetCustodialAccountListing,
      "",
      (respData: AxiosResponse<any>) => {
        const { data } = respData.data;

        dispatch(setUsersCustodialAccountListing(data));
        if (callback) callback();
        dispatch(setLoading(false));
      },
      (error: any) => {
        dispatch(setLoading(false));
        showAlert(2, error.message);
        dispatch(setLoading(false));
      }
    );
  };

// Action function to get custodial account details
export const getCustodialAccountFortress =
  (id: string) => (dispatch: Dispatch<any>) => {
    if (!navigator.onLine) {
      showAlert(2, "No internet connection!");
      return;
    }

    dispatch(setLoading(true));
    getApiCall(
      EndPoint.FortressCustodialAccount,
      `/${id}`,
      (response: AxiosResponse<any>) => {
        const { statusCode, data } = response.data;
        if (statusCode === apiSuccessCode.success) {
          dispatch(setBusinessDetails(data));
          dispatch(setLoading(false));
        }
      },
      (error: any) => {
        dispatch(setLoading(false));
        showAlert(2, error.message);
      }
    );
  };

// Action function to get custodial account details
export const getCustodialAccountDetailFortress =
  (id: string) => (dispatch: Dispatch<any>) => {
    if (!navigator.onLine) {
      showAlert(2, "No internet connection!");
      return;
    }

    dispatch(setLoading(true));
    getApiCall(
      EndPoint.FortressCustodialAccount,
      `/${id}`,
      (response: AxiosResponse<any>) => {
        const { statusCode, data } = response.data;
        if (statusCode === apiSuccessCode.success) {
          dispatch(setCustodialAccountDetails(data));
          dispatch(setLoading(false));
        }
      },
      (error: any) => {
        dispatch(setLoading(false));
        showAlert(2, error.message);
      }
    );
  };

// Action function to get business custodial account listing
export const getBusinessCustodialAccountListingFortress =
  (id: string) => (dispatch: Dispatch<any>) => {
    if (!navigator.onLine) {
      showAlert(2, "No internet connection!");
      return;
    }

    dispatch(setLoading(true));
    getApiCall(
      EndPoint.FortressGetBusinessCustodialAccountListing,
      `/${id}`,
      (response: AxiosResponse<any>) => {
        const { statusCode, data } = response.data;
        if (statusCode === apiSuccessCode.success) {
          dispatch(setCustodialAccountListing(data.data));
          dispatch(setLoading(false));
        }
      },
      (error: any) => {
        dispatch(setLoading(false));
        showAlert(2, error.message);
      }
    );
  };

// Action function to get business custodial account listing
export const getBusinessCustodialAccountListing =
  (id: string) => (dispatch: Dispatch<any>) => {
    if (!navigator.onLine) {
      showAlert(2, "No internet connection!");
      return;
    }

    dispatch(setLoading(true));
    getApiCall(
      EndPoint.FortressGetBusinessCustodialAccountListing,
      `/${id}`,
      (response: AxiosResponse<any>) => {
        const { statusCode, data } = response.data;
        if (statusCode === apiSuccessCode.success) {
          dispatch(setCustodialAccountListing1(data.data));
          dispatch(setLoading(false));
        }
      },
      (error: any) => {
        dispatch(setLoading(false));
        showAlert(2, error.message);
      }
    );
  };

// Action function to upload business document
export const uploadDocumentBusinessFortress =
  (values: any, identityId?: any, callback?: () => void) =>
  (dispatch: Dispatch<any>) => {
    dispatch(setLoading(true));

    const token = localStorage.getItem("auth_token");

    const formdata = new FormData();
    formdata.append("DocumentType", values.doc_type);
    formdata.append("DocumentFront", values.front);
    formdata.append("DocumentBack", values.back);
    formdata.append("identityId", identityId);

    const config = {
      method: "post",
      url: Env.BASE_URL + EndPoint.FortressBusinessFileUpload,
      headers: {
        Authorization: `Bearer ${token} `,
      },
      data: formdata,
    };

    axios(config)
      .then((response: AxiosResponse<any>) => {
        showAlert(1, "Documenets Uploaded Sucessfully");
        if (callback) callback();
        dispatch(setLoading(false));
      })
      .catch((err) => {
        showAlert(2, err.message);
        dispatch(setLoading(false));
      });
  };

// Action function to get transaction listing
export const getTransactionListing =
  (id: any) => (dispatch: Dispatch<any>, getState: () => RootState) => {
    if (!navigator.onLine) {
      showAlert(2, "No internet connection!");
      return;
    }
    dispatch(setLoading(true));
    const { page, limit } = getState().business.transactionQueryParameters;

    const params = `?identityId=${id}&page=${page}&limit=${limit}`;

    getApiCall(
      `${EndPoint.GetTransactionListing}${params}`,
      "",
      (response: AxiosResponse<any>) => {
        const { statusCode, data } = response.data;
        if (statusCode === apiSuccessCode.success) {
          dispatch(
            setTransactionListing({
              data: data.data,
              page,
              total: data.meta.resourceCount,
            })
          );
          dispatch(setLoading(false));
        }
      },
      (error: any) => {
        dispatch(setLoading(false));
        showAlert(2, error.message);
      }
    );
  };

// Action function to get transaction details
export const getTransactionDetails = (id: any) => (dispatch: Dispatch<any>) => {
  if (!navigator.onLine) {
    showAlert(2, "No internet connection!");
    return;
  }
  dispatch(setLoading(true));

  getApiCall(
    `${EndPoint.GetTransactionDetail}/${id}`,
    "",
    (response: AxiosResponse<any>) => {
      const { statusCode, data } = response.data;
      if (statusCode === apiSuccessCode.success) {
        dispatch(setTransactionDetails(data));
        dispatch(setLoading(false));
      }
    },
    (error: any) => {
      dispatch(setLoading(false));
      showAlert(2, error.message);
    }
  );
};

// Action function to get account balance
export const getAccountBalance =
  (id: string, callback: any) => (dispatch: Dispatch<any>) => {
    if (!navigator.onLine) {
      showAlert(2, "No internet connection!");
      return;
    }
    dispatch(setLoading(true));

    getApiCall(
      `${EndPoint.GetAccountBalance}/${id}`,
      "",
      (response: AxiosResponse<any>) => {
        const { statusCode, data } = response.data;
        if (statusCode === apiSuccessCode.success) {
          dispatch(setAccountBalance(data.data));
          if (callback) callback();
          dispatch(setLoading(false));
        }
      },
      (error: any) => {
        dispatch(setLoading(false));
        showAlert(2, error.message);
      }
    );
  };
