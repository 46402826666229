import { COLORS } from "../../common/constants/colors";
import { FontFamily } from "../../common/utils";

const styles = {
  mainContainer: {
    height: "100%",
    padding: "1rem 2rem",
    textAlign: "center",
    width: "80%",
    margin: "10px auto",
  },
  mainHeading: {
    font: FontFamily(500, 32, 35),
    paddingBottom: "40px",
    color: COLORS.black,
  },
  btn: {
    font: FontFamily(700, 30, "1.1"),
    width: "auto",
    margin: "0 30px",
    borderRadius: "4px",
    padding: "50px",
  },
};

export default styles;
