import { Box } from "@mui/material";
import { Form, Formik } from "formik";
import styles from "./styles";
import Schema from "../../common/constants/schema";
import ResetPasswordHelper from "./helper";
import Input from "../../components/formControls/input";
import ContainedButton from "../../components/button/contained-button";
import { resetPassword } from "./services/action";
import { Paths } from "../../common/constants";

/**
 * Defines Reset Password Page
 * @returns The rendered Component
 */
const ResetPassword = () => {
  const { initialValue, dispatch, navigate } = ResetPasswordHelper();

  return (
    <Box sx={styles.mainContainer}>
      <Box sx={styles.heading}>Reset Password</Box>
      <Formik
        initialValues={initialValue}
        validationSchema={Schema.ResetPasswordSchema}
        onSubmit={(values, { setSubmitting }) => {
          dispatch(
            resetPassword(values, setSubmitting, () => {
              navigate(Paths.HOME);
            })
          );
        }}
      >
        {({ isValid }) => {
          return (
            <Form>
              <Box sx={styles.fieldContainer}>
                <Input
                  label="New Password"
                  placeholder="New Password"
                  name="password"
                  fullWidth
                  isPassword
                  isRequired
                  externalStyles={styles.textField}
                />
                <Input
                  label="Confirm Password"
                  placeholder="Confirm Password"
                  name="cnfPassword"
                  fullWidth
                  isPassword
                  isRequired
                  externalStyles={styles.textField}
                />
              </Box>
              <Box sx={styles.btnContainer}>
                <ContainedButton
                  title="Save"
                  disabled={!isValid}
                  externalStyles={styles.btn}
                  fullWidth
                  size="large"
                  type="submit"
                />
              </Box>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default ResetPassword;
