import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Paths } from "../../../common/constants";
import { showAlert } from "../../../common/utils";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { RootState } from "../../../redux/store";
import {
  getCryptoWallet,
  getWalletBalance,
} from "../../fo.dashboard/services/action";
import { getProfile } from "../../login/services/action";
import {
  getAllProperties,
  getMyBuyListing,
  getMyBuyTransactionListing,
  getMySellListing,
} from "../../fo.property/services/action";
import { setWalletDetails } from "../services/slice";

// Helper Component for WalletDetail Component
const WalletDetailHelper = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [searchParams] = useSearchParams();
  const accType = searchParams.get("type") || "";
  const id = searchParams.get("id") || "";

  const name = localStorage.getItem("name") || "";
  const { userDetails } = useAppSelector((state: RootState) => state.login);

  const { custodialAccountListing, businessDetails, walletDetails } =
    useAppSelector((state: RootState) => state.business);

  const { tokenBalance, walletBalance } = useAppSelector(
    (state: RootState) => state.dashboard
  );

  const {
    propertyListing,
    mySellPropertyListing,
    myBuyPropertyListing,
    buyTransactionListing,
    sellTransactionListing,
  } = useAppSelector((state: RootState) => state.property);

  const [showCustodianOptions, setShowCustodianOptions] = useState(false);
  const [accountType, setAccountType] = useState("");
  const [isConfirmationOpen, setConfirmationOpen] = useState(false);
  const [openCheckBalModal, setCheckBalModal] = useState(false);

  const [type, setType] = useState("1");

  const handleTypeChange = (_: any, newValue: string) => {
    setType(newValue);
  };

  const handleConfirmationOpen = () => {
    setConfirmationOpen(true);
  };

  const handleConfirmationClose = () => {
    setConfirmationOpen(false);
  };

  const handleCheckBalModalOpen = () => {
    setCheckBalModal(true);
  };

  const handleCheckBalModalClose = () => {
    setCheckBalModal(false);
  };

  const handleAccount = () => {
    handleConfirmationClose();
    showAlert(2, "Please Create your Business ID first");
    navigate(Paths.FORTRESS_GET_BUSINESS_ID);
  };

  useEffect(() => {
    dispatch(getProfile());
    dispatch(getMySellListing());
    dispatch(getMyBuyListing());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch(getCryptoWallet(id));
    dispatch(getWalletBalance(id));

    return () => {
      dispatch(setWalletDetails({}));
    };
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (walletDetails.identityId) {
      dispatch(getAllProperties(walletDetails.identityId));
      dispatch(getMyBuyTransactionListing(walletDetails.identityId, 1));
      dispatch(getMyBuyTransactionListing(walletDetails.identityId, 2));
    }
  }, [walletDetails]); // eslint-disable-line react-hooks/exhaustive-deps

  const getWalletBalanceMapping = () => {
    const walletBalanceData = walletBalance.map((bal: any) => ({
      id: `${bal.assetType}_${bal.network}`,
      ...bal,
    }));

    const walletAddressData = walletDetails?.addresses?.map((val: any) => ({
      id: `${val.assetType}_${val.network}`,
      ...val,
    }));

    return walletAddressData?.map((data: any) => {
      const balObj = walletBalanceData?.find((ele) => ele.id === data.id);
      return {
        ...data,
        totalBalance: balObj ? balObj.totalBalance : 0,
      };
    });
  };

  return {
    navigate,
    dispatch,
    name,
    userDetails,
    showCustodianOptions,
    setShowCustodianOptions,
    accountType,
    setAccountType,
    isConfirmationOpen,
    handleConfirmationClose,
    handleConfirmationOpen,
    custodialAccountListing,
    handleAccount,
    businessDetails,
    id,
    walletDetails,
    walletBalance,
    propertyListing,
    mySellPropertyListing,
    myBuyPropertyListing,
    buyTransactionListing,
    sellTransactionListing,
    tokenBalance,
    openCheckBalModal,
    setCheckBalModal,
    handleCheckBalModalClose,
    handleCheckBalModalOpen,
    accType,
    type,
    handleTypeChange,
    getWalletBalanceMapping,
  };
};

export default WalletDetailHelper;
