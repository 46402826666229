import { Box, Typography } from "@mui/material";

import styles from "./styles";
import ContainedButton from "../../../components/button/contained-button";
import { Paths } from "../../../common/constants";
import Cards from "../../../components/card";
import WalletDetailHelper from "./helper";
import SimpleModal from "../../../components/modal";
import ConfirmationContainer from "../../../components/confimation";
import WalletDetailsTab from "./walletTab";

/**
 * Defines Balance Modal Component
 * @returns The Balance Modal Component
 */
const BalanceModal = ({ val, onClick }: any) => {
  return (
    <Box sx={styles.checkBalContainer}>
      <Typography>{`Remaining Token Balance: ${val}`}</Typography>
      <ContainedButton title="Close" primary onClick={onClick} />
    </Box>
  );
};

/**
 * Defines Wallet Details Component Page
 * @returns The Wallet Details Component
 */
const WalletDetailsComp = () => {
  const {
    id,
    navigate,
    walletDetails,
    propertyListing,
    mySellPropertyListing,
    myBuyPropertyListing,
    buyTransactionListing,
    sellTransactionListing,
    tokenBalance,
    openCheckBalModal,
    handleCheckBalModalOpen,
    handleCheckBalModalClose,
    type,
    handleTypeChange,
    getWalletBalanceMapping,
  } = WalletDetailHelper();

  const walletBalanceListing = getWalletBalanceMapping();

  return (
    <Box sx={styles.accountSummary_mainContainer}>
      <Box sx={styles.btnContainer}>
        <ContainedButton
          title="Add Wallet Addresses"
          externalStyles={styles.walletBtn}
          onClick={() =>
            navigate(`${Paths.FORTRESS_WALLET_ADDRESSES}?id=${id}`)
          }
          primary
        />
        <ContainedButton
          title="Add Property"
          externalStyles={styles.walletBtn}
          onClick={() => navigate(`${Paths.ADD_PROPERTY}?id=${id}`)}
        />
      </Box>
      <Cards
        childComp={
          <Box sx={styles.accountSummary_cardContentContainer}>
            <Box width="100%">
              <Box sx={styles.accountDetailContainer}>
                <Box sx={styles.headingContainer}>
                  <Typography sx={styles.accountName}>
                    {walletDetails?.name}
                  </Typography>
                </Box>
              </Box>
              <Box sx={styles.accountSummary_detailsContainer}>
                <Box sx={styles.textContainer}>
                  <Typography sx={styles.textHeading}>ID</Typography>
                  <Typography>{walletDetails?.id}</Typography>
                </Box>
                <Box sx={styles.textContainer}>
                  <Typography sx={styles.textHeading}>Identity ID</Typography>
                  <Typography>{walletDetails?.identityId}</Typography>
                </Box>
              </Box>
              <Typography sx={styles.addressHeading}>Addresses</Typography>
              {walletDetails?.addresses &&
                walletDetails?.addresses.length > 0 &&
                walletDetails?.addresses.map((address: any, index: number) => {
                  return (
                    <Box
                      key={index}
                      sx={styles.accountSummary_detailsContainer}
                    >
                      <Box sx={styles.textContainer}>
                        <Typography sx={styles.textHeading}>Network</Typography>
                        <Typography>{address?.network}</Typography>
                      </Box>
                      <Box sx={styles.textContainer}>
                        <Typography sx={styles.textHeading}>
                          Asset Type
                        </Typography>
                        <Typography>{address?.assetType}</Typography>
                      </Box>

                      <Box sx={styles.textContainer}>
                        <Typography sx={styles.textHeading}>Address</Typography>
                        <Typography>{address?.address}</Typography>
                      </Box>
                    </Box>
                  );
                })}
            </Box>
          </Box>
        }
      />

      <WalletDetailsTab
        walletBalanceListing={walletBalanceListing}
        type={type}
        handleTypeChange={handleTypeChange}
        listingData={propertyListing}
        handleCheckBalModalOpen={handleCheckBalModalOpen}
        mySellPropertyListing={mySellPropertyListing}
        myBuyPropertyListing={myBuyPropertyListing}
        buyTransactionListing={buyTransactionListing}
        sellTransactionListing={sellTransactionListing}
      />

      <SimpleModal
        isOpen={openCheckBalModal}
        handleClose={handleCheckBalModalClose}
        children={
          <ConfirmationContainer>
            <BalanceModal
              val={tokenBalance}
              onClick={() => handleCheckBalModalClose()}
            />
          </ConfirmationContainer>
        }
      />
    </Box>
  );
};

export default WalletDetailsComp;
