import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, InputAdornment } from "@mui/material";
import { FC, memo } from "react";
import { PasswordIconProps } from "./type";
import styles from "./styles";

// PasswordIcon component definition
const PasswordIcon: FC<PasswordIconProps> = ({
  showPassword,
  setShowPassword,
  children = "",
  value = "",
}) => {
  return (
    <InputAdornment position="end">
      {children}
      {value ? (
        <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
          {showPassword ? (
            <VisibilityOff sx={styles.visibilityIcon} />
          ) : (
            <Visibility sx={styles.visibilityIcon} />
          )}
        </IconButton>
      ) : null}
    </InputAdornment>
  );
};

export default memo(PasswordIcon);
