import { Box, Typography } from "@mui/material";
import moment from "moment";

import styles from "./styles";
import ContainedButton from "../../../components/button/contained-button";
import Cards from "../../../components/card";
import usePropertyDetailsHelper from "./helper";
import SimpleModal from "../../../components/modal";
import PropertySellModal from "../../../components/modal/sellPropertyModal";
import PropertySellCertModal from "../../../components/modal/sellPropertyCertModal";

/**
 * Defines Property Details Page
 * @returns The Property Details Component
 */
const PropertyDetails = () => {
  const {
    propertyDetails: PD,
    openSellModal,
    openSellCertModal,
    handleOpenSellModal,
    handleOpenSellCertModal,
    handleCloseSellModal,
    handleCloseSellCertModal,
  } = usePropertyDetailsHelper();

  return (
    <Box sx={styles.accountSummary_mainContainer}>
      <Box sx={styles.btnContainer}>
        <ContainedButton
          title="Sell Property"
          externalStyles={styles.walletBtn}
          primary
          onClick={() => handleOpenSellModal()}
        />
      </Box>
      <Cards
        childComp={
          <Box sx={styles.accountSummary_cardContentContainer}>
            <Box width="100%">
              <Box sx={styles.accountDetailContainer}>
                <Box sx={styles.headingContainer}>
                  <Typography sx={styles.accountName}>{PD?.name}</Typography>
                </Box>
              </Box>
              <Box sx={styles.accountSummary_detailsContainer}>
                <Box sx={styles.textContainer}>
                  <Typography sx={styles.textHeading}>Address</Typography>
                  <Typography>{PD?.address}</Typography>
                </Box>
                <Box sx={styles.textContainer}>
                  <Typography sx={styles.textHeading}>Symbol</Typography>
                  <Typography>{PD?.symbol}</Typography>
                </Box>
                <Box sx={styles.textContainer}>
                  <Typography sx={styles.textHeading}>
                    Token Quantity
                  </Typography>
                  <Typography>{PD?.mintedToken}</Typography>
                </Box>

                <Box sx={styles.textContainer}>
                  <Typography sx={styles.textHeading}>Token Value</Typography>
                  <Typography>
                    {PD.sellOrderDetails?.tokenValue
                      ? PD.sellOrderDetails?.tokenValue
                      : "-"}
                  </Typography>
                </Box>

                <Box sx={styles.textContainer}>
                  <Typography sx={styles.textHeading}>
                    Left Number of Token To Sell
                  </Typography>
                  <Typography>{PD?.tokenLeftTosellByOwner}</Typography>
                </Box>

                <Box sx={styles.textContainer}>
                  <Typography sx={styles.textHeading}>Created At</Typography>
                  <Typography>
                    {moment(PD?.createdAt).format("YYYY-MM-DD")}
                  </Typography>
                </Box>
                <Box sx={styles.textContainer}>
                  <Typography sx={styles.textHeading}>
                    Wallet Address
                  </Typography>
                  <Typography>{PD?.walletAddress}</Typography>
                </Box>
                <Box sx={styles.textContainer}>
                  <Typography sx={styles.textHeading}>
                    Token Contract Address
                  </Typography>
                  <Typography>{PD?.tokenHolderAddress}</Typography>
                </Box>

                <Box sx={styles.textContainer}>
                  <Typography sx={styles.textHeading}>
                    Agreement Address
                  </Typography>
                  <Typography>
                    {PD?.sellOrderDetails?.agreementAddress}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        }
      />

      <SimpleModal
        isOpen={openSellModal}
        handleClose={handleCloseSellModal}
        children={
          <PropertySellModal
            details={PD}
            closeModal={handleCloseSellModal}
            openSellCertModal={openSellCertModal}
            handleOpenSellCertModal={handleOpenSellCertModal}
            handleCloseSellCertModal={handleCloseSellCertModal}
          />
        }
      />

      <SimpleModal
        isOpen={openSellCertModal}
        handleClose={handleCloseSellCertModal}
        children={
          <PropertySellCertModal
            details={PD}
            closeModal={handleCloseSellCertModal}
          />
        }
      />
    </Box>
  );
};

export default PropertyDetails;
