import { AxiosResponse } from "axios";
import { Dispatch } from "@reduxjs/toolkit";
import { setLoading } from "../../../redux/global.slice";
import { EndPoint, getApiCall, postApiCall } from "../../../common/constants";
import { showAlert } from "../../../common/utils";
import { apiSuccessCode } from "../../../common/constants/axios.instance";
import { setWalletDetails } from "../../fo.business.id/services/slice";
import {
  setAllIdentities,
  setBusinessIdentityListing,
  setTokenBalance,
  setWalletBalance,
  setWalletListing,
} from "./slice";
import { RootState } from "../../../redux/store";

// Action function to get crypto wallet details
export const getCryptoWallet = (id?: any) => (dispatch: Dispatch<any>) => {
  dispatch(setLoading(true));

  getApiCall(
    `${EndPoint.cryptoWallet}/${id}`,
    "",
    (response: AxiosResponse<any>) => {
      const { data } = response.data;
      if (response.data.statusCode === apiSuccessCode.success) {
        dispatch(setWalletDetails(data));
      }
      dispatch(setLoading(false));
    },
    (error: any) => {
      dispatch(setLoading(false));
      console.log(error.data.err);
      // showAlert(2, error.message);
    }
  );
};

// Action function to create a crypto wallet
export const createCryptoWallet =
  (values: any, callback?: () => void) => (dispatch: Dispatch<any>) => {
    dispatch(setLoading(true));

    postApiCall(
      EndPoint.cryptoWallet,
      values,
      (response: AxiosResponse<any>) => {
        if (response.data.statusCode === apiSuccessCode.success) {
          showAlert(1, "Wallet Created!");
          if (callback) callback();
        }
        dispatch(setLoading(false));
      },
      (error: any) => {
        dispatch(setLoading(false));
        showAlert(2, error.message);
      }
    );
  };

// Action function to get all identities
export const getAllIdentities =
  () => (dispatch: Dispatch<any>, getState: () => RootState) => {
    dispatch(setLoading(true));
    const { page, limit } = getState().dashboard.allIdentityQueryParameters;

    const params = `?page=${page}&limit=${limit}`;
    getApiCall(
      `${EndPoint.AllIdentities}${params}`,
      "",
      (response: AxiosResponse<any>) => {
        const { data } = response.data;
        dispatch(setAllIdentities(data.result));
        dispatch(setLoading(false));
      },
      (error: any) => {
        dispatch(setLoading(false));
        showAlert(2, error.message);
      }
    );
  };

// Action function to get business identities
export const getBusinessIdentities =
  () => (dispatch: Dispatch<any>, getState: () => RootState) => {
    dispatch(setLoading(true));
    const { page, limit } =
      getState().dashboard.businessIdentitiesQueryParameters;

    const params = `?page=${page}&limit=${limit}`;

    getApiCall(
      `${EndPoint.GetBusinessIdentities}${params}`,
      "",
      (response: AxiosResponse<any>) => {
        const { data } = response.data;
        dispatch(setBusinessIdentityListing(data.result));
        dispatch(setLoading(false));
      },
      (error: any) => {
        dispatch(setLoading(false));
        showAlert(2, error.message);
      }
    );
  };

// Action function to get wallet listing
export const getWalletListing =
  (identityId: any) => (dispatch: Dispatch<any>, getState: () => RootState) => {
    dispatch(setLoading(true));

    dispatch(setLoading(true));
    const { page, limit } = getState().dashboard.walletQueryParameters;
    const params = `?identityId=${identityId}&page=${page}&limit=${limit}`;
    getApiCall(
      `${EndPoint.walletListing}${params}`,
      "",
      (response: AxiosResponse<any>) => {
        const { data } = response.data;
        if (response.data.statusCode === apiSuccessCode.success) {
          dispatch(setWalletListing(data.result));
        }
        dispatch(setLoading(false));
      },
      (error: any) => {
        dispatch(setLoading(false));
        showAlert(2, error.message);
      }
    );
  };

// Action function to add wallet addresses
export const walletAddress =
  (walletId: any, values: any, callback?: any) => (dispatch: Dispatch<any>) => {
    dispatch(setLoading(true));

    postApiCall(
      `${EndPoint.walletAddresses}/${walletId}`,
      values,
      (response: AxiosResponse<any>) => {
        if (response.data.statusCode === apiSuccessCode.success) {
          showAlert(1, "Wallet Addresses Added Successfully");

          if (callback) callback();
        }
        dispatch(setLoading(false));
      },
      (error: any) => {
        dispatch(setLoading(false));
        showAlert(2, error.message);
      }
    );
  };

// Action function to get wallet balance
export const getWalletBalance =
  (walletId: any) => (dispatch: Dispatch<any>) => {
    dispatch(setLoading(true));

    getApiCall(
      `${EndPoint.GetWalletBalance}/${walletId}`,
      "",
      (response: AxiosResponse<any>) => {
        const { data } = response.data;
        if (response.data.statusCode === apiSuccessCode.success) {
          dispatch(setWalletBalance(data.data));
        }
        dispatch(setLoading(false));
      },
      (error: any) => {
        dispatch(setLoading(false));
        console.log(error.data.err);
        // showAlert(2, error.message);
      }
    );
  };

// Action function to check balance
export const checkBalance =
  (tokenAddress: string, userAddress: string, callback?: any) =>
  (dispatch: Dispatch<any>) => {
    dispatch(setLoading(true));

    const dataToSend = {
      tokenAddress,
      userAddress,
    };

    postApiCall(
      `${EndPoint.GetBalance}`,
      dataToSend,
      (response: AxiosResponse<any>) => {
        const { data } = response;
        if (data.statusCode === apiSuccessCode.success) {
          dispatch(setTokenBalance(data?.data?.tokenBalance));
          if (callback) callback();
        }
        dispatch(setLoading(false));
      },
      (error: any) => {
        dispatch(setLoading(false));
        showAlert(2, error.message);
      }
    );
  };
