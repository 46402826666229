import { COLORS } from "../../../common/constants/colors";

const styles = {
  mainContainer: {
    height: "100%",
    padding: "1rem 2rem",
    width: "80%",
    margin: "10px auto",
  },
  fullWidth: {
    width: "30%",
  },
  btnContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    margin: "40px 0 35px",
  },
  btn: {
    font: "normal normal 700 20px/25px SofiaPro",
    height: "50px",
    width: "150px",
    marginRight: "20px",
  },
  container: {
    padding: "20px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: COLORS.primary,
    margin: "20px 0",
    color: COLORS.white,
    borderRadius: "6px",
  },
  mainHeading: {
    font: "normal normal 700 25px/30px SofiaPro",
    marginBottom: "20px",
  },

  uploadAgainContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    "& svg": {
      fontSize: "1.7rem",
      marginRight: "1rem",
    },
  },
};

export default styles;
