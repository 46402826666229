// This file defines a Redux slice for managing the personal state.
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { PersonalDetails, PersonalModel } from "./type";

// Initial state for the personal slice
const initialState: PersonalModel = {
  personalDetails: {
    firstName: "",
    lastName: "",
    dateOfBirth: "",
    phone: "",
    ssn: "",
    isSuspended: false,
    upgradeRequirements: [],
    fullName: "",
    id: "",
    identityContainerId: "",
    kycLevel: "",
    type: "",
    status: "",
    address: {
      street1: "",
      street2: "",
      postalCode: "",
      city: "",
      state: "",
      country: "",
    },
    email: "",
    documents: [],
  },
};

// Create the personal detail slice using createSlice from Redux Toolkit
const personalSlice = createSlice({
  name: "personal",
  initialState,
  reducers: {
    setPersonalDetails: (
      state: PersonalModel,
      action: PayloadAction<PersonalDetails>
    ) => {
      state.personalDetails = action.payload;
    },
  },
});

// Export the action creators generated by createSlice
export const { setPersonalDetails } = personalSlice.actions;

// Export the reducer function generated by createSlice
export default personalSlice.reducer;
