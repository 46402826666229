import { Box, Typography } from "@mui/material";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import React from "react";
import {
  ConfirmationContainerProps,
  OkPopUpProps,
  YesNoProps,
  ConfirmProps,
  YesConfirmProps,
} from "./confirmation.types";
import ContainedButton from "../button/contained-button";
import OutlinedButton from "../button/outlined-button";
import styles from "./confirmation.styles";

/**
 * Renders a container for displaying confirmation pop-ups.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {React.ReactNode} props.children - The content to be displayed within the container.
 * @returns {React.ReactNode} The rendered component.
 */
const ConfirmationContainer = ({ children }: ConfirmationContainerProps) => {
  /**
   * Calculates the modal style based on the position.
   * @returns {Object} The modal style object.
   */
  function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }

  const [modalStyle] = React.useState(getModalStyle);

  return (
    <Box style={modalStyle} sx={styles.paper}>
      <Box sx={styles.mainContainer}>{children}</Box>
    </Box>
  );
};

/**
 * Renders a pop-up with an "OK" button for confirmation.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.message - The message to be displayed in the pop-up.
 * @param {string} props.logo - The path or URL of the logo to be displayed.
 * @param {string} props.subMessage - The optional sub-message to be displayed.
 * @param {Function} props.handleClose - The callback function to be called when the pop-up is closed.
 * @param {Object} props.btnExternalStyles - The external styles to be applied to the "OK" button.
 * @returns {React.ReactNode} The rendered component.
 */
export const OkPopUp = ({
  message,
  logo,
  subMessage,
  handleClose,
  btnExternalStyles,
}: OkPopUpProps) => {
  return (
    <>
      <Box component="img" src={logo} alt="Confirmation" sx={styles.logo} />
      <Typography sx={styles.message}>{message}</Typography>
      {subMessage && (
        <Typography sx={styles.subMessage}>{subMessage}</Typography>
      )}
      <ContainedButton
        title="OK"
        externalStyles={{
          ...styles.themeBtn,
          ...styles.okPopUpButton,
          ...btnExternalStyles,
        }}
        onClick={handleClose}
        primary
      />
    </>
  );
};

/**
 * Renders a pop-up with "Yes" and "No" buttons for confirmation.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.message - The message to be displayed in the pop-up.
 * @param {string} props.logo - The path or URL of the logo to be displayed.
 * @param {Function} props.handleNo - The callback function to be called when the "No" button is clicked.
 * @param {Function} props.handleYes - The callback function to be called when the "Yes" button is clicked.
 * @param {string} props.subMessage - The optional sub-message to be displayed.
 * @returns {React.ReactNode} The rendered component.
 */
export const YesNoPopUp = ({
  message,
  logo,
  handleNo,
  handleYes,
  subMessage,
}: YesNoProps) => {
  return (
    <>
      <Box component="img" src={logo} alt="Confirmation" sx={styles.logo} />
      <Typography sx={styles.message} textAlign="center">
        {message}
      </Typography>
      {subMessage && (
        <Typography sx={styles.subMessage}>{subMessage}</Typography>
      )}
      <Box sx={styles.btnContainer}>
        <OutlinedButton
          title="No"
          externalStyles={{ ...styles.themeBtn, ...styles.margin }}
          onClick={handleNo}
          primary
        />
        <ContainedButton
          title="Yes"
          externalStyles={styles.grayBtn}
          onClick={handleYes}
          primary
        />
      </Box>
    </>
  );
};

/**
 * Renders a pop-up with "Cancel" and "Confirm" buttons for confirmation.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.message - The message to be displayed in the pop-up.
 * @param {string} props.logo - The path or URL of the logo to be displayed.
 * @param {Function} props.handleNo - The callback function to be called when the "Cancel" button is clicked.
 * @param {Function} props.handleYes - The callback function to be called when the "Confirm" button is clicked.
 * @returns {React.ReactNode} The rendered component.
 */
export const ConfirmPopUp = ({
  message,
  logo,
  handleNo,
  handleYes,
}: ConfirmProps) => {
  return (
    <>
      <Box component="img" src={logo} alt="Confirmation" sx={styles.logo} />
      <Typography sx={styles.message}>{message}</Typography>
      <Box sx={styles.btnContainer}>
        <OutlinedButton
          title="Cancel"
          externalStyles={styles.grayBtn}
          onClick={handleNo}
          primary
        />
        <ContainedButton
          title="Confirm"
          externalStyles={{ ...styles.themeBtn, ...styles.margin }}
          onClick={handleYes}
          primary
        />
      </Box>
    </>
  );
};

/**
 * Renders a pop-up with "Cancel" and "Yes" buttons for confirmation.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.message - The message to be displayed in the pop-up.
 * @param {string} props.heading - The optional heading to be displayed.
 * @param {Function} props.handleClose - The callback function to be called when the "Cancel" button is clicked.
 * @param {Function} props.handleConfirm - The callback function to be called when the "Yes" button is clicked.
 * @returns {React.ReactNode} The rendered component.
 */
export const YesCancelPopUp = ({
  message,
  heading,
  handleClose,
  handleConfirm,
}: YesConfirmProps) => {
  return (
    <>
      <TaskAltIcon sx={styles.logo} />
      {heading && <Typography sx={styles.heading}>{heading}</Typography>}
      <Typography sx={styles.message}>{message}</Typography>
      <Box sx={styles.btnContainer}>
        <OutlinedButton
          title="Cancel"
          externalStyles={styles.yesCancelGrayBtn}
          onClick={handleClose}
          primary
        />
        <ContainedButton
          title="Yes"
          externalStyles={styles.yesCancelthemeBtn}
          onClick={handleConfirm}
          primary
        />
      </Box>
    </>
  );
};

export default ConfirmationContainer;
