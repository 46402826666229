import { COLORS } from "../../common/constants";

const styles = {
  loaderContainer: {
    zIndex: 9999,
    background: `${COLORS.black}4D`,
  },
  loaderImage: {
    width: "300px",
    margin: "0 auto",
  },
};

export default styles;
