/**
 * Paths definitions.
 */
export const Paths = {
  /** Auth Module */

  LOGIN: "/",
  FORGET_PASSWORD: "/forgot-password",
  RESET_PASSWORD: "/reset-password",
  VERIFICATION: "/verification",
  SIGNUP: "/sign-up",

  /** Home Module */

  HOME: "/home",

  /** Prime Trust */

  PRIME_TRUST_DASHBOARD: "/prime-trust/dashboard",
  PRIME_TRUST_ACCOUNT_SUMMARY: "/prime-trust/account-summary",
  PRIME_TRUST_COMPLETE_KYC: "/prime-trust/account-summary/complete-kyc",

  /** Fortress */

  FORTRESS_DASHBOARD: "/fortress/dashboard",
  FORTRESS_DETAILS: "/fortress/details",
  FORTRESS_ACCOUNTS: "/fortress/accounts",
  FORTRESS_BUSINESS_DETAILS: "/fortress/business/details",
  FORTRESS_BUSINESS_ACCOUNTS: "/fortress/business/accounts",
  FORTRESS_BUSINESS_WALLETS: "/fortress/business/wallets",
  FORTRESS_CUSTODIAL_ACCOUNT_DETAIL: "/fortress/custodial-account-detail",
  FORTRESS_WALLETS: "/fortress/wallets",
  FORTRESS_GET_BUSINESS_ID: "/fortress/get-business-id",
  FORTRESS_ACCOUNT_SUMMARY: "/fortress/account-summary",
  FORTRESS_COMPLETE_KYC: "/fortress/complete-kyc",
  FORTRESS_BUSINESS_ID_DETAIL: "/fortress/business-account-detail",
  FORTRESS_BUSINESS_COMPLETE_KYC: "/fortress/business/complete-kyc",
  FORTRESS_CREATE_WALLET: "/fortress/create-wallet",
  FORTRESS_WALLET_DETAIL: "/fortress/wallet-detail",
  FORTRESS_WALLET_ADDRESSES: "/fortress/add-wallet-addresses",

  FORTRESS_ADD_FUND_CARD: "/fortress/add-fund-card",
  FORTRESS_ADD_PLAID_CARD: "/fortress/add-plaid-card",
  FORTRESS_ADD_CARD: "/fortress/add-card",
  FORTRESS_CARD_DETAIL: "/fortress/card-detail",

  FORTRESS_TRANSACTION_DETAIL: "/fortress/transaction-detail",

  ADD_PROPERTY: "/fortress/add-property",
  PROPERTY_DETAIL: "/fortress/property-details",
  PROPERTY_ON_SELL_LISTING: "/fortress/buy-assets",
  PROPERTY_ON_SELL_DETAILS: "/fortress/buy-assets/details",
};
