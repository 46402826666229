import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Paths } from "../common/constants/paths";
import AuthWrapper from "../components/layout/onboarding-wrapper";
import PublicWrapper from "../components/layout/public-wrapper";
import ForgotPassword from "../pages/forgot.password";
import Home from "../pages/home";

import Login from "../pages/login";
import ResetPassword from "../pages/reset.password";
import SignUp from "../pages/signup";
import Verification from "../pages/verification";
import CompleteKYC from "../pages/pt.forms/complete.kyc";
import PrimeTrustForms from "../pages/pt.forms";

import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import AccountDetail from "../pages/pt.forms/accountSummary";
import FortressForms from "../pages/fo.forms";
import GetBusinessIdForm from "../pages/fo.business.id";
import AccountDetailFortress from "../pages/fo.forms/accountSummaryFortress";
import CompleteFortressKYC from "../pages/fo.forms/complete-kyc";
import CompleteBusinessKYCFortress from "../pages/fo.business.id/complete-kyc";
import CustodialAccountDetail from "../pages/fo.business.id/custodialAccountDetail";
import FortressDashboard from "../pages/fo.dashboard";
import BusinessDetails from "../pages/fo.business.id/business-details";
import { CreateWallet } from "../components/form/create-wallet";
import WalletAddresses from "../components/form/wallet-addresses";
import WalletDetails from "../pages/fo.business.id/wallet.details";
import AddFundCard from "../pages/fund.card";
import AddCardForm from "../components/form/add-card";
import FundCardDetail from "../pages/fund.card/card.detail.tsx";
import TransactionDetails from "../pages/fo.business.id/transaction-details";
import AddPlaidCard from "../pages/fund.card/fund.plaid";
import AddProperty from "../pages/fo.property/add-property";
import PropertyDetails from "../pages/fo.property/property-details";
import BuyPropertyDetails from "../pages/fo.property/buy-property-details";
import BuyPropertyListing from "../pages/fo.property/buy-property-listing";

/**
 * This component manages the routing configuration for the application.
 * It defines the routes and specifies which component to render for each route.
 */
const RoutesManager = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path={Paths.HOME}
          element={
            <PrivateRoute>
              <PublicWrapper childComp={<Home />} />
            </PrivateRoute>
          }
        />
        <Route
          path={Paths.PRIME_TRUST_DASHBOARD}
          element={
            <PrivateRoute>
              <PublicWrapper childComp={<PrimeTrustForms />} />
            </PrivateRoute>
          }
        />
        <Route
          path={Paths.PRIME_TRUST_COMPLETE_KYC}
          element={
            <PrivateRoute>
              <PublicWrapper childComp={<CompleteKYC />} />
            </PrivateRoute>
          }
        />
        <Route
          path={Paths.PRIME_TRUST_ACCOUNT_SUMMARY}
          element={<PublicWrapper childComp={<AccountDetail />} />}
        />

        <Route
          path={Paths.FORTRESS_ACCOUNT_SUMMARY}
          element={<PublicWrapper childComp={<AccountDetailFortress />} />}
        />

        <Route
          path={Paths.FORTRESS_COMPLETE_KYC}
          element={
            <PrivateRoute>
              <PublicWrapper childComp={<CompleteFortressKYC />} />
            </PrivateRoute>
          }
        />
        <Route
          path={Paths.LOGIN}
          element={
            <AuthWrapper
              childComp={
                <PublicRoute>
                  <Login />
                </PublicRoute>
              }
            />
          }
        />
        <Route
          path={Paths.VERIFICATION}
          element={
            <AuthWrapper
              childComp={
                <PublicRoute>
                  <Verification />
                </PublicRoute>
              }
            />
          }
        />
        <Route
          path={Paths.SIGNUP}
          element={
            <AuthWrapper
              childComp={
                <PublicRoute>
                  <SignUp />
                </PublicRoute>
              }
            />
          }
        />
        <Route
          path={Paths.FORGET_PASSWORD}
          element={
            <AuthWrapper
              childComp={
                <PublicRoute>
                  <ForgotPassword />
                </PublicRoute>
              }
            />
          }
        />
        <Route
          path={Paths.RESET_PASSWORD}
          element={
            <AuthWrapper
              childComp={
                <PublicRoute>
                  <ResetPassword />
                </PublicRoute>
              }
            />
          }
        />
        <Route
          path={Paths.FORTRESS_DETAILS}
          element={
            <PrivateRoute>
              <PublicWrapper childComp={<FortressForms tabIndex={0} />} />
            </PrivateRoute>
          }
        />
        <Route
          path={Paths.FORTRESS_ACCOUNTS}
          element={
            <PrivateRoute>
              <PublicWrapper childComp={<FortressForms tabIndex={1} />} />
            </PrivateRoute>
          }
        />
        <Route
          path={Paths.FORTRESS_WALLETS}
          element={
            <PrivateRoute>
              <PublicWrapper childComp={<FortressForms tabIndex={2} />} />
            </PrivateRoute>
          }
        />
        <Route
          path={Paths.FORTRESS_BUSINESS_DETAILS}
          element={
            <PrivateRoute>
              <PublicWrapper childComp={<BusinessDetails tabIndex={0} />} />
            </PrivateRoute>
          }
        />
        <Route
          path={Paths.FORTRESS_BUSINESS_ACCOUNTS}
          element={
            <PrivateRoute>
              <PublicWrapper childComp={<BusinessDetails tabIndex={1} />} />
            </PrivateRoute>
          }
        />
        <Route
          path={Paths.FORTRESS_BUSINESS_WALLETS}
          element={
            <PrivateRoute>
              <PublicWrapper childComp={<BusinessDetails tabIndex={2} />} />
            </PrivateRoute>
          }
        />

        <Route
          path={Paths.FORTRESS_GET_BUSINESS_ID}
          element={
            <PrivateRoute>
              <PublicWrapper childComp={<GetBusinessIdForm />} />
            </PrivateRoute>
          }
        />
        <Route
          path={Paths.FORTRESS_BUSINESS_ID_DETAIL}
          element={
            <PrivateRoute>
              <PublicWrapper childComp={<BusinessDetails />} />
            </PrivateRoute>
          }
        />
        <Route
          path={Paths.FORTRESS_CUSTODIAL_ACCOUNT_DETAIL}
          element={
            <PrivateRoute>
              <PublicWrapper childComp={<CustodialAccountDetail />} />
            </PrivateRoute>
          }
        />
        <Route
          path={Paths.FORTRESS_BUSINESS_COMPLETE_KYC}
          element={
            <PrivateRoute>
              <PublicWrapper childComp={<CompleteBusinessKYCFortress />} />
            </PrivateRoute>
          }
        />
        <Route
          path={Paths.FORTRESS_DASHBOARD}
          element={
            <PrivateRoute>
              <PublicWrapper childComp={<FortressDashboard />} />
            </PrivateRoute>
          }
        />
        <Route
          path={Paths.FORTRESS_CREATE_WALLET}
          element={
            <PrivateRoute>
              <PublicWrapper childComp={<CreateWallet />} />
            </PrivateRoute>
          }
        />
        <Route
          path={Paths.FORTRESS_WALLET_DETAIL}
          element={
            <PrivateRoute>
              <PublicWrapper childComp={<WalletDetails />} />
            </PrivateRoute>
          }
        />
        <Route
          path={Paths.FORTRESS_WALLET_ADDRESSES}
          element={
            <PrivateRoute>
              <PublicWrapper childComp={<WalletAddresses />} />
            </PrivateRoute>
          }
        />

        <Route
          path={Paths.FORTRESS_ADD_FUND_CARD}
          element={
            <PrivateRoute>
              <PublicWrapper childComp={<AddFundCard />} />
            </PrivateRoute>
          }
        />

        <Route
          path={Paths.FORTRESS_ADD_CARD}
          element={
            <PrivateRoute>
              <PublicWrapper childComp={<AddCardForm />} />
            </PrivateRoute>
          }
        />

        <Route
          path={Paths.FORTRESS_CARD_DETAIL}
          element={
            <PrivateRoute>
              <PublicWrapper childComp={<FundCardDetail />} />
            </PrivateRoute>
          }
        />

        <Route
          path={Paths.FORTRESS_TRANSACTION_DETAIL}
          element={
            <PrivateRoute>
              <PublicWrapper childComp={<TransactionDetails />} />
            </PrivateRoute>
          }
        />
        <Route
          path={Paths.FORTRESS_ADD_PLAID_CARD}
          element={
            <PrivateRoute>
              <PublicWrapper childComp={<AddPlaidCard />} />
            </PrivateRoute>
          }
        />
        <Route
          path={Paths.ADD_PROPERTY}
          element={
            <PrivateRoute>
              <PublicWrapper childComp={<AddProperty />} />
            </PrivateRoute>
          }
        />

        <Route
          path={Paths.PROPERTY_DETAIL}
          element={
            <PrivateRoute>
              <PublicWrapper childComp={<PropertyDetails />} />
            </PrivateRoute>
          }
        />
        <Route
          path={Paths.PROPERTY_ON_SELL_LISTING}
          element={
            <PrivateRoute>
              <PublicWrapper childComp={<BuyPropertyListing />} />
            </PrivateRoute>
          }
        />
        <Route
          path={Paths.PROPERTY_ON_SELL_DETAILS}
          element={
            <PrivateRoute>
              <PublicWrapper childComp={<BuyPropertyDetails />} />
            </PrivateRoute>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default RoutesManager;
