import axios, { AxiosResponse } from "axios";
import { Dispatch } from "@reduxjs/toolkit";

import { EndPoint, Env, Paths } from "../../../common/constants";
import { getApiCall, postApiCall } from "../../../common/constants/api";
import { apiSuccessCode } from "../../../common/constants/axios.instance";
import { showAlert } from "../../../common/utils/alert";
import { setLoading } from "../../../redux/global.slice";
import { RootState } from "../../../redux/store";
import { getProfile } from "../../login/services/action";

// Action for getting JWT Token
export const getJwtToken = () => (dispatch: Dispatch<any>) => {
  if (!navigator.onLine) {
    showAlert(2, "No internet connection!");
    return;
  }

  dispatch(setLoading(true));
  getApiCall(
    EndPoint.JwtTokenClient,
    "",
    (response: AxiosResponse<any>) => {
      const { statusCode, data } = response.data;
      if (statusCode === apiSuccessCode.success) {
        localStorage.setItem("auth_token_client", data?.authToken);
      }
      dispatch(setLoading(false));
    },
    (error: any) => {
      dispatch(setLoading(false));
      showAlert(2, error.message);
    }
  );
};

// Action for adding Custodial Account
export const addCustodialAccount =
  (values: any, setSubmitting: any, navigate: any) =>
  (dispatch: Dispatch<any>, getState: () => RootState) => {
    dispatch(setLoading(true));
    const { userDetails } = getState().login;

    const dataToSend = {
      ...values,
      name: userDetails.name,
      phoneNumber: userDetails.phoneNo,
      email: userDetails.email,
    };

    postApiCall(
      EndPoint.custodialAccount,
      dataToSend,
      (respData: AxiosResponse<any>) => {
        if (respData.data.statusCode === apiSuccessCode.success) {
          showAlert(1, respData.data.message);
          navigate(Paths.PRIME_TRUST_ACCOUNT_SUMMARY);
        }
        setSubmitting(false);
      },
      (error: any) => {
        dispatch(setLoading(false));
        setSubmitting(false);
        showAlert(2, error.message);
      }
    );
  };

// Action for uploading documents
export const uploadDocument =
  (values: any, type: any, callback?: () => void) =>
  (dispatch: Dispatch<any>) => {
    dispatch(setLoading(true));
    const token = localStorage.getItem("auth_token_client");
    axios
      .post(Env.PRIME_URL + EndPoint.ImageUpload, values, {
        headers: {
          Authorization: `Bearer ${token} `,
        },
      })
      .then((response: AxiosResponse<any>) => {
        const { data } = response.data;

        if (data) {
          const docType = data.attributes.description.split(" ");

          const values = {
            [`${type === "back" ? `${type}Side` : `${type}`}DocumentId`]:
              data.id,
            [`${type === "back" ? `${type}Side` : `${type}`}DocumentIdUrl`]:
              data["attributes"]["file-url"],
            kycDocumentType: docType[2],
          };

          if (callback) callback();

          dispatch(uploadFilesLocally(values));
          dispatch(setLoading(false));
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(setLoading(false));
      });
  };

// Action for uploading files locally
export const uploadFilesLocally =
  (values: any) => (dispatch: Dispatch<any>) => {
    dispatch(setLoading(true));
    postApiCall(
      EndPoint.ImageUploadLocally,
      values,
      (respData: AxiosResponse<any>) => {
        dispatch(setLoading(false));
        dispatch(getProfile());
        showAlert(1, "File Uploaded Successfully");
        localStorage.removeItem("auth_token_client");
      },
      (error: any) => {
        dispatch(setLoading(false));
        showAlert(2, error.message);
      }
    );
  };

// Action for saving KYC documents
export const saveKYCDocs = (navigate: any) => (dispatch: Dispatch<any>) => {
  dispatch(setLoading(true));

  postApiCall(
    EndPoint.SaveKYCDocs,
    {},
    (respData: AxiosResponse<any>) => {
      dispatch(setLoading(false));
      showAlert(1, "KYC Documents Saved");
      navigate(Paths.PRIME_TRUST_ACCOUNT_SUMMARY);
    },
    (error: any) => {
      dispatch(setLoading(false));
      showAlert(2, error.message);
    }
  );
};
