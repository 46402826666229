import React from "react";
import { Box } from "@mui/material";

import Header from "../../header";
import Spinner from "../../spinner";

interface Props {
  childComp: React.ReactNode;
}

/**
 * Wrapper component for public pages.
 *
 * @param {Props} props - Component properties.
 * @param {React.ReactNode} props.childComp - Child component to be rendered within the wrapper.
 * @returns {JSX.Element} PublicWrapper component.
 */
const PublicWrapper = ({ childComp }: Props) => {
  return (
    <Box>
      {/* Spinner component */}
      <Spinner />
      {/* Header component */}
      <Header />
      <Box>{childComp}</Box>
    </Box>
  );
};

export default PublicWrapper;
