import { useNavigate } from "react-router-dom";
import { uploadFile } from "../../../common/utils";

import { useAppDispatch } from "../../../hooks/useAppDispatch";

// Helper function for the Add Property component
const AddPropertyhelper = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const initialValues = {
    propertyName: "",
    tokenValue: "",
    symbol: "",
    address: "",
    fileURL: "",
    wallet_address: "",
  };

  const uploadImage = async (
    evt: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: any
  ) => {
    // Check if files were selected
    if (evt.target.files && evt.target.files.length > 0) {
      // Dispatch an action to upload the file
      dispatch(
        uploadFile(evt.target.files[0], (data: any) => {
          // When the upload is complete, update the field value with the uploaded file URL
          setFieldValue("fileURL", data.s3Url);
        })
      );
    }
  };

  return { navigate, dispatch, initialValues, uploadImage };
};

export default AddPropertyhelper;
