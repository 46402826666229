import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Paths } from "../../common/constants";
import { showAlert } from "../../common/utils";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import { RootState } from "../../redux/store";
import { getProfile } from "../login/services/action";
import { createCustodialAccount } from "./services/action";
import { getPersonalContainerFortress } from "./services/action";
import { getBusinessCustodialAccountListingFortress } from "../fo.business.id/services/action";

// Helper Component for Fortress Forms
const FortressFormsHelper = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const name = localStorage.getItem("name") || "";
  const { userDetails } = useAppSelector((state: RootState) => state.login);
  const { custodialAccountListing } = useAppSelector(
    (state: RootState) => state.business
  );

  const { personalDetails } = useAppSelector(
    (state: RootState) => state.personal
  );

  const { walletListing } = useAppSelector(
    (state: RootState) => state.dashboard
  );

  const { identityContainerId, personalIdentity: id } = userDetails;
  const [showCustodianOptions, setShowCustodianOptions] = useState(false);
  const [accountType, setAccountType] = useState("");
  const [isConfirmationOpen, setConfirmationOpen] = useState(false);

  // Handle open confirmation dialog
  const handleConfirmationOpen = () => {
    setConfirmationOpen(true);
  };

  // Handle close confirmation dialog
  const handleConfirmationClose = () => {
    setConfirmationOpen(false);
  };

  // Handle creation of account based on account type
  const handleAccount = () => {
    if (accountType === "personal") {
      const values = {
        accountType,
        personalIdentityId: userDetails.personalIdentity,
      };
      handleConfirmationClose();
      dispatch(
        createCustodialAccount(values, userDetails.personalIdentity, () => {
          navigate(Paths.FORTRESS_DETAILS);
        })
      );
    } else if (accountType === "business") {
      handleConfirmationClose();
      showAlert(2, "Please Create your Business ID first");
      navigate(Paths.FORTRESS_GET_BUSINESS_ID);
    }
  };

  // Fetch user profile data on component mount
  useEffect(() => {
    dispatch(getProfile());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Fetch personal container data when identity container ID changes
  useEffect(() => {
    if (identityContainerId) dispatch(getPersonalContainerFortress());
  }, [identityContainerId]); // eslint-disable-line react-hooks/exhaustive-deps

  // Fetch business custodial account listing when personal details or identity ID changes
  useEffect(() => {
    if (id) dispatch(getBusinessCustodialAccountListingFortress(id));
  }, [personalDetails, id]); // eslint-disable-line react-hooks/exhaustive-deps

  // Extract first name and last name from user details
  const firstName = userDetails.name.split(" ")[0];
  const lastName = userDetails.name.split(" ")[1];

  // Set initial form values using memoization
  const initialValues = useMemo(
    () => ({
      firstName: userDetails.name ? firstName : "",
      lastName: userDetails.name ? lastName : "",
      phone: userDetails.phoneNo,
      email: userDetails.email,
      dateOfBirth: "",
      ssn: "",
      street1: "",
      street2: "",
      postalCode: "",
      city: "",
      state: "",
      country: "",
    }),
    [userDetails, firstName, lastName]
  );

  return {
    navigate,
    dispatch,
    name,
    userDetails,
    initialValues,
    showCustodianOptions,
    setShowCustodianOptions,
    accountType,
    setAccountType,
    isConfirmationOpen,
    handleConfirmationClose,
    handleConfirmationOpen,
    handleAccount,
    custodialAccountListing,
    id,
    personalDetails,
    walletListing,
  };
};

export default FortressFormsHelper;
