import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { commonTableStyles as styles, businessDocuments } from "../styles";

/**
 * Component for rendering business documents table.
 *
 * @param {object} data - The data for the table.
 * @returns {JSX.Element} BusinessDocuments component.
 */
export default function BusinessDocuments({ data }: any) {
  return (
    <>
      <TableContainer>
        <Table sx={{ ...styles.table, ...businessDocuments.table }}>
          <TableHead sx={{ ...styles.tableHeader }}>
            <TableRow sx={styles.headerRow}>
              <TableCell>Identity ID</TableCell>
              <TableCell>Business Document Type</TableCell>
              <TableCell>Document Check Status</TableCell>
              <TableCell>Verified</TableCell>
            </TableRow>
          </TableHead>
          {data && data.length > 0 && (
            <TableBody>
              {data &&
                data.length > 0 &&
                data.map((innerData: any) => {
                  return (
                    <TableRow key={innerData.id} sx={{ ...styles.row }}>
                      <TableCell>{innerData.identityId}</TableCell>
                      <TableCell>{innerData.businessDocumentType}</TableCell>
                      <TableCell>{innerData.documentCheckStatus}</TableCell>
                      <TableCell>
                        <Box
                          sx={{
                            ...styles.statusBox,
                            ...(innerData.isVerified
                              ? { ...styles.successBox }
                              : { ...styles.errorBox }),
                          }}
                        >
                          {innerData.isVerified ? "Verified" : "Not Verified"}
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </>
  );
}
