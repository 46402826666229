import { COLORS } from "../../common/constants/colors";

const styles = {
  mainContainer: {
    height: "100%",
    backgroundColor: "#FFFFFF",
    padding: "1rem 2rem",
    textAlign: "center",
    width: "80%",
    margin: "10px auto",
  },
  mainHeading: {
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "32px",
    lineHeight: "32px",
    color: "#000000",
  },

  //Account Summary Styling
  accountSummary_mainContainer: {
    width: "80%",
    margin: "0 auto",
  },
  accountSummary_upperCardContainer: {
    border: `1px solid ${COLORS.borderGrey}`,
    borderRadius: "0.556vw",
    boxShadow: "0 0.278vw 0.417vw rgba(0, 0, 0, 0.02)",
    padding: "1.389vw 1.042vw 0 1.042vw",
    margin: "3vw 0 2.197vw 0",
  },
  accountSummary_cardContentContainer: {
    width: "100%",
    display: "flex",
  },
  accountDetailContainer: {
    display: "flex",
    alignItens: "center",
  },
  accountSummary_detailsContainer: {
    padding: "1rem 0",
    width: "70%",
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  accountName: {
    fontSize: "24px",
    fontWeight: 600,
  },
  accountStatus: {
    textTransform: "capitalize",
    borderRadius: "0.278vw",
    display: "flex",
    alignItems: "center",
    padding: "5px",
    marginLeft: "1rem",
  },
  textContainer: {
    flexBasis: "30%",
    marginBottom: "1rem",
  },
  textHeading: {
    fontSize: "20px",
    fontWeight: 550,
  },
  pending: {
    backgroundColor: COLORS.orangeBg,
    color: COLORS.orange,
    border: `1px solid ${COLORS.orangeBg}`,
  },
  active: {
    backgroundColor: COLORS.greenBg,
    color: COLORS.green,
    border: `1px solid ${COLORS.greenBg}`,
  },
  active_arrowContainer: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  btnContainer: {
    width: "20%",
    textAlign: "right",
  },

  iconContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },

  activeIcons: {
    backgroundColor: COLORS.primaryBlue,
    padding: "1rem",
    borderRadius: "50%",
    display: "flex",
    // justifyContent: "center",
    alignItems: "center",
    marginBottom: "6px",
    cursor: "pointer",

    "& svg": {
      fill: COLORS.white,
      fontSize: "1.7rem",
    },
  },
  btn: {
    font: "normal normal 700 18px/23px SofiaPro",
    width: "auto",
    marginRight: "20px",
    borderRadius: "4px",
    padding: "20px",
  },
};

export default styles;
