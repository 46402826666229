import { useEffect } from "react";
import { Box } from "@mui/material";

import styles from "./styles";
import BusinessDetailHelper from "./helper";
import ContainedButton from "../../../components/button/contained-button";
import { Paths } from "../../../common/constants";
import { getProfile } from "../../login/services/action";
import WalletListing from "../../../components/tables/walletListing";

/**
 * Defines Wallet Detail Tab Component
 * @returns The Wallet Detail Tab Component
 */
const WalletDetailTab = () => {
  const { navigate, id, dispatch, walletListing } = BusinessDetailHelper();

  useEffect(() => {
    dispatch(getProfile());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box width="100%">
      <Box sx={styles.walletHeadingContainer}>
        {walletListing && walletListing.length < 1 && (
          <ContainedButton
            title="Create Wallet"
            externalStyles={styles.walletBtn}
            fullWidth
            size="large"
            type="submit"
            onClick={() =>
              navigate(`${Paths.FORTRESS_CREATE_WALLET}?type=business&id=${id}`)
            }
          />
        )}
      </Box>
      <WalletListing id={id} type="business" />
    </Box>
  );
};

export default WalletDetailTab;
