import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { RootState } from "../../../redux/store";
import { getCryptoWallet } from "../../fo.dashboard/services/action";

// Helper Component for Add Property Component
const useAddPropertyHelper = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { walletDetails } = useAppSelector(
    (state: RootState) => state.business
  );

  const location = useLocation();
  const id = location.search.split("=")[1];

  useEffect(() => {
    dispatch(getCryptoWallet(id));
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  return { navigate, dispatch, walletDetails };
};

export default useAddPropertyHelper;
