import { Box } from "@mui/material";
import { logout } from "../../common/utils";
import OutlinedButton from "../button/outlined-button";
import styles from "./styles";

/**
 * Header component displaying a logout button.
 */
const Header = () => {
  return (
    // Main container for the header
    <Box sx={styles.header_mainContainer}>
      {/* Logout button */}
      <OutlinedButton
        title="Logout"
        onClick={logout}
        externalStyles={styles.btn}
      />
    </Box>
  );
};

export default Header;
