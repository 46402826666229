import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { getFundCardDetails } from "../services/action";
import { RootState } from "../../../redux/store";

// Helper Component for Card Detail Component
const CardDetailHelper = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const id = location.search.split("=")[1];

  const { fundCardDetails } = useAppSelector((state: RootState) => state.fund);

  useEffect(() => {
    dispatch(getFundCardDetails(id));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    navigate,
    dispatch,
    id,
    fundCardDetails,
  };
};

export default CardDetailHelper;
