// This file defines a Redux slice for managing the home state.

import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { HomeModel, UserDetails, JWTClient } from "./type";

// Initial state for the home slice
const initialState: HomeModel = {
  userDetails: {
    _id: "",
    phoneNo: "",
    email: "",
    name: "",
    countryCode: "",
    otp: "",
    otpTimeStamp: "",
    createdAt: "",
    updatedAt: "",
    accountVerify: true,
    accountId: "",
    accountName: "",
    accountNumber: "",
    contactId: "",
    kycStatus: "",
    ownerId: "",
    frontDocumentId: "",
    backSideDocumentId: "",
  },
  jwtToken: {
    authtoken: "",
  },
};

// Create the home slice using createSlice from Redux Toolkit
const homeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {
    setUserDetails: (state: HomeModel, action: PayloadAction<UserDetails>) => {
      state.userDetails = action.payload;
    },
    setJWTClient: (state: HomeModel, action: PayloadAction<JWTClient>) => {
      state.jwtToken = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setUserDetails, setJWTClient } = homeSlice.actions;

export default homeSlice.reducer;
