import React from "react";
import { Box } from "@mui/material";
import { IMAGES } from "../../../common/constants/images";
import styles from "./styles";

interface Props {
  childComp: React.ReactNode;
}

/**
 * Wrapper component for authentication pages.
 *
 * @param {Props} props - Component properties.
 * @param {React.ReactNode} props.childComp - Child component to be rendered within the wrapper.
 * @returns {JSX.Element} AuthWrapper component.
 */
const AuthWrapper = ({ childComp }: Props) => {
  return (
    // Main container for the wrapper
    <Box sx={styles.mainContainer}>
      {/* Logo */}
      <Box
        component="img"
        sx={styles.logo}
        src={IMAGES.LandingPage}
        alt="watani_logo"
      />
      {/* Container for the child component */}
      <Box sx={styles.childContainer}>{childComp}</Box>
    </Box>
  );
};

export default AuthWrapper;
