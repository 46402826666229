import { Box } from "@mui/material";
import { Form, Formik } from "formik";
import styles from "./styles";
import Schema from "../../common/constants/schema";
import ForgotPasswordHelper from "./helper";
import Input from "../../components/formControls/input";
import ContainedButton from "../../components/button/contained-button";
import { forgotPassword } from "./services/action";
import { Paths } from "../../common/constants";

/**
 * Defines Forget Password Page
 * @returns The rendered Component
 */
const ForgotPassword = () => {
  const { initialValue, dispatch, navigate } = ForgotPasswordHelper();

  return (
    <Box sx={styles.mainContainer}>
      <Box sx={styles.heading}>Forgot Password</Box>
      <Formik
        initialValues={initialValue}
        validationSchema={Schema.ForgetPasswordSchema}
        onSubmit={(values, { setSubmitting }) => {
          dispatch(
            forgotPassword(values, setSubmitting, () => {
              navigate(Paths.RESET_PASSWORD);
            })
          );
        }}
      >
        {({ isValid }) => {
          return (
            <Form>
              <Box sx={styles.fieldContainer}>
                <Input
                  name="email"
                  type="email"
                  label="Email Address"
                  placeholder="Email Address"
                  fullWidth
                  externalStyles={styles.textField}
                  isRequired
                />
              </Box>
              <Box sx={styles.btnContainer}>
                <ContainedButton
                  title="Send Link"
                  disabled={!isValid}
                  externalStyles={styles.btn}
                  fullWidth
                  size="large"
                  type="submit"
                />
              </Box>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default ForgotPassword;
