import { COLORS } from "../../common/constants/colors";
import { FontFamily } from "../../common/utils/common.fns";

const styles = {
  mainContainer: {},
  heading: {
    font: FontFamily(700, 25, 30),
    padding: "0 0 10px 0",
  },
  fieldContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  btnContainer: {
    margin: "35px 0",
    "& .MuiButton-root.Mui-disabled": {
      color: COLORS.white,
    },
  },
  btn: {
    backgroundColor: COLORS.primary,
    color: COLORS.white,
    padding: "12px",
    font: "normal normal 700 20px/25px SofiaPro",
    "&:hover": {
      backgroundColor: COLORS.hoverOver,
      color: COLORS.white,
    },
  },
  email: {
    color: COLORS.darkGrey,
    font: "normal normal 500 14px/18px SofiaPro",
    paddingTop: "10px",
  },
  eyeIcon: { color: COLORS.primary },
  none: { visibility: "hidden" },
  innerContainer: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: `translate(-50%, -50%)`,
  },
  textField: { margin: "10px 0" },
  nameField: { margin: "10px 0", width: "48%" },
  nameContainer: { display: "flex", justifyContent: "space-between" },
  countryPicker: { margin: "10px 0" },
  label: {
    font: "normal normal 500 14px/14px SofiaPro",
    color: COLORS.inputHead,
    marginTop: "10px",
  },
  footerContainer: {
    display: "flex",
    justifyContent: "center",
  },
  footerLink: {
    cursor: "pointer",
    font: FontFamily(700, 14, 18),
    color: COLORS.primary,
    textDecoration: "underline",
  },
  error: {
    color: "#ff1744",
    margin: "2px",
    fontSize: "0.75rem",
    letterSpacing: "0",
  },
};

export default styles;
