import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { getJwtToken, uploadDocument } from "../services/action";
import { getProfile } from "../../login/services/action";
import { RootState } from "../../../redux/store";

// Helper Component for Complete KYC Component
const CompleteKYCHelper = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { userDetails } = useAppSelector((state: RootState) => state.login);
  const { contactId } = userDetails;

  const initialValues = {
    doc_type: "",
    front: "",
    back: "",
  };

  useEffect(() => {
    dispatch(getJwtToken());
    dispatch(getProfile());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const uploadImage = async (
    evt: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: (name: string, val: string) => void,
    type: string,
    values: typeof initialValues
  ) => {
    if (evt.target.files && evt.target.files.length > 0) {
      const file = evt.target.files[0];
      const formdata = new FormData();
      const ext = evt.target.files[0].type.split("/");

      formdata.append("contact-id", contactId);
      formdata.append("description", `${type} of ${values.doc_type}`);
      formdata.append("label", `${type} of ${values.doc_type}`);
      formdata.append("file", file, "[PROXY]");
      formdata.append("public", "true");
      formdata.append("extension", `.${ext[1]}`);

      dispatch(
        uploadDocument(formdata, type, () => {
          setFieldValue(type, "");
        })
      );
    }
  };

  return { navigate, dispatch, initialValues, uploadImage, userDetails };
};

export default CompleteKYCHelper;
