import { Fragment } from "react";
import { Box, Typography } from "@mui/material";

import styles from "./styles";
import ContainedButton from "../../components/button/contained-button";
import FortressFormsHelper from "./helper";
import SimpleModal from "../../components/modal";
import ConfirmationContainer, {
  YesCancelPopUp,
} from "../../components/confimation";
import { Paths } from "../../common/constants";
import AccountDetailFortress from "./accountSummaryFortress";
import CustodialAccountListing from "../../components/tables/custodialAccountListing";
import GeneralTab from "../../components/tabs";
import ENUM from "../../common/constants/constant.enum";
import PersonalDetails from "./personal-details";
import WalletListing from "../../components/tables/walletListing";

/**
 * Defines Fortress Forms Page
 * @returns The Fortress Forms Component
 */
const FortressForms = ({ tabIndex }: any) => {
  const {
    setAccountType,
    handleConfirmationOpen,
    accountType,
    isConfirmationOpen,
    handleConfirmationClose,
    handleAccount,
    userDetails,
    custodialAccountListing,
    navigate,
    walletListing,
  } = FortressFormsHelper();

  const tabList = [
    {
      label: "Details",
      href: Paths.FORTRESS_DETAILS,
    },
    {
      label: "Accounts",
      href: Paths.FORTRESS_ACCOUNTS,
    },
    {
      label: "Wallets",
      href: Paths.FORTRESS_WALLETS,
    },
  ];

  return (
    <Box sx={styles.mainContainer}>
      <Box>
        {userDetails?.identityContainerId && <AccountDetailFortress />}
        <Box sx={styles.fortressContentContainer}>
          <GeneralTab tabItems={tabList} type={tabIndex} />
          {tabIndex === ENUM.TAB.accounts && (
            <Fragment>
              {custodialAccountListing &&
                custodialAccountListing.length < 1 && (
                  <ContainedButton
                    title="Create Custodial Account Using Personal ID"
                    externalStyles={styles.btn}
                    onClick={() => {
                      setAccountType("personal");
                      handleConfirmationOpen();
                    }}
                  />
                )}
              {custodialAccountListing && custodialAccountListing.length > 0 ? (
                <>
                  <Typography sx={styles.heading}>
                    Your Custodial Accounts
                  </Typography>
                  <CustodialAccountListing
                    listingData={custodialAccountListing}
                    type="personal"
                  />
                </>
              ) : (
                <Typography sx={styles.heading}>
                  No Accounts Created Yet.
                </Typography>
              )}
            </Fragment>
          )}
          {tabIndex === ENUM.TAB.details && <PersonalDetails />}
          {tabIndex === ENUM.TAB.wallets && (
            <>
              {walletListing && walletListing.length < 1 && (
                <ContainedButton
                  title="Create Wallet"
                  externalStyles={styles.walletBtn}
                  onClick={() =>
                    navigate(
                      `${Paths.FORTRESS_CREATE_WALLET}?type=personal&id=${userDetails.personalIdentity}`
                    )
                  }
                />
              )}
              <WalletListing id={userDetails.personalIdentity} type="personal"/>
            </>
          )}
        </Box>
        <SimpleModal
          handleClose={handleConfirmationClose}
          isOpen={isConfirmationOpen}
        >
          <ConfirmationContainer>
            <YesCancelPopUp
              message={`Are you sure you want to create account using ${
                accountType === "personal" ? "Personal" : "Business"
              } ID?`}
              handleClose={handleConfirmationClose}
              handleConfirm={handleAccount}
            />
          </ConfirmationContainer>
        </SimpleModal>
      </Box>
    </Box>
  );
};

export default FortressForms;
