import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { useEffect } from "react";
import { getTransactionDetails } from "../services/action";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { RootState } from "../../../redux/store";

// Helper Component for Transaction Detail Component
const TransactionDetailHelper = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const id = location.search.split("=")[1];

  useEffect(() => {
    dispatch(getTransactionDetails(id));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const { transactionDetails } = useAppSelector(
    (state: RootState) => state.business
  );

  return {
    navigate,
    dispatch,
    id,
    transactionDetails,
  };
};

export default TransactionDetailHelper;
