import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import { RootState } from "../../redux/store";
import { getProfile } from "../login/services/action";

// Helper Component for Prime Trust Forms Component
const PrimeTrustFormsHelper = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const name = localStorage.getItem("name") || "";
  const { userDetails } = useAppSelector((state: RootState) => state.login);

  useEffect(() => {
    dispatch(getProfile());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return { navigate, dispatch, name, userDetails };
};

export default PrimeTrustFormsHelper;
