import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { commonTableStyles as styles, transactionListing } from "../styles";

/**
 * Component for rendering sell transaction table.
 *
 * @param {object} data - The data for the table.
 * @returns {JSX.Element} The rendered component.
 */
const SellTransactionListing = ({ data }: any) => {
  const isPending = (status: string) => status === "pending";

  return (
    <TableContainer>
      <Table sx={styles.table} stickyHeader aria-label="customized table">
        <TableHead sx={styles.tableHeader}>
          <TableRow sx={styles.headerRow}>
            <TableCell>Payment Id</TableCell>
            <TableCell>Property Id</TableCell>
            <TableCell>Buyer Identity Id</TableCell>
            <TableCell>Seller Identity Id</TableCell>
            <TableCell>Token Value</TableCell>
            <TableCell>Number of Tokens Bought</TableCell>
            <TableCell>Crypto Amount</TableCell>
            <TableCell>Asset Type</TableCell>
            <TableCell>Henry Transaction Id</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {data && data.length > 0 ? (
            data.map((data: any, index: number) => (
              <TableRow key={index} sx={styles.row}>
                <TableCell>
                  <Typography>
                    {data?.paymentId ? data.paymentId : "-"}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography>
                    {data?.propertyId ? data?.propertyId : "-"}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography>{data?.buyerIdentityId}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{data?.sellerIdentityId}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{data?.tokenValue}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{data?.noOfTokenToBuy}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{data?.cryptoAmount}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>
                    {data?.assetType ? data?.assetType : "-"}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography>
                    {data?.henryTransactionId ? data?.henryTransactionId : "-"}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    sx={
                      isPending(data?.txStatus)
                        ? transactionListing.pending
                        : transactionListing.completed
                    }
                  >
                    {data?.txStatus}
                  </Typography>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={14} align="center">
                <h3>No Records</h3>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SellTransactionListing;
