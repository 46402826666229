import { COLORS } from "../../../common/constants/colors";
const styles = {
  custodialForm_mainContainer: {},
  formContainer: {
    display: "grid",
    gridTemplateColumns: "48% 48%",
    columnGap: "50px",
  },
  textField: {
    margin: "10px 0",

    "& .MuiTypography-h6": {
      textAlign: "left",
      marginBottom: "5px",
    },
  },

  btn: {
    width: "20%",
    margin: "0 1rem",
    backgroundColor: COLORS.primary,
    color: COLORS.white,
    padding: "12px",
    font: "normal normal 700 20px/25px SofiaPro",
    "&:hover": {
      backgroundColor: COLORS.hoverOver,
      color: COLORS.white,
    },
  },

  btnContainer: {
    width: "100%%",
    margin: "2rem auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

export default styles;
