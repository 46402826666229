import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { getPropertyDetails } from "../services/action";
import { RootState } from "../../../redux/store";
import { getProfile } from "../../login/services/action";

// Helper Component for Property Details Component
const usePropertyDetailsHelper = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const id = location.search.split("=")[1];

  useEffect(() => {
    dispatch(getProfile());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch(getPropertyDetails(id));
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  const { propertyDetails } = useAppSelector(
    (state: RootState) => state.property
  );

  const [openSellModal, setOpenSellModal] = useState(false);
  const [openSellCertModal, setOpenSellCertModal] = useState(false);

  const handleOpenSellModal = () => {
    setOpenSellModal(true);
  };

  const handleCloseSellModal = () => {
    setOpenSellModal(false);
  };

  const handleOpenSellCertModal = () => {
    setOpenSellCertModal(true);
  };

  const handleCloseSellCertModal = () => {
    setOpenSellCertModal(false);
  };

  return {
    navigate,
    dispatch,
    id,
    propertyDetails,
    openSellModal,
    openSellCertModal,
    handleOpenSellModal,
    handleCloseSellModal,
    handleCloseSellCertModal,
    handleOpenSellCertModal,
  };
};

export default usePropertyDetailsHelper;
