import { Box, Typography } from "@mui/material";

import { styles } from "./styles";
import ContainedButton from "../../../components/button/contained-button";
import AddFundPlaidhelper from "./helper";
import FundListing from "../../../components/tables/fundListing";
import SimpleModal from "../../../components/modal";
import { useEffect, useState } from "react";
import CardPaymentModal from "../../../components/modal/cardPaymentModal";
import { usePlaidLink } from "react-plaid-link";
import { PublicTokenExchange } from "../services/action";
import { setLinkToken } from "../services/slice";

/**
 * Defines Add Plaid Card Page
 * @returns The Add Plaid Card Component
 */
const AddPlaidCard = () => {
  const {
    id,
    fundListing,
    openPaymentModal,
    handleOpenModal,
    handleCloseModal,
    handleCreatePlaid,
    handleOpenAddModal,
    linkToken,
    dispatch,
  } = AddFundPlaidhelper();

  const [cardId, setCardId] = useState("");

  const config: Parameters<typeof usePlaidLink>[0] = {
    token: linkToken,
    onSuccess: async (publicToken, metadata) => {
      dispatch(setLinkToken(""));
      dispatch(PublicTokenExchange(publicToken, metadata, handleCreatePlaid));
    },
  };
  const { open: openPlaidLink, ready } = usePlaidLink(config);

  useEffect(() => {
    if (ready && linkToken) {
      openPlaidLink();
    }
  }, [linkToken, ready, openPlaidLink]);

  return (
    <Box sx={styles.mainContainer}>
      <Box sx={styles.btnContainer}>
        <ContainedButton
          title="Add Plaid"
          externalStyles={styles.btn}
          primary
          onClick={handleOpenAddModal}
        />
      </Box>
      <Box sx={styles.listingContainer}>
        <Typography>All Plaid</Typography>
        <FundListing
          listingData={fundListing}
          handleOpenModal={handleOpenModal}
          setCardId={setCardId}
        />
      </Box>
      {/* <SimpleModal handleClose={handleCloseAddModal} isOpen={openAddPlaidModal}>
        <ConfirmationContainer>
          <YesCancelPopUp
            message={`Are you sure you want to create plaid?`}
            handleClose={handleCloseAddModal}
            handleConfirm={handleCreatePlaid}
          />
        </ConfirmationContainer>
      </SimpleModal> */}

      <SimpleModal
        isOpen={openPaymentModal}
        handleClose={handleCloseModal}
        children={
          <CardPaymentModal
            CId={id}
            externalId={cardId}
            closeModal={handleCloseModal}
          />
        }
      />
    </Box>
  );
};

export default AddPlaidCard;
