import useAddPropertyHelper from "./helper";
import AddPropertyForm from "../../../components/form/add-property";

/**
 * Defines Add Property Page
 * @returns The Add Property Component
 */
const AddProperty = () => {
  const { walletDetails } = useAddPropertyHelper();

  return (
    <AddPropertyForm
      address={walletDetails?.addresses}
      id={walletDetails.identityId}
    />
  );
};

export default AddProperty;
