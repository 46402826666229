import { COLORS } from "../../common/constants";

export const styles = {
  cardMainContainer: {
    width: "100%",
    margin: "2rem auto",
    backgroundColor: COLORS.white,
    border: `1px solid #F4F6FA`,
    borderRadius: "0.556vw",
    boxShadow: "0 0.278vw 0.417vw rgba(0, 0, 0, 0.08)",
    padding: "1.042vw",
    boxSizing: "border-box",
  },
};
