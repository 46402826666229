import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab, Typography } from "@mui/material";

import styles from "./styles";
import PropertyListing from "../../../components/tables/propertyListing";
import Cards from "../../../components/card";
import CryptoAssetsListing from "../../../components/tables/cryptoAssetsListing";
import MySellPropertyListing from "../../../components/tables/mySellPropertyListing";
import MyBuyPropertyListing from "../../../components/tables/myBuyPropertyListing";
import BuyTransactionListing from "../../../components/tables/buytransactionListing";
import SellTransactionListing from "../../../components/tables/sellTransactionListing";

/**
 * Defines Wallet Details Tab Page
 * @returns The Wallet Details Tab Component
 */
const WalletDetailsTab = ({
  walletBalanceListing,
  type,
  handleTypeChange,
  listingData,
  handleCheckBalModalOpen,
  mySellPropertyListing,
  myBuyPropertyListing,
  buyTransactionListing,
  sellTransactionListing,
}: any) => {
  return (
    <Box>
      <TabContext value={type}>
        <Box>
          <TabList sx={styles.tabs} onChange={handleTypeChange}>
            <Tab label="Crypto Wallet" value="1" sx={styles.tab} />
            <Tab label="Security Token" value="2" sx={styles.tab} />
          </TabList>
        </Box>

        <TabPanel value="1" sx={{ padding: "0" }}>
          <Box sx={styles.tabContentContainer}>
            <Cards
              childComp={
                <>
                  <Box sx={styles.cryptoHeadingContainer}>
                    <Typography sx={styles.heading}>Crypto Assets</Typography>
                  </Box>
                  <CryptoAssetsListing listingData={walletBalanceListing} />
                </>
              }
            />

            <Cards
              childComp={
                <Box sx={styles.propertyListingContainer}>
                  <Typography sx={styles.heading}>Buy Transaction</Typography>
                  <BuyTransactionListing data={buyTransactionListing} />
                </Box>
              }
            />
            <Cards
              childComp={
                <Box sx={styles.propertyListingContainer}>
                  <Typography sx={styles.heading}>Sell Transaction</Typography>
                  <SellTransactionListing data={sellTransactionListing} />
                </Box>
              }
            />
          </Box>
        </TabPanel>
        <TabPanel value="2" sx={{ padding: "0" }}>
          <Box sx={styles.tabContentContainer}>
            <Cards
              childComp={
                <Box sx={styles.propertyListingContainer}>
                  <Typography sx={styles.heading}>Security Token</Typography>
                  <PropertyListing
                    listingData={listingData}
                    handleOpen={handleCheckBalModalOpen}
                  />
                </Box>
              }
            />

            <Cards
              childComp={
                <Box sx={styles.propertyListingContainer}>
                  <Typography sx={styles.heading}>
                    Properties Available For Sell
                  </Typography>
                  <MySellPropertyListing listingData={mySellPropertyListing} />
                </Box>
              }
            />

            <Cards
              childComp={
                <Box sx={styles.propertyListingContainer}>
                  <Typography sx={styles.heading}>Properties Bought</Typography>
                  <MyBuyPropertyListing listingData={myBuyPropertyListing} />
                </Box>
              }
            />
          </Box>
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default WalletDetailsTab;
