import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Paths } from "../../../common/constants";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { RootState } from "../../../redux/store";
import ContainedButton from "../../button/contained-button";

import { commonTableStyles as styles } from "../styles";

/**
 * Component for rendering crypto assets table.
 *
 * @param {object} data - The data for the table.
 * @returns {JSX.Element} The rendered component.
 */
const CryptoAssetsListing = ({ listingData }: any) => {
  const navigate = useNavigate();
  const { walletDetails } = useAppSelector(
    (state: RootState) => state.business
  );

  return (
    <TableContainer>
      <Table sx={styles.table} stickyHeader aria-label="customized table">
        <TableHead sx={styles.tableHeader}>
          <TableRow sx={styles.headerRow}>
            <TableCell>Name</TableCell>
            <TableCell>Address</TableCell>
            <TableCell>Balance</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {listingData && listingData.length > 0 ? (
            listingData.map((data: any, index: number) => (
              <TableRow key={index} sx={styles.row}>
                <TableCell>
                  <Typography>
                    {data.network}
                    <span> {data.assetType}</span>
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography>{data?.address}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{data.totalBalance}</Typography>
                </TableCell>
                <TableCell>
                  <ContainedButton
                    title="Buy Assets"
                    externalStyles={styles.yesCancelthemeBtn}
                    onClick={() => {
                      navigate(
                        `${Paths.PROPERTY_ON_SELL_LISTING}?address=${data.address}&id=${walletDetails?.identityId}`
                      );
                    }}
                  />
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={14} align="center">
                <h3>No Records</h3>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CryptoAssetsListing;
