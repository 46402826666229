import { Box, Typography } from "@mui/material";

import styles from "./styles";
import { useAppSelector } from "../../hooks/useAppSelector";
import { RootState } from "../../redux/store";
import Cards from "../../components/card";
import { useLocation, useNavigate } from "react-router-dom";
import { Paths } from "../../common/constants";
import ContainedButton from "../../components/button/contained-button";

/**
 * Defines Account Detail Fortress Page
 * @returns The Account Detail Fortress Component
 */
const AccountDetailFortress = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { personalDetails } = useAppSelector(
    (state: RootState) => state.personal
  );
  const { address } = personalDetails;

  return (
    <Box sx={styles.accountSummary_mainContainer}>
      <Cards
        childComp={
          <Box sx={styles.accountSummary_cardContentContainer}>
            <Box sx={{ width: "100%" }}>
              <Box sx={styles.accountDetailContainer}>
                <Box sx={styles.headingContainer}>
                  <Typography sx={styles.accountName}>
                    {personalDetails?.fullName}
                  </Typography>
                  <Typography
                    sx={
                      personalDetails?.status !== "active"
                        ? { ...styles.accountStatus, ...styles.pending }
                        : { ...styles.accountStatus, ...styles.active }
                    }
                  >
                    {personalDetails?.status}
                  </Typography>
                  <Typography
                    sx={
                      personalDetails?.kycLevel === "L0"
                        ? { ...styles.accountStatus, ...styles.pending }
                        : { ...styles.accountStatus, ...styles.active }
                    }
                  >
                    {personalDetails?.kycLevel}
                  </Typography>
                </Box>
              </Box>
              <Box sx={styles.accountSummary_detailsContainer}>
                <Box sx={styles.textContainer}>
                  <Typography sx={styles.textHeading}>Identity Type</Typography>
                  <Typography>{personalDetails.type}</Typography>
                </Box>
                <Box sx={styles.textContainer}>
                  <Typography sx={styles.textHeading}>Identity ID</Typography>
                  <Typography>{personalDetails.id}</Typography>
                </Box>

                <Box sx={styles.textContainer}>
                  <Typography sx={styles.textHeading}>Email</Typography>
                  <Typography>{personalDetails?.email}</Typography>
                </Box>

                <Box sx={styles.textContainer}>
                  <Typography sx={styles.textHeading}>Address</Typography>
                  <Typography>{`${address.street1}  , ${address.city} , ${address.state} , ${address.country} , ${address.postalCode}`}</Typography>
                </Box>

                <Box sx={styles.textContainer}>
                  <Typography sx={styles.textHeading}>Date of Birth</Typography>
                  <Typography>{personalDetails.dateOfBirth}</Typography>
                </Box>

                <Box sx={styles.textContainer}>
                  <Typography sx={styles.textHeading}>Phone Number</Typography>
                  <Typography>{personalDetails.phone}</Typography>
                </Box>

                <Box sx={styles.textContainer}>
                  <Typography sx={styles.textHeading}>SSN</Typography>
                  <Typography>{personalDetails.ssn}</Typography>
                </Box>
              </Box>
            </Box>
            {location.pathname === Paths.FORTRESS_DASHBOARD && (
              <ContainedButton
                title={"View More Details"}
                size="large"
                fullWidth
                onClick={() => navigate(Paths.FORTRESS_DETAILS)}
              />
            )}
          </Box>
        }
      />
    </Box>
  );
};

export default AccountDetailFortress;
