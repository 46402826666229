import {
  MenuItem,
  FormControl,
  Select,
  SelectProps,
  Typography,
  Box,
} from "@mui/material";
import { useField } from "formik";
import {
  GetKeyValue,
  GetListText,
  GetListValue,
  MenuAttributes,
} from "./helper";
import styles from "./styles";
import { SelectCompProps } from "./type";

// Define a custom Select component
export default function SelectComp({
  onChange,
  label,
  name,
  inputPlaceholder,
  searchCall,
  onScroll,
  clearSearch,
  onClose,
  handleChange,
  options = [],
  isSearch = false,
  labelKey = "",
  valKey = "",
  externalStyles = {},
  isRequired = false,
  errortipPlacement = "left",
  labelValue = "",
  disabledArray = [],
  searchValue = "",
  hideLabel = false,
  ...otherProps
}: SelectCompProps & SelectProps) {
  // Use formik's `useField` hook to manage the input field's state and validation
  const [field, meta] = useField(name);

  /* eslint-disable react/no-unstable-nested-components */

  // Define attributes for the Select component
  const attributes: SelectProps = {
    ...field,
    ...otherProps,
    id: `${name}-select`,
    placeholder: `${label}${isRequired ? "*" : ""}`,
    error: meta.touched && Boolean(meta.error),
    sx: styles.select,
    displayEmpty: true,
  };

  if (onChange) attributes.onChange = onChange;
  if (onClose) attributes.onClose = onClose;

  if (meta.touched && meta.error) {
    attributes.error = true;
  }

  // Render Value in Select component
  const RenderValue = (
    placeholder: string,
    selected: string,
    optionsToFilter: { [key: string]: any }
  ) => {
    if (selected && selected?.length > 0 && optionsToFilter.length > 0) {
      const val = optionsToFilter.filter(
        (item: any) => item[valKey] === selected
      );
      if (val.length > 0) return val[0][labelKey];
      searchValue = labelValue;
      return labelValue;
    }
    return placeholder;
  };

  return (
    <Box sx={[externalStyles]}>
      <FormControl fullWidth sx={styles.formControl}>
        {!hideLabel && label && (
          <Typography
            component="div"
            sx={{ ...styles.label, ...(!field.value && { ...styles.none }) }}
          >
            {`${label}${isRequired ? "*" : ""}`}
          </Typography>
        )}
        <Select
          MenuProps={
            onScroll
              ? MenuAttributes(onScroll)
              : {
                  PaperProps: {
                    style: styles.paper,
                    sx: styles.select,
                  },
                }
          }
          renderValue={(selected: any) => {
            return <>{RenderValue(label, selected, options)}</>;
          }}
          {...attributes}
        >
          {options?.map(
            (listItem: string | { [key: string]: any }, index: number) => {
              const key = GetKeyValue(listItem, index, valKey);
              return (
                <MenuItem
                  key={key}
                  value={GetListValue(listItem, valKey)}
                  autoFocus={index === 0}
                  sx={styles.menuItem}
                  disabled={disabledArray.includes(key)}
                >
                  {GetListText(labelKey, listItem)}
                </MenuItem>
              );
            }
          )}
        </Select>
      </FormControl>
    </Box>
  );
}
