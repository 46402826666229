import { PaperProps, SelectChangeEvent } from "@mui/material";
import styles from "./styles";

const ITEM_HEIGHT = 48;

const ITEM_PADDING_TOP = 8;

export const MenuAttributes = (onScroll: (evt: any) => void) => {
  return {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
      onScroll,
    } as PaperProps,
    sx: styles.select,
    autoFocus: false,
  };
};

export const RenderValue = (
  valKey: string,
  placeholder: string,
  selected: Array<string | { [key: string]: any }>
) => {
  if (selected.length > 0) {
    return selected
      .map((selval: string | { [key: string]: any }) => {
        if (typeof selval === "string") return selval;
        return selval[valKey];
      })
      .join(", ");
  }
  return placeholder;
};

export const HandleChange = (
  options: Array<string | { [key: string]: any }>,
  event: SelectChangeEvent<Array<string | { [key: string]: any }>>,
  setValue: (value: Array<string | { [key: string]: any }>) => void
) => {
  const { value } = event.target;
  if (typeof value === "object") {
    const Ids = value.filter((item: any) => typeof item === "string");
    const Objs = value.filter((item: any) => typeof item === "object");

    const isAvailable =
      Objs.findIndex((item: any) => Ids.includes(JSON.stringify(item))) > -1;
    if (isAvailable)
      setValue(Objs.filter((item: any) => JSON.stringify(item) !== Ids[0]));
    else
      setValue(
        typeof Ids[0] === "string" ? [...Objs, JSON.parse(Ids[0])] : [...Objs]
      );
  }
};

export const GetCheckedValue = (
  values: Array<string | { [key: string]: any }>,
  listItem: string | { [key: string]: any }
) => {
  return (
    values.findIndex((val: string | { [key: string]: any }) => {
      if (typeof val === "string" && typeof listItem === "string")
        return val === listItem;
      if (typeof val === "object" && typeof listItem === "object")
        return val._id === listItem._id;
      return false;
    }) > -1
  );
};

export const GetKeyValue = (
  listItem: string | { [key: string]: any },
  index: number
) => {
  if (typeof listItem === "string") return `row-${index + 1}`;
  return listItem._id;
};

export const GetListValue = (listItem: string | { [key: string]: any }) => {
  if (typeof listItem === "string") return listItem;
  return JSON.stringify(listItem);
};

export const GetListText = (
  valKey: string,
  listItem: string | { [key: string]: any }
) => {
  if (typeof listItem === "string") return listItem;
  return valKey ? listItem[valKey] : "";
};
