import { Box, Typography } from "@mui/material";

import styles from "./styles";
import BusinessDetailHelper from "./helper";
import { useEffect } from "react";
import { getBusinessCustodialAccountListingFortress } from "../services/action";
import CustodialAccountListing from "../../../components/tables/custodialAccountListing";
import ContainedButton from "../../../components/button/contained-button";
import { createCustodialAccount } from "../../fo.forms/services/action";
import { Paths } from "../../../common/constants";

/**
 * Defines Account Detail Tab Component
 * @returns The Account Detail Tab Component
 */
const AccountDetailTab = () => {
  const { id, dispatch, custodialAccountListing, navigate } =
    BusinessDetailHelper();

  useEffect(() => {
    dispatch(getBusinessCustodialAccountListingFortress(id));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box width="100%">
      {custodialAccountListing && custodialAccountListing.length < 1 && (
        <ContainedButton
          title="Create Custodial Account Using Business ID"
          externalStyles={styles.walletBtn}
          fullWidth
          size="large"
          type="submit"
          onClick={() => {
            const values = {
              accountType: "business",
              businessIdentityId: id,
            };
            dispatch(
              createCustodialAccount(values, id, () => {
                navigate(`${Paths.FORTRESS_BUSINESS_ACCOUNTS}?id=${id}`);
              })
            );
          }}
        />
      )}
      {custodialAccountListing && custodialAccountListing.length > 0 ? (
        <>
          <Typography sx={styles.heading}>Your Custodial Accounts</Typography>
          <CustodialAccountListing
            listingData={custodialAccountListing}
            type="business"
          />
        </>
      ) : (
        <Typography sx={styles.heading}>No Accounts Created Yet.</Typography>
      )}
    </Box>
  );
};

export default AccountDetailTab;
