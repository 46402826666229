import { AxiosResponse } from "axios";
import { Dispatch } from "@reduxjs/toolkit";
import { EndPoint, Paths } from "../../../common/constants";
import { getApiCall, postApiCall } from "../../../common/constants/api";
import {
  apiErrorCode,
  apiSuccessCode,
} from "../../../common/constants/axios.instance";
import { showAlert } from "../../../common/utils/alert";
import { setLoading } from "../../../redux/global.slice";
import { OnResendOtp } from "../../verification/services/action";
import { setUserDetails } from "./slice";

/**
 * Action function to handle user login.
 * @param {object} values - User login form values.
 * @param {function} setSubmitting - Function to set the submitting state.
 * @param {function} navigate - Navigation function.
 */
export const onLogin =
  (values: any, setSubmitting: any, navigate: any) =>
  (dispatch: Dispatch<any>) => {
    if (!navigator.onLine) {
      showAlert(2, "No internet connection!");
      return;
    }
    setSubmitting(true);
    dispatch(setLoading(false));
    postApiCall(
      EndPoint.login,
      values,
      (respData: AxiosResponse<any>) => {
        const { data } = respData;
        if (data?.statusCode === apiSuccessCode.success) {
          localStorage.setItem("email", values.email);
          localStorage.setItem("name", data?.data?.name);
          localStorage.setItem("auth_token", data?.data?.authToken);
          navigate(Paths.HOME);
          showAlert(1, data?.message);
        }
        dispatch(setLoading(false));
        setSubmitting(false);
      },
      (error: any) => {
        dispatch(setLoading(false));
        setSubmitting(false);
        if (error.statusCode === apiErrorCode.USER_NOT_VERIFIED) {
          localStorage.setItem("email", values.email);
          dispatch(OnResendOtp(values.email, navigate));
          navigate(Paths.VERIFICATION);
          return;
        }
        showAlert(2, error.message);
      }
    );
  };

// Action function for getting user details
export const getProfile = () => (dispatch: Dispatch<any>) => {
  if (!navigator.onLine) {
    showAlert(2, "No internet connection!");
    return;
  }

  dispatch(setLoading(true));
  getApiCall(
    EndPoint.getProfile,
    "",
    (response: AxiosResponse<any>) => {
      const { statusCode, data } = response.data;
      if (statusCode === apiSuccessCode.success) {
        dispatch(setUserDetails(data));
      }
      dispatch(setLoading(false));
    },
    (error: any) => {
      dispatch(setLoading(false));
      showAlert(2, error.message);
    }
  );
};
