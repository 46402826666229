import { createTheme } from "@mui/material/styles";
import { red } from "@mui/material/colors";
import { COLORS } from "./colors";

// Create a theme instance.
const theme = createTheme({
  palette: {
    primary: {
      main: COLORS.theme,
    },
    secondary: {
      main: "#19857b",
    },
    error: {
      main: red.A400,
    },
  },
  typography: {
    allVariants: {
      fontFamily: "SofiaPro",
    },
  },
});

export default theme;
