/**
 * Color definitions.
 */
export const COLORS = {
  theme: "#3393CF",
  black: "#000000",
  lightGrey: "rgba(96, 96, 96, 0.46)",
  darkGrey: "#0000008F",
  white: "#ffffff",
  primary: "#005EAD",
  gray: "#606060",
  hoverOver: "#4697db",
  borderGrey: "#606060",
  paginationFillGrey: "#898989",
  paginationGrey: "#C4C4C4",
  actionPopup: "#F9F9F9",
  actionPopupBorder: "#D1D1D1",
  greyInput: "#A9A9A9",
  grayButtonColor: "#D9D9D9",
  profileGray: "#A0A4AE",
  filterHead: "#6C6C6C",
  filterStatusBack: "rgba(244, 244, 244, 1)",
  headingGray: "#878787",
  sidebarGray: "#8A8A8A",
  primaryBlue: "#2061A8",
  lightBlue: " #0078DC",
  darkBlue: "#134a85",
  disableButton: "#717171",
  success: "#009C50",
  warning: "#FFB11B",
  error: "#E71515",
  backgroundColor: "#f0f0f5",
  border: "#D6DBDE",
  themeError: "rgba(231,21,21,1)",
  inputHead: "#181B20",
  background: "#E1E1E1",
  primaryLightBlue: "#F5FAFF",
  searchBackground: "#EFF0F6",
  tableHeader: "#323232",
  orangeBg: "#FDF2E1",
  orange: "#EB971A",
  greenBg: "#E7F5EE",
  green: "#19A85B",
  // Add more color definitions here if needed
};
