import { Box, Typography } from "@mui/material";
import moment from "moment";

import styles from "./styles";
import BusinessDetailHelper from "./helper";
import BusinessDocuments from "../../../components/tables/businessIdentities/businessDocuments";
import ContainedButton from "../../../components/button/contained-button";
import { Paths } from "../../../common/constants";

/**
 * Defines Business Detail Tab Component
 * @returns The Business Detail Tab Component
 */
const BusinessDetailTab = () => {
  const { businessDetails, navigate, id, getBeneficialOwners } =
    BusinessDetailHelper();

  const displayBeneficialOwnersName = getBeneficialOwners().map(
    (owner: any) => owner.name
  );

  return (
    <Box width="100%" padding="20px">
      <Box sx={styles.accountSummary_detailsContainer}>
        <Box sx={styles.textContainer}>
          <Typography sx={styles.textHeading}>Naics Description</Typography>
          <Typography>{businessDetails?.naicsDescription}</Typography>
        </Box>
        <Box sx={styles.textContainer}>
          <Typography sx={styles.textHeading}>Naics</Typography>
          <Typography>{businessDetails?.naics}</Typography>
        </Box>
        <Box sx={styles.textContainer}>
          <Typography sx={styles.textHeading}>EIN</Typography>
          <Typography>{businessDetails?.ein}</Typography>
        </Box>
        <Box sx={styles.textContainer}>
          <Typography sx={styles.textHeading}>Description</Typography>
          <Typography>{businessDetails?.description}</Typography>
        </Box>
        <Box sx={styles.textContainer}>
          <Typography sx={styles.textHeading}>Established On</Typography>
          <Typography>
            {moment(businessDetails?.establishedOn).format("YYYY-MM-DD")}
          </Typography>
        </Box>
        <Box sx={styles.textContainer}>
          <Typography sx={styles.textHeading}>Legal Structure</Typography>
          <Typography>{businessDetails?.legalStructure}</Typography>
        </Box>
      </Box>
      {businessDetails.beneficialOwners &&
        businessDetails.beneficialOwners.length > 0 && (
          <Box sx={styles.textContainer}>
            <Typography sx={styles.textHeading}>Beneficial Owners</Typography>
            <Typography>{displayBeneficialOwnersName.join(" , ")}</Typography>
          </Box>
        )}
      {businessDetails.documents && businessDetails.documents.length > 0 ? (
        <Box>
          <Typography sx={styles.docHeading}>Documents</Typography>
          <BusinessDocuments data={businessDetails.documents} />
        </Box>
      ) : (
        <ContainedButton
          title="Upload Documents"
          externalStyles={styles.accountSummary_btn}
          fullWidth
          size="large"
          type="submit"
          onClick={() =>
            navigate(`${Paths.FORTRESS_BUSINESS_COMPLETE_KYC}?id=${id}`)
          }
        />
      )}
    </Box>
  );
};

export default BusinessDetailTab;
