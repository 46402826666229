import React, { ChangeEvent } from "react";
import { Formik, Form } from "formik";
import { Box, Typography } from "@mui/material";
import TaskAltRoundedIcon from "@mui/icons-material/TaskAltRounded";

import styles from "./styles";
import CompleteFortressKYCPersonalHelper from "./helper";
import SelectComp from "../../../components/formControls/select/singleSelect";
import OutlinedButton from "../../../components/button/outlined-button";
import ContainedButton from "../../../components/button/contained-button";
import Schema from "../../../common/constants/schema";
import Upload from "../../../components/upload";
import { uploadDocumentFortress } from "../services/action";
import { Paths } from "../../../common/constants/paths";

/**
 * Defines Complete Fortress KYC Page
 * @returns The Complete Fortress KYC Component
 */
const CompleteFortressKYC = () => {
  const { initialValues, dispatch, navigate, userDetails } =
    CompleteFortressKYCPersonalHelper();

  return (
    <Box sx={styles.mainContainer}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={Schema.CompleteYourFortressKYC}
        onSubmit={(values) => {
          dispatch(
            uploadDocumentFortress(values, userDetails.personalIdentity, () =>
              navigate(Paths.FORTRESS_DETAILS)
            )
          );
        }}
      >
        {({ isValid, setFieldValue, values }) => (
          <Form>
            <Box>
              <Typography sx={styles.mainHeading}>Complete Your KYC</Typography>
              <Box>
                <SelectComp
                  name="doc_type"
                  label="Document Type"
                  externalStyles={styles.fullWidth}
                  options={[
                    { id: "license", value: "License" },
                    { id: "identificationCard", value: "ID Card" },
                    { id: "passport", value: "Passport" },
                  ]}
                  labelKey="value"
                  valKey="id"
                  fullWidth
                />
              </Box>
              <Box sx={styles.container}>
                <Typography>Upload Your Front Document</Typography>
                <Box sx={styles.uploadAgainContainer}>
                  {values.front !== "" && <TaskAltRoundedIcon />}
                  <Upload
                    name={"front"}
                    accept={"image/png"}
                    handleImageChange={(evt: ChangeEvent<HTMLInputElement>) => {
                      if (evt?.target?.files && evt.target.files.length > 0) {
                        setFieldValue("front", evt.target.files[0]);
                      }
                    }}
                    imageName={
                      values.front !== ""
                        ? "Upload Again"
                        : "Upload Front Image"
                    }
                  />
                </Box>
              </Box>
              <Box sx={styles.container}>
                <Typography>Upload Your Back Document</Typography>
                <Box sx={styles.uploadAgainContainer}>
                  {values.back !== "" && <TaskAltRoundedIcon />}
                  <Upload
                    name={"back"}
                    accept={"image/png"}
                    handleImageChange={(evt: ChangeEvent<HTMLInputElement>) => {
                      if (evt?.target?.files && evt.target.files.length > 0) {
                        setFieldValue("back", evt?.target.files[0]);
                      }
                    }}
                    imageName={
                      values.back !== "" ? "Upload Again" : "Upload Back Image"
                    }
                  />
                </Box>
              </Box>
              <Box sx={styles.btnContainer}>
                <ContainedButton
                  type="submit"
                  externalStyles={styles.btn}
                  title="Save"
                  disabled={!isValid}
                />
                <ContainedButton
                  externalStyles={{ ...styles.btn, ...styles.skipBtn }}
                  title="Skip For Now"
                  onClick={() => navigate(Paths.FORTRESS_DETAILS)}
                />
                <OutlinedButton externalStyles={styles.btn} title="Cancel" />
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default CompleteFortressKYC;
