// This file defines a Redux slice for managing the fund state.
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  FundListing,
  FundModel,
  PaymentData,
  PaymentDetail,
  Result,
} from "./type";

// Initial Value for fund slice
const initialState: FundModel = {
  fundListing: [],
  usersFundListing: [],

  fundCardDetails: {
    _id: "",
    userId: "",
    ownerIdentityId: "",
    type: "",
    externalAccountId: "",
    accountNumberLast4: "",
    createdAt: "",
    updatedAt: "",
    cardVerificationStatus: "",
    network: "",
  },

  paymentDetails: {
    id: "",
    type: "",
    status: "",
    source: {
      identityId: "",
      externalAccountId: "",
    },
    destination: {
      identityId: "",
      custodialAccountId: "",
    },
    comment: "",
    funds: 0,
    currency: "",
  },

  paymentData: {
    next: false,
    result: [],
    total: 0,
  },

  paymentDataResult: [],

  paymentQueryParameters: {
    page: 1,
    limit: 10,
  },

  externalAccountQueryParameters: {
    page: 1,
    limit: 10,
  },

  linkToken: "",
};

// Create the fund slice using createSlice from Redux Toolkit
const fundSlice = createSlice({
  name: "fund",
  initialState,
  reducers: {
    setFundListing: (
      state: FundModel,
      action: PayloadAction<FundListing[]>
    ) => {
      state.fundListing = [...action.payload];
    },

    setAllUsersFundListing: (
      state: FundModel,
      action: PayloadAction<FundListing[]>
    ) => {
      state.usersFundListing = [...action.payload];
    },

    setFundCardDetails: (
      state: FundModel,
      action: PayloadAction<FundListing>
    ) => {
      state.fundCardDetails = action.payload;
    },

    setPaymentDetails: (
      state: FundModel,
      action: PayloadAction<PaymentDetail>
    ) => {
      state.paymentDetails = action.payload;
    },

    setPaymentData: (state: FundModel, action: PayloadAction<PaymentData>) => {
      state.paymentData = action.payload;
    },

    setPaymentResult: (state: FundModel, action: PayloadAction<Result[]>) => {
      state.paymentDataResult = [...action.payload];
    },

    setLinkToken: (state: FundModel, action: PayloadAction<string>) => {
      state.linkToken = action.payload;
    },
  },
});

// Export the action creators generated by createSlice
export const {
  setFundListing,
  setFundCardDetails,
  setPaymentDetails,
  setPaymentData,
  setPaymentResult,
  setAllUsersFundListing,
  setLinkToken,
} = fundSlice.actions;

// Export the reducer function generated by createSlice
export default fundSlice.reducer;
