import { Box } from "@mui/material";
import { Form, Formik } from "formik";

import Input from "../../formControls/input";
import ContainedButton from "../../button/contained-button";
import OutlinedButton from "../../button/outlined-button";
import styles from "./styles";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import Schema from "../../../common/constants/schema";
import { useNavigate } from "react-router-dom";
import { addCustodialAccount } from "../../../pages/pt.forms/services/action";
import { Paths } from "../../../common/constants";

/**
 * Renders a Get business Id form for creating business Id.
 * @returns {React.ReactNode} The rendered component.
 */
const GetBusinessIdForm = () => {
  const initialValues = {
    accountName: "",
    name: "",
    email: "",
    DOB: "",
    taxIdNumber: "",
    phoneNumber: "",
    street1: "",
    street2: "",
    postalCode: "",
    city: "",
    region: "",
    contactEmail: "",
  };

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return (
    <Box sx={styles.custodialForm_mainContainer}>
      <Formik
        initialValues={initialValues}
        validationSchema={Schema.CreateCustodialSchema}
        onSubmit={(values, { setSubmitting }) => {
          dispatch(addCustodialAccount(values, setSubmitting, navigate));
        }}
      >
        {(props) => {
          return (
            <Form>
              <Box sx={styles.formContainer}>
                <Input
                  name="accountName"
                  type="text"
                  label="Account Name"
                  placeholder="Account Name"
                  fullWidth
                  isRequired
                  externalStyles={styles.textField}
                />
                {/* <Input
                  name="name"
                  type="text"
                  label="Name"
                  placeholder="Name"
                  fullWidth
                  isRequired
                  externalStyles={styles.textField}
                /> */}
                {/* <Input
                  name="email"
                  type="email"
                  label="Email Address"
                  placeholder="Email Address"
                  fullWidth
                  isRequired
                  externalStyles={styles.textField}
                /> */}
                <Input
                  name="DOB"
                  type="text"
                  label="Date of Birth"
                  placeholder="Date of Birth"
                  fullWidth
                  isRequired
                  externalStyles={styles.textField}
                />
                <Input
                  name="taxIdNumber"
                  type="text"
                  label="Tax ID Number"
                  placeholder="Tax ID Number"
                  fullWidth
                  isRequired
                  externalStyles={styles.textField}
                />
                {/* <Input
                  name="phoneNumber"
                  type="text"
                  label="Phone Number"
                  placeholder="Phone Number"
                  fullWidth
                  isRequired
                  externalStyles={styles.textField}
                /> */}
                <Input
                  name="street1"
                  type="text"
                  label="Street 1"
                  placeholder="Street 1"
                  fullWidth
                  isRequired
                  externalStyles={styles.textField}
                />
                <Input
                  name="street2"
                  type="text"
                  label="Street 2"
                  placeholder="Street 2"
                  fullWidth
                  isRequired
                  externalStyles={styles.textField}
                />
                <Input
                  name="postalCode"
                  type="text"
                  label="Postal Code"
                  placeholder="Postal Code"
                  fullWidth
                  isRequired
                  externalStyles={styles.textField}
                />
                <Input
                  name="city"
                  type="text"
                  label="City"
                  placeholder="City"
                  fullWidth
                  isRequired
                  externalStyles={styles.textField}
                />
                <Input
                  name="region"
                  type="text"
                  label="Region"
                  placeholder="Region"
                  fullWidth
                  isRequired
                  externalStyles={styles.textField}
                />
                <Input
                  name="contactEmail"
                  type="email"
                  label="Contact Email"
                  placeholder="Contact Email"
                  fullWidth
                  isRequired
                  externalStyles={styles.textField}
                />
              </Box>

              <Box sx={styles.btnContainer}>
                <ContainedButton
                  title={"Save"}
                  externalStyles={styles.btn}
                  size="large"
                  type="submit"
                  fullWidth
                />
                <OutlinedButton
                  title="Cancel"
                  externalStyles={styles.btn}
                  size="large"
                  fullWidth
                  onClick={() => {
                    navigate(Paths.HOME);
                  }}
                />
              </Box>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default GetBusinessIdForm;
