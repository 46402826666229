import React, { ChangeEvent } from "react";
import { Formik, Form } from "formik";
import { Box, Typography } from "@mui/material";
import TaskAltRoundedIcon from "@mui/icons-material/TaskAltRounded";

import styles from "./styles";
import CompleteBusinessKYCFortressHelper from "./helper";
import SelectComp from "../../../components/formControls/select/singleSelect";
import OutlinedButton from "../../../components/button/outlined-button";
import ContainedButton from "../../../components/button/contained-button";
import Schema from "../../../common/constants/schema";
import Upload from "../../../components/upload";
import { Paths } from "../../../common/constants";
import { uploadDocumentBusinessFortress } from "../services/action";

/**
 * Defines Complete Business KYC Component
 * @returns The Complete Business KYC Component
 */
const CompleteBusinessKYCFortress = () => {
  const { initialValues, dispatch, id, navigate } =
    CompleteBusinessKYCFortressHelper();

  return (
    <Box sx={styles.mainContainer}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={Schema.CompleteYourFortressKYC}
        onSubmit={(values) => {
          dispatch(
            uploadDocumentBusinessFortress(values, id, () =>
              navigate(`${Paths.FORTRESS_BUSINESS_DETAILS}?id=${id}`)
            )
          );
        }}
      >
        {({ isValid, setFieldValue, values }) => (
          <Form>
            <Box>
              <Typography sx={styles.mainHeading}>
                Complete Your Business Account KYC
              </Typography>
              <Box>
                <SelectComp
                  name="doc_type"
                  label="Document Type"
                  externalStyles={styles.fullWidth}
                  options={[
                    { id: "proofOfAddress", value: "Proof of Address" },
                    {
                      id: "proofOfCompanyFormation",
                      value: "Proof of Company Formation",
                    },
                    { id: "other", value: "Other" },
                  ]}
                  labelKey="value"
                  valKey="id"
                  fullWidth
                />
              </Box>

              <Box sx={styles.container}>
                <Typography>Upload Your Front Document (Required)</Typography>
                <Box sx={styles.uploadAgainContainer}>
                  {values.front !== "" && <TaskAltRoundedIcon />}
                  <Upload
                    name={"front"}
                    accept={"image/png"}
                    handleImageChange={(evt: ChangeEvent<HTMLInputElement>) => {
                      if (evt?.target?.files && evt.target.files.length > 0) {
                        setFieldValue("front", evt.target.files[0]);
                      }
                    }}
                    imageName={
                      values.front !== ""
                        ? "Upload Again"
                        : "Upload Front Image"
                    }
                  />
                </Box>
              </Box>
              <Box sx={styles.container}>
                <Typography>Upload Your Back Document</Typography>
                <Box sx={styles.uploadAgainContainer}>
                  {values.back !== "" && <TaskAltRoundedIcon />}
                  <Upload
                    name={"back"}
                    accept={"image/png"}
                    handleImageChange={(evt: ChangeEvent<HTMLInputElement>) => {
                      if (evt?.target?.files && evt.target.files.length > 0) {
                        setFieldValue("back", evt?.target.files[0]);
                      }
                    }}
                    imageName={
                      values.back !== "" ? "Upload Again" : "Upload Back Image"
                    }
                  />
                </Box>
              </Box>
              <Box sx={styles.btnContainer}>
                <ContainedButton
                  type="submit"
                  externalStyles={styles.btn}
                  title="Save"
                  disabled={!isValid}
                />
                <OutlinedButton externalStyles={styles.btn} title="Cancel" />
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default CompleteBusinessKYCFortress;
