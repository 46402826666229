import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import { Paths } from "../../../common/constants";
import { commonTableStyles as styles } from "../styles";

/**
 * Component for rendering custodial account table.
 *
 * @param {object} data - The data for the table.
 * @returns {JSX.Element} The rendered component.
 */
const CustodialAccountListing = ({ listingData, type }: any) => {
  return (
    <TableContainer>
      <Table sx={styles.table}>
        <TableHead sx={{ ...styles.tableHeader }}>
          <TableRow sx={styles.headerRow}>
            <TableCell>Account ID</TableCell>
            <TableCell>Account Number</TableCell>
            <TableCell>Account Type</TableCell>
            <TableCell>Account Status</TableCell>
          </TableRow>
        </TableHead>

        {listingData && listingData.length > 0 && (
          <TableBody>
            {listingData &&
              listingData.length > 0 &&
              listingData.map((data: any) => {
                return (
                  <TableRow key={data.id} sx={{ ...styles.row }}>
                    <TableCell>
                      <Link
                        to={`${Paths.FORTRESS_CUSTODIAL_ACCOUNT_DETAIL}?type=${type}&id=${data.id}`}
                      >
                        <Typography sx={{ ...styles.link }}>
                          {data.id}
                        </Typography>
                      </Link>
                    </TableCell>
                    <TableCell>
                      <Typography>{data.accountNumber}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{data.accountType}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{data.accountStatus}</Typography>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
};

export default CustodialAccountListing;
