import { useEffect } from "react";
import moment from "moment";
import { Box, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useNavigate } from "react-router-dom";

import styles from "./styles";
import { useAppSelector } from "../../hooks/useAppSelector";
import { RootState } from "../../redux/store";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { getProfile } from "../login/services/action";
import ContainedButton from "../../components/button/contained-button";
import { Paths } from "../../common/constants";

/**
 * Defines Active Button Section Page
 * @returns The Active Button Section Component
 */
const ActiveButtonSection = () => {
  return (
    <Box sx={styles.active_arrowContainer}>
      <Box sx={styles.iconContainer}>
        <Box sx={styles.activeIcons}>
          <AddIcon />
        </Box>
        Deposit
      </Box>

      <Box sx={styles.iconContainer}>
        <Box sx={styles.activeIcons}>
          <ArrowForwardIcon />
        </Box>
        Send
      </Box>
    </Box>
  );
};

/**
 * Defines Account Detail Page
 * @returns The Account Detail Component
 */
const AccountDetail = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { userDetails } = useAppSelector((state: RootState) => state.login);

  useEffect(() => {
    dispatch(getProfile());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box sx={styles.accountSummary_mainContainer}>
      <Box sx={styles.accountSummary_upperCardContainer}>
        <Box sx={styles.accountSummary_cardContentContainer}>
          <Box sx={{ width: "80%" }}>
            <Box sx={styles.accountDetailContainer}>
              <Typography sx={styles.accountName}>
                {userDetails?.accountName}
              </Typography>
              <Typography
                sx={
                  userDetails?.kycStatus === "pending"
                    ? { ...styles.accountStatus, ...styles.pending }
                    : { ...styles.accountStatus, ...styles.active }
                }
              >
                {userDetails?.kycStatus}
              </Typography>
            </Box>

            <Box sx={styles.accountSummary_detailsContainer}>
              <Box sx={styles.textContainer}>
                <Typography sx={styles.textHeading}>Account Number</Typography>
                <Typography>{userDetails?.accountNumber}</Typography>
              </Box>
              <Box sx={styles.textContainer}>
                <Typography sx={styles.textHeading}>Account Type</Typography>
                <Typography>Custodial Account</Typography>
              </Box>
              <Box sx={styles.textContainer}>
                <Typography sx={styles.textHeading}>Account Owner</Typography>
                <Typography>{userDetails?.name}</Typography>
              </Box>
              <Box sx={styles.textContainer}>
                <Typography sx={styles.textHeading}>
                  Account Organization
                </Typography>
                <Typography>Tester</Typography>
              </Box>

              <Box sx={styles.textContainer}>
                <Typography sx={styles.textHeading}>Account Subtype</Typography>
                <Typography>Traditional Custodial</Typography>
              </Box>

              <Box sx={styles.textContainer}>
                <Typography sx={styles.textHeading}>Created At</Typography>
                <Typography>
                  {moment(userDetails?.createdAt).format("YYYY-MM-DD")}
                </Typography>
              </Box>
              <Box sx={styles.textContainer}>
                <Typography sx={styles.textHeading}>Last Updated At</Typography>
                <Typography>
                  {moment(userDetails?.updatedAt).format("YYYY-MM-DD")}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box sx={styles.btnContainer}>
            {userDetails?.kycStatus === "pending" ? (
              <ContainedButton
                title="Complete Your KYC"
                externalStyles={styles.btn}
                fullWidth
                size="large"
                type="submit"
                onClick={() => navigate(Paths.PRIME_TRUST_COMPLETE_KYC)}
              />
            ) : (
              <ActiveButtonSection />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AccountDetail;
