// This file defines a Redux slice for managing the business state.
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  BusinessDetails,
  BusinessModel,
  ListingData,
  TransactionDetail,
  TransactionListingData,
  TransactionQueryParameters,
} from "./type";

// Initial state for the business slice
const initialState: BusinessModel = {
  businessDetails: {
    companyName: "",
    phone: "",
    ein: "",
    website: "",
    regionOfFormation: "",
    description: "",
    establishedOn: "",
    legalStructure: "",
    mailingAddress: {
      street1: "",
      street2: "",
      postalCode: "",
      city: "",
      state: "",
      country: "",
    },
    naics: "",
    naicsDescription: "",
    isSuspended: false,
    beneficialOwners: [],
    id: "",
    identityContainerId: "",
    kycLevel: "",
    type: "",
    status: "",
    address: {
      street1: "",
      street2: "",
      postalCode: "",
      city: "",
      state: "",
      country: "",
    },
    email: "",
    documents: [],
  },
  usersCustodialAccountListing: [],
  custodialAccountListing: [],
  custodialAccountListing1: [],

  custodialAccountDetails: {
    id: "",
    ownerIdentityId: "",
    accountStatus: "",
    accountType: "",
    accountNumber: "",
  },
  walletDetails: {},
  transactionListing: { data: [], page: 1, total: 10 },
  transactionDetails: {
    id: "",
    paymentId: "",
    paymentType: "",
    status: "",
    source: { identityId: "" },
    destination: { identityId: "", custodialAccountId: "" },
    comment: "",
    amount: 0,
    currency: "",
    description: "",
    createdAt: "",
    groupId: "",
  },
  transactionQueryParameters: { page: 1, limit: 10 },

  accountBalance: [],
};

// Create the business detail slice using createSlice from Redux Toolkit
const businessSlice = createSlice({
  name: "business",
  initialState,
  reducers: {
    setBusinessDetails: (
      state: BusinessModel,
      action: PayloadAction<BusinessDetails>
    ) => {
      state.businessDetails = action.payload;
    },

    setCustodialAccountListing: (
      state: BusinessModel,
      action: PayloadAction<ListingData[]>
    ) => {
      state.custodialAccountListing = [...action.payload];
    },
    setCustodialAccountListing1: (
      state: BusinessModel,
      action: PayloadAction<ListingData[]>
    ) => {
      state.custodialAccountListing1 = [...action.payload];
    },

    setUsersCustodialAccountListing: (
      state: BusinessModel,
      action: PayloadAction<ListingData[]>
    ) => {
      state.usersCustodialAccountListing = [...action.payload];
    },

    setCustodialAccountDetails: (
      state: BusinessModel,
      action: PayloadAction<ListingData>
    ) => {
      state.custodialAccountDetails = action.payload;
    },

    setWalletDetails: (state: BusinessModel, action: PayloadAction<any>) => {
      state.walletDetails = action.payload;
    },

    setTransactionListing: (
      state: BusinessModel,
      action: PayloadAction<TransactionListingData>
    ) => {
      state.transactionListing = action.payload;
    },

    setTransactionDetails: (
      state: BusinessModel,
      action: PayloadAction<TransactionDetail>
    ) => {
      state.transactionDetails = action.payload;
    },

    setAccountBalance: (state: BusinessModel, action: PayloadAction<[]>) => {
      state.accountBalance = [...action.payload];
    },

    setTransactionQueryParameters: (
      state: BusinessModel,
      action: PayloadAction<TransactionQueryParameters>
    ) => {
      state.transactionQueryParameters = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setBusinessDetails,
  setCustodialAccountListing,
  setCustodialAccountListing1,
  setCustodialAccountDetails,
  setWalletDetails,
  setTransactionListing,
  setTransactionDetails,
  setUsersCustodialAccountListing,
  setAccountBalance,
  setTransactionQueryParameters,
} = businessSlice.actions;

// Export the reducer function generated by createSlice
export default businessSlice.reducer;
