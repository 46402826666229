import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../hooks/useAppDispatch";

// Helper Component for Login Component
const LoginHelper = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const initialValue = {
    email: "",
    password: "",
  };

  return {
    navigate,
    initialValue,
    dispatch,
  };
};

export default LoginHelper;
