import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";

import { commonTableStyles as styles } from "../styles";
import { Paths } from "../../../common/constants";
import ContainedButton from "../../button/contained-button";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { getAgreementDetails } from "../../../pages/fo.property/services/action";
import SimpleModal from "../../modal";
import AgreementDetailModal from "../../modal/agreementDetailModal";

/**
 * Component for rendering the "My Sell Property Listing" table.
 *
 * @param {object} listingData - The data for the table.
 * @returns {JSX.Element} MySellPropertyListing component.
 */
const MySellPropertyListing = ({ listingData }: any) => {
  const dispatch = useAppDispatch();

  const [openAgreementModal, setOpenAgreementModal] = useState(false);

  /**
   * Handle opening the agreement modal.
   */
  const handleOpenAgreementModal = () => {
    setOpenAgreementModal(true);
  };

  /**
   * Handle closing the agreement modal.
   */
  const handleCloseAgreementModal = () => {
    setOpenAgreementModal(false);
  };

  /**
   * Handle click on "View Agreement" button.
   *
   * @param {string} agreementAddress - The agreement address.
   */
  const handleViewAgreementClick = (agreementAddress: string) => {
    dispatch(
      getAgreementDetails(agreementAddress, () => {
        handleOpenAgreementModal();
      })
    );
  };

  return (
    <TableContainer>
      <Table sx={styles.table} stickyHeader aria-label="customized table">
        <TableHead sx={styles.tableHeader}>
          <TableRow sx={styles.headerRow}>
            <TableCell>Property Id</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Address</TableCell>
            <TableCell>Symbol</TableCell>
            <TableCell>Token Quantity</TableCell>
            <TableCell>Created At</TableCell>
            <TableCell>Wallet Address</TableCell>
            <TableCell>Token Contract Address</TableCell>
            <TableCell>Token Value</TableCell>
            <TableCell>Left Number Of Token To Sell</TableCell>
            <TableCell>Total Number Of Token To Sell</TableCell>
            <TableCell>Self Token Left To Sell</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {listingData && listingData.length > 0 ? (
            listingData.map((data: any) => (
              <TableRow key={data._id} sx={styles.row}>
                <TableCell>
                  <Link
                    style={styles.link}
                    to={`${Paths.PROPERTY_DETAIL}?id=${data._id}`}
                  >
                    <Typography>{data.propertyId}</Typography>
                  </Link>
                </TableCell>

                <TableCell>
                  <Typography>{data?.properties?.name}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{data?.properties?.address}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{data?.properties?.symbol}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{data?.properties?.mintedToken}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>
                    {moment(data?.properties?.createdAt).format("YYYY-MM-DD")}
                  </Typography>
                </TableCell>
                <TableCell sx={styles.tableCell}>
                  <Typography>{data?.properties?.walletAddress}</Typography>
                </TableCell>
                <TableCell sx={styles.tableCell}>
                  <Typography>
                    {data?.properties?.tokenHolderAddress}
                  </Typography>
                </TableCell>

                <TableCell>
                  <Typography>{data.tokenValue}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{data.leftNoOfTokenToSell}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{data.noOfTokenToSell}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>
                    {data.properties.tokenLeftTosellByOwner}
                  </Typography>
                </TableCell>
                <TableCell>
                  <ContainedButton
                    title="View Agreement"
                    onClick={() =>
                      handleViewAgreementClick(data.agreementAddress)
                    }
                  />
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={14} align="center">
                <h3>No Records</h3>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>

      <SimpleModal
        isOpen={openAgreementModal}
        handleClose={handleCloseAgreementModal}
        children={
          <AgreementDetailModal closeModal={handleCloseAgreementModal} />
        }
      />
    </TableContainer>
  );
};

export default MySellPropertyListing;
