import { Box, Button } from "@mui/material";
import { useSelector } from "react-redux";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";

import PdfDocument from "../invoice";
import { RootState } from "../../redux/store";
import { styles } from "./styles";
import { ChangeEvent } from "react";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { uploadFile } from "../../common/utils";
import Upload from "../upload";
import { setSellPropertyPDFLink } from "../../pages/fo.property/services/slice";
import {
  confirmSignTransaction,
  createSellOrder,
  getPropertyDetails,
} from "../../pages/fo.property/services/action";
import { useAppSelector } from "../../hooks/useAppSelector";
import { useLocation } from "react-router-dom";

/**
 * Component for rendering the SellPropertyCertModal.
 *
 * @param {object} details - The details of the property.
 * @param {function} closeModal - The function to close the modal.
 * @param {function} handleOpenPaymentModal - The function to open the payment modal.
 * @returns {JSX.Element} SellPropertyCertModal component.
 */
const SellPropertyCertModal = ({ details, closeModal }: any) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const id = location.search.split("=")[1];
  const { sellTokenInfo, propertyDetails: PD } = useSelector(
    (state: RootState) => state.property
  );

  const { userDetails } = useAppSelector((state: RootState) => state.login);

  const handleClick = () => {
    const walletId = userDetails?.walletDetails?.cryptoWalletId;
    const network = "polygonMumbai";

    const dataToSend = {
      ...sellTokenInfo,
      identityId: PD.identityId,
      sellerAddress: PD.walletAddress,
      targetAddress: PD.tokenHolderAddress,
      propertyId: PD._id,
    };
    dispatch(
      confirmSignTransaction(walletId, network, (data: any) => {
        dispatch(
          createSellOrder(dataToSend, data, () => {
            closeModal();
            dispatch(getPropertyDetails(id));
          })
        );
      })
    );
  };

  return (
    <Box sx={{ ...styles.modalStyle, minWidth: "800px", minHeight: "600px" }}>
      <Box sx={styles.modalContentStyling}>
        <PDFViewer width={700} height={500} showToolbar={false}>
          <PdfDocument details={details} invoiceData={sellTokenInfo} />
        </PDFViewer>

        <Box sx={{ ...styles.btnContainer, marginBottom: 0 }}>
          <Button sx={styles.certBtn}>
            <PDFDownloadLink
              document={
                <PdfDocument details={details} invoiceData={sellTokenInfo} />
              }
              fileName={"certificate.pdf"}
            >
              {({ blob, url, loading, error }) =>
                loading ? "Loading..." : "Download Invoice"
              }
            </PDFDownloadLink>
          </Button>
          <Button>
            <Upload
              name={"pdf"}
              accept={"application/pdf"}
              handleImageChange={(evt: ChangeEvent<HTMLInputElement>) => {
                if (evt?.target?.files && evt.target.files.length > 0) {
                  dispatch(
                    uploadFile(evt.target.files[0], (data: any) => {
                      dispatch(setSellPropertyPDFLink(data.s3Url));
                    })
                  );
                }
              }}
              imageName={"Upload Certificate"}
            />
          </Button>
          <Button sx={styles.certBtn} onClick={handleClick}>
            Sign Transaction
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default SellPropertyCertModal;
