import { Box, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";

import Schema from "../../common/constants/schema";
import Input from "../formControls/input";
import { styles } from "./styles";
import ContainedButton from "../button/contained-button";
import OutlinedButton from "../button/outlined-button";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { addPayment } from "../../pages/fund.card/services/action";
import { Paths } from "../../common/constants";
import SelectComp from "../formControls/select/singleSelect";


const TransferSelfCard = ({ optionListing, CId, closeModal }: any) => {
  const initialValues = {
    selectedId: "",
    funds: "",
    comment: "",
  };

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return (
    <Box sx={styles.modalStyle}>
      <Box>
        <Typography sx={styles.modalHeading}>
          Transfer To Self Plaid Account
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={Schema.TransferSchema}
          onSubmit={(values, { setSubmitting }) => {
            dispatch(
              addPayment({
                values,
                setSubmitting,
                SCAID: CId,
                DEAID: values.selectedId,
                callback: () => {
                  closeModal();
                  navigate(
                    `${Paths.FORTRESS_CUSTODIAL_ACCOUNT_DETAIL}?id=${CId}`
                  );
                },
              })
            );
          }}
        >
          {(props) => {
            return (
              <Form>
                <Box sx={styles.formContainer}>
                  <SelectComp
                    name={"selectedId"}
                    label="Select Self Plaid Account"
                    fullWidth
                    options={optionListing}
                    labelKey="externalAccountId"
                    valKey="externalAccountId"
                    externalStyles={styles.textField}
                  />
                  <Input
                    name="funds"
                    type="text"
                    label="Amount"
                    placeholder="Amount"
                    fullWidth
                    isRequired
                    externalStyles={styles.textField}
                  />
                  <Input
                    name="comment"
                    type="text"
                    label="Comment"
                    placeholder="Comment"
                    fullWidth
                    isRequired
                    externalStyles={styles.textField}
                    multiline
                  />
                </Box>
                <Box sx={styles.btnContainer}>
                  <ContainedButton
                    title={"Save"}
                    externalStyles={styles.btn}
                    size="large"
                    type="submit"
                    fullWidth
                    primary
                  />
                  <OutlinedButton
                    title="Cancel"
                    externalStyles={styles.btn}
                    size="large"
                    fullWidth
                    onClick={() => {
                      closeModal();
                    }}
                  />
                </Box>
              </Form>
            );
          }}
        </Formik>
      </Box>
    </Box>
  );
};

export default TransferSelfCard;
