import { COLORS } from "../../../common/constants/colors";
import { FontFamily } from "../../../common/utils";

const styles = {
  mainContainer: {
    minHeight: "100vh",
    height: "100%",
    backgroundColor: "rgb(245 247 253)",
    padding: "1rem 2rem",
  },
  btn: {
    margin: "1rem",
    padding: "20px",
    font: FontFamily(700, 18, 20),
  },
  heading: {
    font: FontFamily(700, 24, 22),
    textAlign: "left",
    width: "95%",
    margin: "0 auto 1rem",
  },
  fortressContentContainer: {
    width: "85%",
    margin: "0 auto",
    backgroundColor: COLORS.white,
    border: `1px solid #F4F6FA`,
    borderRadius: "0.556vw",
    boxShadow: "0 0.278vw 0.417vw rgba(0, 0, 0, 0.02)",
    padding: "1.042vw",
    boxSizing: "border-box",
    position: "relative",
  },
  accountSummary_mainContainer: {
    margin: "0 auto",
    width: "85%",
    position: "relative",
  },
  accountSummary_upperCardContainer: {
    backgroundColor: COLORS.white,
    border: `1px solid #F4F6FA`,
    borderRadius: "0.556vw",
    boxShadow: "0 0.278vw 0.617vw rgba(0, 0, 0, 0.02)",
    padding: "1.042vw",
    margin: "2rem auto",
    width: "100%",
    boxSizing: "border-box",
  },
  accountSummary_cardContentContainer: {
    width: "100%",
    display: "flex",
  },
  accountDetailContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: "20px",
  },
  accountSummary_detailsContainer: {
    padding: "1rem 0 0",
    display: "grid",
    gridTemplateColumns: "30% 30% 30%",
    gap: "1rem",
    width: "100%",
  },
  accountName: {
    fontSize: "24px",
    fontWeight: 600,
  },
  accountStatus: {
    textTransform: "capitalize",
    borderRadius: "0.278vw",
    display: "flex",
    alignItems: "center",
    padding: "5px",
    marginLeft: "1rem",
  },
  textContainer: {
    flexBasis: "25%",
    marginBottom: "1rem",
  },
  textHeading: {
    fontSize: "20px",
    fontWeight: 550,
  },
  pending: {
    backgroundColor: COLORS.orangeBg,
    color: COLORS.orange,
    border: `1px solid ${COLORS.orangeBg}`,
  },
  active: {
    backgroundColor: COLORS.greenBg,
    color: COLORS.green,
    border: `1px solid ${COLORS.greenBg}`,
  },
  active_arrowContainer: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
  },

  iconContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },

  activeIcons: {
    backgroundColor: COLORS.primaryBlue,
    padding: "1rem",
    borderRadius: "50%",
    display: "flex",
    // justifyContent: "center",
    alignItems: "center",
    marginBottom: "6px",
    cursor: "pointer",

    "& svg": {
      fill: COLORS.white,
      fontSize: "1.7rem",
    },
  },
  accountSummary_btn: {
    font: "normal normal 700 14px/16px SofiaPro",
    width: "auto",
    marginRight: "20px",
    borderRadius: "4px",
    padding: "15px",
  },
  walletBtnContainer: {
    width: "100%%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  headingContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  docHeading: {
    font: FontFamily(700, 20, 22),
    paddingTop: "20px",
  },
  walletBtn: {
    margin: "1rem",
    padding: "16px",
    font: FontFamily(700, 16, 18),
  },
  btnContainer: {
    position: "absolute",
    top: "1%",
    right: "0",
  },
  walletHeadingContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  addressHeading: {
    font: FontFamily(700, 18, 20),
  },

  propertyListingContainer: {},
  checkBalContainer: {
    minHeight: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",

    "& p": {
      font: FontFamily(700, 22, 30),
    },
  },

  tabs: {
    margin: "10px 0 20px 0",
    "& .Mui-selected": {
      color: `${COLORS.primary} !important`,
      fontWeight: 700,
    },
    "& .MuiTabs-indicator": {
      height: "4px",
      borderRadius: "2px",
      backgroundColor: COLORS.primary,
    },
  },
  tab: { width: "220px" },

  tabContentContainer: {
    "& > div": {
      margin: "0 auto",
    },

    "& table": {
      width: "100%",
    },
  },

  cryptoHeadingContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    "& button": {
      minWidth: "220px",
      padding: "1rem",
      borderRadius: "5px",
    },
  },
};

export default styles;
