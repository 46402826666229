import { Box, Typography } from "@mui/material";
import { Form, Formik } from "formik";

import Input from "../../formControls/input";
import ContainedButton from "../../button/contained-button";
import OutlinedButton from "../../button/outlined-button";
import styles from "./styles";

import AddPropertyhelper from "./helper";
import Upload from "../../upload";
import { ChangeEvent } from "react";
import { addProperty } from "../../../pages/fo.property/services/action";
import SelectComp from "../../formControls/select/singleSelect";
import { showAlert } from "../../../common/utils";

/**
 * Renders a Add Property Form for adding property.
 * @returns {React.ReactNode} The rendered component.
 */
const AddPropertyForm = ({ address, id }: any) => {
  const { navigate, initialValues, uploadImage, dispatch } =
    AddPropertyhelper();

  return (
    <Box sx={styles.addForm_mainContainer}>
      <Formik
        initialValues={initialValues}
        // validationSchema={Schema.AddCardSchema}
        onSubmit={(values) => {
          const filterAddress = address.filter(
            (add: any) => add.network === values.wallet_address
          );
          const newValues = values;
          values["wallet_address"] = filterAddress[0].address;

          dispatch(addProperty(newValues, id, () => navigate(-1)));
        }}
      >
        {({ setFieldValue, values }) => {
          return (
            <Form>
              <Typography sx={styles.heading}>Add Property</Typography>
              <Box sx={styles.formContainer}>
                <Input
                  name="propertyName"
                  type="text"
                  label="Property Name"
                  placeholder="Property Name"
                  fullWidth
                  isRequired
                  externalStyles={styles.textField}
                />
                <Input
                  name="tokenValue"
                  type="text"
                  label="Number of Tokens"
                  placeholder="Number of Tokens"
                  fullWidth
                  isRequired
                  externalStyles={styles.textField}
                />
                <Input
                  name="symbol"
                  type="text"
                  label="Symbol"
                  placeholder="Symbol"
                  fullWidth
                  isRequired
                  externalStyles={styles.textField}
                />
                <Input
                  name="address"
                  type="text"
                  label="Address"
                  placeholder="Address"
                  fullWidth
                  isRequired
                  externalStyles={styles.textField}
                />
                <SelectComp
                  name={"wallet_address"}
                  label="Wallet Address"
                  fullWidth
                  options={address}
                  labelKey="network"
                  valKey="network"
                  externalStyles={styles.textField}
                  onChange={(evt) => {
                    if (evt.target.value !== "polygonMumbai") {
                      showAlert(2, "Please use Polygon Mumbai Asset.");
                      setFieldValue("wallet_address", "");
                      return;
                    }
                    setFieldValue("wallet_address", evt.target.value);
                  }}
                />

                <Box sx={styles.uploadBox}>
                  <Upload
                    name={"fileURL"}
                    accept={"image/png, application/pdf, image/jpg, image/jpeg"}
                    handleImageChange={(evt: ChangeEvent<HTMLInputElement>) => {
                      uploadImage(evt, setFieldValue);
                    }}
                    imageName={
                      values.fileURL ? "Upload Again" : "Upload Document"
                    }
                  />
                </Box>
              </Box>
              <Box sx={styles.btnContainer}>
                <ContainedButton
                  title={"Save"}
                  externalStyles={styles.btn}
                  size="large"
                  type="submit"
                  fullWidth
                />
                <OutlinedButton
                  title="Cancel"
                  externalStyles={styles.btn}
                  size="large"
                  fullWidth
                  onClick={() => {
                    navigate(-1);
                  }}
                />
              </Box>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default AddPropertyForm;
