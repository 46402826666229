import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import { RootState } from "../../redux/store";
import { getProfile } from "../login/services/action";
import { getPersonalContainerFortress } from "../fo.forms/services/action";
import { getAllIdentities, getBusinessIdentities } from "./services/action";
import { AssetsOnSellListing } from "../fo.property/services/action";

// Helper Component for Dashboard Component
export const useDashboardHelper = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [type, setType] = useState("1");
  const { userDetails } = useAppSelector((state: RootState) => state.login);
  const { identityContainerId } = userDetails;
  const { personalDetails } = useAppSelector(
    (state: RootState) => state.personal
  );
  const { identitiesListing, walletListing } = useAppSelector(
    (state: RootState) => state.dashboard
  );
  const handleTypeChange = (_: any, newValue: string) => {
    setType(newValue);
  };

  useEffect(() => {
    dispatch(getProfile());
    dispatch(getAllIdentities());
    dispatch(getBusinessIdentities());
    dispatch(AssetsOnSellListing());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (identityContainerId) dispatch(getPersonalContainerFortress());
  }, [identityContainerId]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    type,
    handleTypeChange,
    navigate,
    dispatch,
    userDetails,
    personalDetails,
    identitiesListing,
    walletListing,
  };
};
