import { Box, Typography } from "@mui/material";

import TransactionDetailHelper from "./helper";
import Cards from "../../../components/card";
import styles from "../styles";
import moment from "moment";

/**
 * Defines Transaction Details Component
 * @returns The Transaction Details Component
 */
const TransactionDetails = () => {
  const { transactionDetails: TD } = TransactionDetailHelper();

  return (
    <Box sx={styles.accountSummary_mainContainer}>
      <Cards
        childComp={
          <>
            <Typography sx={styles.CD_heading}>Transaction Detail</Typography>
            <Box sx={styles.accountSummary_cardContentContainer}>
              <Box
                sx={{
                  ...styles.transaction_detailsContainer,
                }}
              >
                <Box sx={styles.textContainer}>
                  <Typography sx={styles.textHeading}>Payment ID</Typography>
                  <Typography>{TD.paymentId}</Typography>
                </Box>

                <Box sx={styles.textContainer}>
                  <Typography sx={styles.textHeading}>Payment Type</Typography>
                  <Typography>{TD.paymentType}</Typography>
                </Box>

                <Box sx={styles.textContainer}>
                  <Typography sx={styles.textHeading}>Currency</Typography>
                  <Typography>{TD.currency}</Typography>
                </Box>

                <Box sx={styles.textContainer}>
                  <Typography sx={styles.textHeading}>Source</Typography>
                  <Typography>{TD.source.externalAccountId}</Typography>
                </Box>

                <Box sx={styles.textContainer}>
                  <Typography sx={styles.textHeading}>Destination</Typography>
                  <Typography>{TD.destination.custodialAccountId}</Typography>
                </Box>

                <Box sx={styles.textContainer}>
                  <Typography sx={styles.textHeading}>Amount</Typography>
                  <Typography>{TD.amount}</Typography>
                </Box>

                <Box sx={styles.textContainer}>
                  <Typography sx={styles.textHeading}>Status</Typography>
                  <Typography>{TD.status}</Typography>
                </Box>

                <Box sx={styles.textContainer}>
                  <Typography sx={styles.textHeading}>Created At</Typography>
                  <Typography>
                    {moment(TD.createdAt).format("YYYY-MM-DD")}
                  </Typography>
                </Box>

                <Box sx={styles.textContainer}>
                  <Typography sx={styles.textHeading}>Comment</Typography>
                  <Typography>{TD.comment}</Typography>
                </Box>

                <Box sx={styles.textContainer}>
                  <Typography sx={styles.textHeading}>Description</Typography>
                  <Typography>{TD.description}</Typography>
                </Box>
              </Box>
            </Box>
          </>
        }
      />
    </Box>
  );
};

export default TransactionDetails;
