import { COLORS } from "../../common/constants/colors";
import { FontFamily } from "../../common/utils/common.fns";

const styles = {
  mainContainer: {
    height: "100%",
    backgroundColor: "#FFFFFF",
    padding: "1rem 2rem",
    width: "80%",
    margin: "10px auto",
  },
  formContainer: {
    display: "grid",
    gridTemplateColumns: "25% 25% 25% 25%",
    columnGap: "25px",
  },
  textField: {
    margin: "10px 0",
    "& .MuiTypography-h6": {
      textAlign: "left",
      marginBottom: "5px",
    },
  },
  btn: {
    width: "20%",
    margin: "0 1rem",
    backgroundColor: COLORS.primary,
    color: COLORS.white,
    padding: "12px",
    font: "normal normal 700 20px/25px SofiaPro",
    "&:hover": {
      backgroundColor: COLORS.hoverOver,
      color: COLORS.white,
    },
  },
  btnContainer: {
    width: "100%%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  headingContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  heading: {
    font: FontFamily(700, 20, 22),
    paddingTop: "15px",
  },

  //Account Summary Styling
  accountSummary_mainContainer: {
    margin: "2rem auto",
    width: "80%",
    minHeight: "100vh",
  },
  accountSummary_upperCardContainer: {
    border: `1px solid ${COLORS.borderGrey}`,
    borderRadius: "0.556vw",
    boxShadow: "0 0.278vw 0.417vw rgba(0, 0, 0, 0.02)",
    padding: "1.389vw 1.042vw 0 1.042vw",
    margin: "50px auto",
  },
  accountSummary_cardContentContainer: {
    width: "100%",
    display: "flex",
  },
  accountDetailContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: "20px",
  },
  accountSummary_detailsContainer: {
    padding: "1rem 0",
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },

  CD_detailsContainer: {
    width: "100%",
    display: "grid",
    gridTemplateColumns: "50% 50%",
    gap: "1rem",
  },

  CD_heading: {
    font: FontFamily(700, 26, 30),
    letterSpacing: "1.5px",
    marginBottom: "1rem",
  },
  accountName: {
    fontSize: "24px",
    fontWeight: 600,
  },
  accountStatus: {
    textTransform: "capitalize",
    borderRadius: "0.278vw",
    display: "flex",
    alignItems: "center",
    padding: "5px",
    marginLeft: "1rem",
  },
  textContainer: {
    flexBasis: "25%",
    marginBottom: "1rem",
  },
  textHeading: {
    fontSize: "20px",
    fontWeight: 550,
  },
  pending: {
    backgroundColor: COLORS.orangeBg,
    color: COLORS.orange,
    border: `1px solid ${COLORS.orangeBg}`,
  },
  active: {
    backgroundColor: COLORS.greenBg,
    color: COLORS.green,
    border: `1px solid ${COLORS.greenBg}`,
  },
  active_arrowContainer: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
  },

  iconContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },

  activeIcons: {
    backgroundColor: COLORS.primaryBlue,
    padding: "1rem",
    borderRadius: "50%",
    display: "flex",
    // justifyContent: "center",
    alignItems: "center",
    marginBottom: "6px",
    cursor: "pointer",

    "& svg": {
      fill: COLORS.white,
      fontSize: "1.7rem",
    },
  },
  accountSummary_btn: {
    font: "normal normal 700 14px/16px SofiaPro",
    width: "auto",
    marginRight: "20px",
    borderRadius: "4px",
    padding: "15px",
  },

  //Account Detail
  accountDetail_btnContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",

    "& button": {
      width: "auto",
      minWidth: "220px",
    },
  },

  //Account Detail Tab
  tabs: {
    margin: "10px 0 20px 0",
    "& .Mui-selected": {
      color: `${COLORS.primary} !important`,
      fontWeight: 700,
    },
    "& .MuiTabs-indicator": {
      height: "4px",
      borderRadius: "2px",
      backgroundColor: COLORS.primary,
    },
  },
  tab: { width: "200px" },

  popoverContainer: {
    "& .css-3bmhjh-MuiPaper-root-MuiPopover-paper": {
      minWidth: "220px",
    },

    "& a": {
      textDecoration: "none",
      color: COLORS.primary,
    },
  },

  popoverText: {
    padding: "1rem",
    cursor: "pointer",
    color: COLORS.primary,
  },

  //transaction
  transaction_mainContainer: {
    padding: "1rem",

    "& table": {
      width: "100%",
      margin: "1rem auto",
    },
  },

  transaction_detailsContainer: {
    width: "100%",
    display: "grid",
    gridTemplateColumns: "33% 33% 33%",
    gap: "1rem",
  },

  checkBalContainer: {
    minHeight: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",

    "& p": {
      font: FontFamily(700, 22, 30),
    },
  },
};

export default styles;
