import { COLORS } from "../../common/constants";
import { FontFamily } from "../../common/utils";

const styles = {
  dashboard_mainContainer: {
    borderRadius: "6px",
    height: "100%",
    margin: "30px auto 0",
    minHeight: "85vh",
    padding: "15px",
    backgroundColor: COLORS.white,
    boxShadow: ` 0px 4px 32px rgba(0, 0, 0, 0.08)`,
    width: "95%",
  },
  tabs: {
    margin: "10px 0 20px 0",
    "& .Mui-selected": {
      color: `${COLORS.primary} !important`,
      fontWeight: 700,
    },
    "& .MuiTabs-indicator": {
      height: "4px",
      borderRadius: "2px",
      backgroundColor: COLORS.primary,
    },
  },
  tab: { width: "200px" },
  heading: {
    font: FontFamily(700, 24, 22),
    paddingTop: "15px",
    textAlign: "left",
    width: "95%",
    margin: "0 auto 1rem",
  },

  personalIdentityContainer: {
    "& > div": {
      width: "90%",

      "& > div": {
        padding: "1.5vw 2vw",
      },
    },
  },

  businessIdentityContainer: {
    "& button": {
      position: "absolute",
      top: "-4%",
      right: "2%",
      font: FontFamily(700, 18, 23),
      padding: "15px",
      margin: "10px 0",
    },
  },

  textContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    marginBottom: "1rem",
  },

  textHeading: {
    marginRight: "1rem",
    fontSize: "20px",
    fontWeight: 550,
  },

  textData: {
    fontSize: "18px",
    textTransform: "Capitalize",
  },

  docHeading: {
    fontSize: "24px",
    fontWeight: 600,
    marginBottom: "1rem",
  },

  docContainer: {
    display: "grid",
    gridTemplateColumns: "50% 50%",
    gap: "1rem",
  },

  KYCBtn: {
    position: "absolute",
    top: "1%",
    right: 0,
    padding: "1rem",
    font: FontFamily(700, 18, 20),
  },
  btn: {
    font: FontFamily(700, 18, 23),
    padding: "15px",
    margin: "10px 0",
  },
};

export default styles;
