import { Box } from "@mui/material";

import AddCustodialAccount from "../../components/form/custodial-account";
import styles from "./styles";
import PrimeTrustFormsHelper from "./helper";

/**
 * Defines Prime Trust Forms Page
 * @returns The Prime Trust Forms Component
 */
const PrimeTrustForms = () => {
  PrimeTrustFormsHelper();
  return (
    <Box sx={styles.mainContainer}>
      <Box>
        <Box>{/* <GeneralTabs tabItems={tabUserList} /> */}</Box>
        <Box>
          <AddCustodialAccount />
        </Box>
      </Box>
    </Box>
  );
};

export default PrimeTrustForms;
