import { COLORS } from "../../../common/constants/colors";
import { FontFamily } from "../../../common/utils";

const styles = {
  addForm_mainContainer: {
    width: "90%",
    margin: "1rem auto",
    minHeight: "100vh",
  },
  formContainer: {
    display: "grid",
    gridTemplateColumns: "48% 48%",
    columnGap: "50px",
  },
  textField: {
    margin: "10px 0",
    "& .MuiTypography-h6": {
      textAlign: "left",
      marginBottom: "5px",
    },
  },
  btn: {
    width: "20%",
    margin: "0 1rem",
    backgroundColor: COLORS.primary,
    color: COLORS.white,
    padding: "12px",
    font: FontFamily(700, 20, 25),
    "&:hover": {
      backgroundColor: COLORS.hoverOver,
      color: COLORS.white,
    },
  },
  btnContainer: {
    width: "100%%",
    margin: "2rem auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  heading: {
    font: FontFamily(700, 25, 30),
    padding: "10px 0 0 0",
  },
};

export default styles;
