/**
 * API endpoint paths.
 */
export const EndPoint = {
  /** Onboarding */
  login: "/user/login",
  signup: "/user/signup",
  verifyOTP: "/user/verify-otp",
  resendOTP: "/user/resend-otp",
  forgetPassword: "/user/forget-password",
  resetPassword: "/user/reset-password",
  getProfile: "/user/get-profile",

  /** Prime trust */
  custodialAccount: "/user/custodialAccount",
  JwtTokenClient: "/user/authToken",
  ImageUpload: "/v2/uploaded-documents",
  ImageUploadLocally: "/user/updateDocDetails",
  SaveKYCDocs: "/user/kycDocChecks",

  /** Fortress */
  GetBusinessID: "/user/business-identity",
  custodialAccountFortress: "/user/create-identity",
  AllIdentities: "/user/personal/identities",
  OAuthTokenClientFortress: "/user/OauthToken",
  GetIdentityContainer: "/user/identity-container",
  GetPersonalContainer: "/user/personal-identity",
  GetBusinessContainer: "/user/get/business-identity",
  FortressPersonalImageUpload: "/api/trust/v1/personal-identities",
  FortressBusinessImageUpload: "/api/trust/v1/business-identities",
  FortressCustodialAccount: "/user/custodial-account",
  FortressGetCustodialAccountListing: "/user/custodial-accounts-list",
  uploadFile: "/user/upload-file",
  FortressGetBusinessCustodialAccountListing: "/user/get-accounts",
  FortressBusinessFileUpload: "/user/business-file-upload",
  GetBusinessIdentities: "/user/business-identities",
  GetAccountBalance: "/user/custodial-account-balance",

  /** Wallet */
  cryptoWallet: "/user/crypto-wallet",
  walletListing: "/user/wallets",
  walletAddresses: "/user/wallet-addresses",
  GetWalletBalance: "/user/wallet-balance",

  /** External Accounts */
  GetExternalAccounts: "/user/external-accounts",
  GetExternalAccountsTypes: "/user/external-accounts-types",
  GetExternalAccount: "/user/external-account",
  AddExternalAccountCard: "/user/external-account/card",
  CreatePlaidExternalAccount: "/user/createPlaidExternalAcc",
  CreateLinkToken: "/user/link/token/create",
  PublicTokenExchange: "/user/public/token/exchange",
  CreateProcessorToken: "/user/processor/token/create",

  /** Payment */
  Payment: "/user/payments",

  /** Transaction */
  GetTransactionListing: "/user/transactions",
  GetTransactionDetail: "/user/transaction",

  /** Property */
  GetAllProperties: "/user/all-properties",
  AddProperty: "/user/property",
  GetBalance: "/user/get-balance",
  GetAssetsOnSellListing: "/user/sell-order-listing",
  GetAssetsOnSellDetails: "/user/sell-order-details",
  GetPropertyDetails: "/user/property-details",
  CreateSellOrder: "/user/create-sell-order",
  GetMySellOrderListing: "/user/my-sell-order-listing",
  GetMyBuyOrderListing: "/user/my-buy-order-listing",
  CreateBuyOrder: "/user/create-buy-order",
  GetExchangeAmount: "/user/get-exchange-amount",
  RetrieveAddress: "/user/retrieve-address",
  SendCrypto: "/user/send-crypto",
  SellCrypto: "/user/sell-crypto",
  GetMyBuyTransactionListing: "/user/transactions/status",
  LockToken: "/user/lock-token",
  RevertToken: "/user/revert-token",
  signTransaction: "/user/sign-message",
  getAgreementDetail: "/user/getAgreementDetail",

  /** S3 */
  S3upload: "/user/upload-file-to-s3",
  // Add more endpoints here if needed
};
