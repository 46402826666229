import { COLORS } from "../../common/constants";

const styles = {
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  message: {
    font: "normal normal 500 18px/22px SofiaPro",
    marginBottom: "33px",
    maxWidth: "100%",
    textAlign: "center",
  },
  paper: {
    position: "absolute",
    backgroundColor: COLORS.white,
    padding: "5% 3% 5%",
    minWidth: "25%",
    maxWidth: "35%",
  },
  okButton: {
    margin: "14px 15px 0",
  },
  logo: {
    height: "80px",
    width: "80px",
    marginBottom: "42px",
    color: COLORS.primary,
  },
  subMessage: {
    font: "normal normal 500 16px/26px SofiaPro",
    color: `${COLORS.black}8F`,
    marginBottom: "48px",
    textAlign: "center",
  },
  btnContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "57%",
  },
  themeBtn: {
    font: "normal normal 700 18px/23px SofiaPro",
    width: "130px",
    padding: "8px 15px",
  },
  grayBtn: {
    font: "normal normal 700 18px/23px SofiaPro",
    width: "130px",
    padding: "8px 15px",
  },
  okPopUpButton: {
    width: "25%",
    minWidth: "80px",
  },
  heading: {
    font: "normal normal 700 26px/28px SofiaPro",
    marginBottom: "20px",
  },
  yesCancelGrayBtn: {
    font: "normal normal 700 18px/23px SofiaPro",
    width: "130px",
    padding: "13px",
    marginRight: "20px",
  },
  yesCancelthemeBtn: {
    font: "normal normal 700 18px/23px SofiaPro",
    width: "130px",
    padding: "13px",
  },
  margin: {
    marginRight: "10px",
  },
};

export default styles;
