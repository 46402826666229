import { Typography, Box } from "@mui/material";
import React, { ChangeEvent } from "react";
import styles from "./styles";

interface Props {
  handleImageChange: (evt: ChangeEvent<HTMLInputElement>) => void;
  accept: string;
  imageName: string;
  name: string;
  reset?: boolean;
}

/**
 * Upload component for selecting and uploading an image.
 *
 * @param {Props} props - Component properties.
 * @param {function} props.handleImageChange - Function to handle the image change event.
 * @param {string} props.accept - File types accepted by the upload component.
 * @param {string} props.imageName - Name of the selected image.
 * @param {string} props.name - Name of the input field.
 * @param {boolean} [props.reset=false] - Flag indicating if the file input should be reset when clicked.
 * @returns {JSX.Element} Upload component.
 */
const Upload: React.FC<Props> = ({
  handleImageChange,
  accept,
  imageName,
  name,
  reset = false,
}) => {
  const fileRef = React.useRef<HTMLInputElement>(null);

  /**
   * Handles the image selection event and opens the file dialog.
   */
  const handleImage = () => {
    if (fileRef && fileRef.current) {
      fileRef.current.click();
    }
  };

  return (
    <Box sx={styles.uploadImageContainer}>
      <Box sx={styles.uploadImage} onClick={handleImage}>
        <Typography>{imageName}</Typography>
        <Box className="upload-file">
          <input
            type="file"
            id={name}
            name={name}
            onChange={(evt: ChangeEvent<HTMLInputElement>) => {
              handleImageChange(evt);
            }}
            accept={accept}
            style={styles.inputFile}
            ref={fileRef}
            onClick={() => {
              if (fileRef?.current?.value && reset) {
                fileRef.current.value = "";
              }
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Upload;
