import { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { Form, Formik } from "formik";

import { styles } from "./styles";
import SelectComp from "../../formControls/select/singleSelect";
import ContainedButton from "../../button/contained-button";
import OutlinedButton from "../../button/outlined-button";
import useCreateWalletHelper from "../create-wallet/helper";
import Schema from "../../../common/constants/schema";
import {
  getCryptoWallet,
  walletAddress,
} from "../../../pages/fo.dashboard/services/action";
import { Paths } from "../../../common/constants";
import { showAlert } from "../../../common/utils";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { RootState } from "../../../redux/store";

/**
 * Renders a Wallet Addresses form for creating wallet address.
 * @returns {React.ReactNode} The rendered component.
 */
const WalletAddresses = () => {
  const { assetsOptions, chainOptions, navigate, dispatch, id } =
    useCreateWalletHelper();

  useEffect(() => {
    dispatch(getCryptoWallet(id));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const { walletDetails } = useAppSelector(
    (state: RootState) => state.business
  );

  const initialValues = {
    chain: "",
    assetType: "",
  };

  const isAddressExist = (value: any) => {
    const { addresses } = walletDetails;
    let addressExist = false;
    addresses.forEach((wallet: any) => {
      if (
        wallet.network === value.chain &&
        wallet.assetType === value.assetType
      )
        addressExist = true;
    });

    return addressExist;
  };

  return (
    <Box sx={styles.mainContainer}>
      <Typography sx={styles.heading}>Add Wallet Address</Typography>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={Schema.WalletAddresess}
        onSubmit={(values: any) => {
          if (isAddressExist(values)) {
            showAlert(2, "You cannot add same address again");
          } else {
            dispatch(
              walletAddress(id, values, () =>
                navigate(`${Paths.FORTRESS_WALLET_DETAIL}?id=${id}`)
              )
            );
          }
        }}
      >
        {({ isValid, dirty }) => (
          <Form>
            <Box sx={styles.selectContainer}>
              <SelectComp
                name={"chain"}
                label="Select Chain"
                fullWidth
                options={chainOptions}
                labelKey="name"
                valKey="id"
                externalStyles={styles.selectComp}
              />
              <SelectComp
                name={"assetType"}
                label="Select Asset Type"
                fullWidth
                options={assetsOptions}
                labelKey="name"
                valKey="id"
                externalStyles={styles.selectComp}
              />
            </Box>
            <Box sx={styles.btnContainer}>
              <ContainedButton
                externalStyles={styles.submitButton}
                type="submit"
                title="Save"
                disabled={!(isValid && dirty)}
                primary
              />

              <OutlinedButton
                title="Cancel"
                externalStyles={styles.submitButton}
                onClick={() => navigate(-1)}
                primary
              />
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default WalletAddresses;
