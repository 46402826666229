import { FontFamily } from "../../common/utils";

export const styles = {
  mainContainer: {
    width: "90%",
    margin: "1.5rem auto",
    minHeight: "100vh",
    boxSizing: "border-box",
  },

  btnContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },

  btn: {
    font: FontFamily(700, 18, 23),
    padding: "15px",
    margin: "10px 0",
  },

  listingContainer: {
    marginTop: "1rem",

    "& > p": {
      font: FontFamily(700, 20, 25),
      textAlign: "center",
      margin: "1rem",
    },
  },
};
