import { COLORS } from "../../common/constants/colors";

const styles = {
  formControl: {
    width: "150px",
    marginRight: "10px",
    "& .MuiInputBase-formControl": {
      border: "none",
      color: "#181B20",
      font: "normal normal 500 16px/16px SofiaPro",
      alignItems: "inherit",
      height: "55px",
      margin: "0",
      "& fieldset": {
        borderRadius: "0px",
        borderColor: COLORS.black,
      },
      "&:hover fieldset": {
        borderColor: COLORS.borderGrey,
      },
      "& .MuiSelect-select::placeholder fieldset": {
        borderColor: "#181B20",
      },
      "&.Mui-focused fieldset": {
        borderColor: COLORS.borderGrey,
      },
    },
  },
  select: {
    maxHeight: "160px",
    "& li": {
      font: `normal normal 500 18px/23px SofiaPro`,
      color: "#181B20",
    },
  },
  menutItem: {
    color: "#181B20",
    font: "normal normal 500 16px/16px SofiaPro",
  },
};

export default styles;
