import React from "react";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { ThemeProvider } from "@mui/material/styles";
import { store } from "./redux/store";
import theme from "./common/constants/theme";
import { createRoot } from "react-dom/client";

type ProvidersProps = {
  children: React.ReactNode;
};

// Creates and Wraps the root component (App) with the Redux & Theme Provider
const Providers = ({ children }: ProvidersProps) => {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </Provider>
  );
};

const container = document.getElementById("root") as HTMLDivElement;
const root = createRoot(container);

/**
 * This file serves as the entry point for the React application.
 * It renders the root component (App) into the HTML DOM.
 */

// Render the root component (App) into the 'root' element in the HTML
root.render(
  <React.StrictMode>
    <Providers>
      <App />
    </Providers>
  </React.StrictMode>
);

reportWebVitals();
