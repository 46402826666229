import { COLORS } from "../../../common/constants/colors";
import { FontFamily } from "../../../common/utils/common.fns";

const styles = {
  cardInput: { borderRadius: 10 },
  authInput: {
    "& input": {
      padding: "16.5px 14px",
      color: COLORS.inputHead,
      font: "normal normal 500 16px/16px SofiaPro",
      "&::placeholder": {
        color: "#181B20",
        opacity: "1 !important",
        font: "normal normal 500 16px/16px SofiaPro",
      },
      "&:-internal-autofill-selected": {
        backgroundColor: "transparent",
      },
    },
    "& textarea": {
      color: COLORS.inputHead,
      font: "normal normal 500 16px/16px SofiaPro",
      "&::placeholder": {
        color: "#181B20",
        opacity: "1 !important",
        font: "normal normal 500 16px/16px SofiaPro",
      },
      "&:-internal-autofill-selected": {
        backgroundColor: "transparent",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid rgba(24, 27, 32, 0.57)",
    },
    "& .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
      {
        border: "2px solid rgba(24, 27, 32, 0.57)",
      },
    "& .MuiInputBase-root.MuiOutlinedInput-root.Mui-active .MuiOutlinedInput-notchedOutline":
      {
        border: "2px solid rgba(24, 27, 32, 0.57)",
      },
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
      border: "2px solid #E71515",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "0px",
      font: FontFamily(500, 16, 20),
    },
    "& .MuiFormHelperText-root.Mui-error": {
      margin: "2px",
      letterSpacing: "0",
    },
  },
  filled: {
    "& .MuiInputBase-root.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
      {
        border: "2px solid #2061A8;",
      },
  },
  notFilled: {},
  label: {
    font: "normal normal 500 14px/14px SofiaPro",
    transition: "ease-in 0.5s",
    color: COLORS.inputHead,
  },
  errorTooltip: {
    maxWidth: "250px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    padding: "20px",
    background: COLORS.themeError,
    font: "normal normal 500 16px/16px SofiaPro",
  },
  passwordRow: {
    width: "100%",
    display: "flex",
    alignItems: "center",
  },
  visibilityIcon: { color: COLORS.primary },
  errorIcon: { color: COLORS.themeError },
  none: { visibility: "hidden" },
};

export default styles;
