import { Box, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import { useSelector } from "react-redux";

import Schema from "../../common/constants/schema";
import { styles } from "./styles";
import ContainedButton from "../button/contained-button";
import OutlinedButton from "../button/outlined-button";
import SelectComp from "../formControls/select/singleSelect";
import Input from "../formControls/input";
import { addPayment } from "../../pages/fund.card/services/action";
import {
  RevertToken,
  createBuyProperty,
  sendCrypto,
} from "../../pages/fo.property/services/action";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { RootState } from "../../redux/store";
import { Env, Paths } from "../../common/constants";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import useTimer from "../../hooks/useTimer";

interface PropertyPaymentModalType {
  closeModal: () => void;
  sourceCusID: any;
  DestinationCusID: any;
  address: any;
  details: any;
  exchangeAmount: any;
  noOfToken: any;
  id: string;
}

/**
 * Component for rendering the PropertyPaymentModal.
 */

const PropertyPaymentModal = ({
  closeModal,
  sourceCusID,
  DestinationCusID,
  address,
  details,
  exchangeAmount,
  noOfToken,
  id,
}: PropertyPaymentModalType) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { userDetails } = useSelector((state: RootState) => state.login);

  const [timeState, setTimeState] = useState(0);

  const initialValues = {
    mode: "",
    fund: exchangeAmount.priceInUsd,
    comment: "",
  };
  const { seconds, setSeconds } = useTimer(0, 0);

  // Used to show Timer
  useEffect(() => {
    if (timeState > 0) {
      setSeconds(15);
      setTimeState(0);
    }
  }, [timeState]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleReleaseToken = () => {
    const dataToSend = {
      sellOrderId: details._id,
      noOfTokenToBuy: noOfToken,
    };
    dispatch(RevertToken(dataToSend, () => closeModal()));
  };

  useEffect(() => {
    const modalTimer = setTimeout(() => {
      handleReleaseToken();
    }, 120000);

    return () => clearTimeout(modalTimer);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box sx={styles.modalStyle}>
      <Box>
        {seconds > 0 && (
          <Typography sx={styles.timerContainer}>{seconds}</Typography>
        )}
        <Typography sx={styles.modalHeading}>Amount to be paid :-</Typography>
        <Typography sx={styles.subHeading}>
          In USD : {exchangeAmount?.priceInUsd}
        </Typography>
        <Typography sx={styles.subHeading}>
          In Matic : {exchangeAmount?.priceInCrypto}
        </Typography>

        <Formik
          initialValues={initialValues}
          validationSchema={Schema.FundPaymentSchema()}
          onSubmit={(values, { setSubmitting }) => {
            if (values.mode === "1") {
              const dataToSend = {
                funds: values.fund,
                comment: values.comment,
              };

              dispatch(
                addPayment({
                  values: dataToSend,
                  setSubmitting,
                  SCAID: sourceCusID,
                  DCAID: DestinationCusID,
                  callback: () => {
                    const dataToSendCallBack = {
                      identityId: id,
                      sellerAddress: details?.sellerAddress,
                      targetAddress: details?.targetAddress,
                      buyerAddress: address,
                      sellOrderId: details._id,
                      noOfTokenToBuy: noOfToken,
                      name: details.propertyDetails.name,
                    };
                    dispatch(
                      createBuyProperty(dataToSendCallBack, () => closeModal())
                    );
                  },
                  handleReleaseToken,
                })
              );
            } else if (values.mode === "2") {
              const sendCryptoValues = {
                network: "polygonMumbai",
                data: {
                  addressFrom: address,
                  addressTo: Env.HENRY_MATIC_WALLET_ADDRESS,
                  value: `${exchangeAmount?.priceInCrypto}`,
                  buyerIdentityId: id,
                  buyerUserId: userDetails._id,
                  sellerAccountId: DestinationCusID,
                  sellerIdentityId: details.userDetails.personalIdentity,
                  sellerAddress: details?.sellerAddress,
                  targetAddress: details?.targetAddress,
                  sellerUserId: details.userDetails._id,
                  noOfTokenToBuy: noOfToken,
                  tokenValue: details.tokenValue,
                  sellOrderId: details._id,
                  propertyId: details.propertyDetails._id,
                },
              };

              dispatch(
                sendCrypto(sendCryptoValues, handleReleaseToken, () =>
                  navigate(
                    `${Paths.FORTRESS_WALLET_DETAIL}?id=${userDetails.walletDetails.cryptoWalletId}`
                  )
                )
              );
            }
          }}
        >
          {({ setFieldValue }) => {
            return (
              <Form>
                <Box sx={styles.formContainer}>
                  <SelectComp
                    name="mode"
                    label="Select Mode for Payment"
                    options={[
                      { id: "1", value: "Using USD" },
                      { id: "2", value: "Using Matic" },
                    ]}
                    labelKey="value"
                    valKey="id"
                    fullWidth
                    onChange={(evt: any) => {
                      const { value } = evt?.target;
                      setFieldValue("mode", value);
                      if (value === "2") {
                        setTimeState(15);
                        setTimeout(() => {
                          closeModal();
                        }, 15000);
                      }
                    }}
                  />

                  <Input
                    name="comment"
                    type="text"
                    label="Comment"
                    placeholder="Comment"
                    fullWidth
                    isRequired
                    externalStyles={styles.textField}
                    multiline
                  />
                </Box>

                <Box sx={styles.btnContainer}>
                  <ContainedButton
                    title={"Pay"}
                    externalStyles={styles.btn}
                    size="large"
                    type="submit"
                    fullWidth
                    primary
                  />
                  <OutlinedButton
                    title="Cancel"
                    externalStyles={styles.btn}
                    size="large"
                    fullWidth
                    onClick={() => handleReleaseToken()}
                  />
                </Box>
              </Form>
            );
          }}
        </Formik>
      </Box>
    </Box>
  );
};

export default PropertyPaymentModal;
