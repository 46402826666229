import { COLORS } from "../../common/constants";

const styles = {
  header_mainContainer: {
    width: "100%",
    backgroundColor: COLORS.primary,
    height: "60px",
    color: COLORS.white,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  btn: {
    font: "normal normal 700 18px/23px SofiaPro",
    padding: "7px",
    width: "125px",
    backgroundColor: COLORS.white,
    marginRight: "20px",
    borderRadius: "4px",
  },
};

export default styles;
