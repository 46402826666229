import { Typography, Box } from "@mui/material";
import { FieldArray, Form, Formik } from "formik";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

import ContainedButton from "../../button/contained-button";
import Input from "../../formControls/input";
import SelectComp from "../../formControls/select/singleSelect";
import { styles } from "./styles";
import useCreateWalletHelper from "./helper";
import Schema from "../../../common/constants/schema";
import { createCryptoWallet } from "../../../pages/fo.dashboard/services/action";
import { Paths } from "../../../common/constants";
import OutlinedButton from "../../button/outlined-button";

/**
 * Renders a Create Wallet Form for creating wallet.
 * @returns {React.ReactNode} The rendered component.
 */
export function CreateWallet() {
  const {
    initialValues,
    initialAssetsValue,
    chainOptions,
    assetsOptions,
    id,
    type,
    dispatch,
    navigate,
  } = useCreateWalletHelper();

  const walletType = type === "business" ? "2" : "1";
  const pathToNavigate =
    type === "business"
      ? `${Paths.FORTRESS_BUSINESS_WALLETS}?id=${id}`
      : Paths.FORTRESS_WALLETS;

  return (
    <Box sx={styles.mainContainer}>
      <Typography sx={styles.heading}>Create Your Wallet</Typography>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={Schema.CreateWalletSchema}
        onSubmit={(values) => {
          dispatch(
            createCryptoWallet(
              { ...values, identityId: id, walletType },
              () => {
                navigate(pathToNavigate);
              }
            )
          );
        }}
      >
        {({ values, isValid, dirty }) => (
          <Form>
            <>
              <Input
                name="walletName"
                type="text"
                label="Wallet Name"
                placeholder="Wallet Name"
                fullWidth
                externalStyles={styles.selectComp}
              />
              <Typography sx={styles.heading}>Assets</Typography>
              <FieldArray
                name="assets"
                render={(arrayHelpers) => (
                  <Box>
                    {values.assets &&
                      values.assets.length > 0 &&
                      values.assets.map((_, index: number) => (
                        <Box
                          sx={styles.selectContainer}
                          id={`assets-${index}`}
                          key={`assets-${index}`}
                        >
                          <SelectComp
                            name={`assets[${index}].chain`}
                            label="Select Chain"
                            fullWidth
                            options={chainOptions}
                            labelKey="name"
                            valKey="id"
                            externalStyles={styles.selectComp}
                          />
                          <SelectComp
                            name={`assets[${index}].assetType`}
                            label="Select Asset Type"
                            fullWidth
                            options={assetsOptions}
                            labelKey="name"
                            valKey="id"
                            externalStyles={styles.selectComp}
                          />
                          {values.assets && values.assets.length > 1 && (
                            <RemoveCircleOutlineIcon
                              onClick={() => arrayHelpers.remove(index)}
                            />
                          )}
                        </Box>
                      ))}
                    <ContainedButton
                      title="+ Add Assets"
                      externalStyles={styles.btn}
                      onClick={() => arrayHelpers.push(initialAssetsValue)}
                      primary
                    />
                  </Box>
                )}
              />
              <Box sx={styles.btnContainer}>
                <ContainedButton
                  externalStyles={styles.submitButton}
                  type="submit"
                  title="Save"
                  disabled={!(isValid && dirty)}
                  primary
                />

                <OutlinedButton
                  title="Cancel"
                  externalStyles={styles.submitButton}
                  onClick={() => navigate(-1)}
                  primary
                />
              </Box>
            </>
          </Form>
        )}
      </Formik>
    </Box>
  );
}
