import { Box, Button } from "@mui/material";
import { useSelector } from "react-redux";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";

import PdfDocument from "../invoice";
import { RootState } from "../../redux/store";
import { styles } from "./styles";
import { ChangeEvent } from "react";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { uploadFile } from "../../common/utils";
import Upload from "../upload";
import { setSellPropertyPDFLink } from "../../pages/fo.property/services/slice";
import {
  LockToken,
  confirmSignTransaction,
} from "../../pages/fo.property/services/action";
import { useAppSelector } from "../../hooks/useAppSelector";

/**
 * Component for rendering the BuyPropertyCertModal.
 *
 * @param {object} details - The details of the property.
 * @param {function} closeModal - The function to close the modal.
 * @param {function} handleOpenPaymentModal - The function to open the payment modal.
 * @returns {JSX.Element} BuyPropertyCertModal component.
 */
const BuyPropertyCertModal = ({
  details,
  closeModal,
  handleOpenPaymentModal,
}: any) => {
  const dispatch = useAppDispatch();

  const { noOfToken } = useSelector((state: RootState) => state.property);
  const { userDetails } = useAppSelector((state: RootState) => state.login);

  const handleClick = () => {
    const walletId = userDetails?.walletDetails?.cryptoWalletId;
    const network = "polygonMumbai";

    const lockTokenData = {
      sellOrderId: details._id,
      noOfTokenToBuy: noOfToken,
    };

    dispatch(
      confirmSignTransaction(walletId, network, () => {
        dispatch(
          LockToken(lockTokenData, () => {
            closeModal();
            handleOpenPaymentModal();
          })
        );
      })
    );
  };

  const detailsToSend = {
    tokenValue: details.tokenValue,
    name: details.propertyDetails.name,
    address: details.propertyDetails.address,
    ownerName: details.userDetails.name,
    ownerIdentityId: details.userDetails.personalIdentity,
  };

  return (
    <Box sx={{ ...styles.modalStyle, minWidth: "700px", minHeight: "650px" }}>
      <Box sx={styles.modalContentStyling}>
        <PDFViewer width={700} height={500} showToolbar={false}>
          <PdfDocument
            details={detailsToSend}
            invoiceData={noOfToken}
            isBuying
          />
        </PDFViewer>

        <Box sx={styles.btnContainer}>
          <Button sx={styles.certBtn}>
            <PDFDownloadLink
              document={
                <PdfDocument
                  details={detailsToSend}
                  invoiceData={noOfToken}
                  isBuying
                />
              }
              fileName={"certificate.pdf"}
            >
              {({ blob, url, loading, error }) => (
                <>
                  {loading ? "Loading..." : "Download Invoice"}
                  {console.log(blob, url)}
                </>
              )}
            </PDFDownloadLink>
          </Button>
          <Button>
            <Upload
              name={"pdf"}
              accept={"application/pdf"}
              handleImageChange={(evt: ChangeEvent<HTMLInputElement>) => {
                if (evt?.target?.files && evt.target.files.length > 0) {
                  dispatch(
                    uploadFile(evt.target.files[0], (data: any) => {
                      dispatch(setSellPropertyPDFLink(data.s3Url));
                    })
                  );
                }
              }}
              imageName={"Upload Certificate"}
            />
          </Button>
          <Button sx={styles.certBtn} onClick={handleClick}>
            Sign Transaction
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default BuyPropertyCertModal;
