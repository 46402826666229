import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { Paths } from "../../../common/constants";
import { commonTableStyles as styles } from "../styles";
import ContainedButton from "../../button/contained-button";

/**
 * Component for rendering fund table.
 *
 * @param {object} data - The data for the table.
 * @returns {JSX.Element} The rendered component.
 */
const FundListing = ({ listingData, handleOpenModal, setCardId }: any) => {
  const location = useLocation();
  const handlePayBtnClick = (id: any) => {
    setCardId(id);
    handleOpenModal();
  };

  const isCard = location.pathname.includes(Paths.FORTRESS_ADD_FUND_CARD);

  return (
    <TableContainer>
      <Table sx={styles.table} stickyHeader aria-label="customized table">
        <TableHead sx={styles.tableHeader}>
          <TableRow sx={styles.headerRow}>
            <TableCell>Account ID</TableCell>
            <TableCell>Account Number</TableCell>
            <TableCell>Fund Type</TableCell>
            {isCard && <TableCell>Card Verification Status</TableCell>}
            {isCard && <TableCell>Network</TableCell>}
            <TableCell></TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {listingData && listingData.length > 0 ? (
            listingData.map((data: any) => (
              <TableRow key={data._id} sx={styles.row}>
                <TableCell>
                  <Link
                    to={`${Paths.FORTRESS_CARD_DETAIL}?id=${data.externalAccountId}`}
                  >
                    <Typography sx={styles.link}>
                      {data.externalAccountId}
                    </Typography>
                  </Link>
                </TableCell>
                <TableCell>
                  <Typography>{`**** **** **** ${data.accountNumberLast4}`}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{data.type}</Typography>
                </TableCell>
                {isCard && (
                  <TableCell>
                    <Typography>
                      {data.cardVerificationStatus
                        ? data.cardVerificationStatus
                        : "-"}
                    </Typography>
                  </TableCell>
                )}
                {isCard && (
                  <TableCell>
                    <Typography>{data.network ? data.network : "-"}</Typography>
                  </TableCell>
                )}

                <TableCell>
                  <ContainedButton
                    title="Pay"
                    primary
                    onClick={() => handlePayBtnClick(data.externalAccountId)}
                  />
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={14} align="center">
                <h3>No Records</h3>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default FundListing;
