import { COLORS } from "../../common/constants";

const styles = {
  uploadImage: {
    display: "flex",
    minWidth: "150px",
    alignItems: "center",
    justifyContent: "center",
    padding: "10px",
    backgroundColor: COLORS.white,
    borderRadius: "6px",
    cursor: "pointer",
    border: `1px solid ${COLORS.primary}`,
    "& p": {
      marginLeft: "10px",
      font: "normal normal 500 14px/18px SofiaPro",
      color: COLORS.primary,
    },
  },
  uploadImageContainer: {
    display: "flex",
    alignItems: "center",
    "> p": {
      marginLeft: "10px",
      font: "normal normal 600 14px/18px SofiaPro",
      textDecoration: "underline",
      cursor: "pointer",
    },
  },
  inputFile: { display: "none" },
};

export default styles;
