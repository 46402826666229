import { Box } from "@mui/material";

import styles from "./styles";
import { Paths } from "../../../common/constants";
import GeneralTab from "../../../components/tabs";
import ENUM from "../../../common/constants/constant.enum";
import BusinessIDDetailFortress from "./businessDetails";
import BusinessDetailHelper from "./helper";
import BusinessDetailTab from "./businessDetailTab";
import AccountDetailTab from "./AccountTab";
import WalletDetailTab from "./walletDetailTab";

/**
 * Defines Business Details Component
 * @returns The Business Details Component
 */
const BusinessDetails = ({ tabIndex }: any) => {
  const { id } = BusinessDetailHelper();
  const tabList = [
    {
      label: "Details",
      href: `${Paths.FORTRESS_BUSINESS_DETAILS}?id=${id}`,
    },
    {
      label: "Accounts",
      href: `${Paths.FORTRESS_BUSINESS_ACCOUNTS}?id=${id}`,
    },
    {
      label: "Wallets",
      href: `${Paths.FORTRESS_BUSINESS_WALLETS}?id=${id}`,
    },
  ];

  return (
    <Box sx={styles.mainContainer}>
      <BusinessIDDetailFortress />
      <Box sx={styles.fortressContentContainer}>
        <GeneralTab tabItems={tabList} type={tabIndex} />
        {tabIndex === ENUM.TAB.accounts && <AccountDetailTab />}
        {tabIndex === ENUM.TAB.details && <BusinessDetailTab />}
        {tabIndex === ENUM.TAB.wallets && <WalletDetailTab />}
      </Box>
    </Box>
  );
};

export default BusinessDetails;
