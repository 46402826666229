import { AxiosResponse } from "axios";
import { Dispatch } from "@reduxjs/toolkit";

import { setLoading } from "../../../redux/global.slice";
import { showAlert } from "../../../common/utils/alert";
import { EndPoint, getApiCall, postApiCall } from "../../../common/constants";
import { apiSuccessCode } from "../../../common/constants/axios.instance";
import {
  setFundCardDetails,
  setFundListing,
  setPaymentData,
  setPaymentDetails,
  setPaymentResult,
  setAllUsersFundListing,
  setLinkToken,
} from "./slice";
import { RootState } from "../../../redux/store";

// Action to retrieve all external accounts for a specific identity and type
export const getAllExternalAccounts =
  (identityId: string, type: string) =>
  (dispatch: Dispatch<any>, getState: () => RootState) => {
    if (!navigator.onLine) {
      showAlert(2, "No internet connection!");
      return;
    }
    dispatch(setLoading(true));
    const { page, limit } = getState().fund.externalAccountQueryParameters;
    const params = `?identityId=${identityId}&type=${type}&page=${page}&limit=${limit}`;

    getApiCall(
      `${EndPoint.GetExternalAccounts}${params}`,
      "",
      (response: AxiosResponse<any>) => {
        const { data } = response.data;
        dispatch(setFundListing(data.result));
        dispatch(setLoading(false));
      },
      (error: any) => {
        dispatch(setLoading(false));
        showAlert(2, error.message);
      }
    );
  };

// Action to retrieve the details of a specific external account (card)
export const getFundCardDetails = (id: string) => (dispatch: Dispatch<any>) => {
  if (!navigator.onLine) {
    showAlert(2, "No internet connection!");
    return;
  }
  dispatch(setLoading(true));

  getApiCall(
    `${EndPoint.GetExternalAccount}/${id}`,
    "",
    (response: AxiosResponse<any>) => {
      const { data } = response.data;

      dispatch(setFundCardDetails(data));
      dispatch(setLoading(false));
    },
    (error: any) => {
      dispatch(setLoading(false));
      showAlert(2, error.message);
    }
  );
};

// Action to add an external card account
export const addExternalCard =
  (values: any, id: any, setSubmitting: any, callback?: any) =>
  (dispatch: Dispatch<any>) => {
    dispatch(setLoading(true));

    const {
      firstName,
      lastName,
      street1,
      street2,
      postalCode,
      city,
      state,
      country,
      number,
      expirationMonth,
      expirationYear,
      cvv,
    } = values;

    const dataToSend = {
      identityId: id,
      firstName,
      lastName,
      street1,
      street2,
      postalCode,
      city,
      state,
      country,
      number,
      expirationMonth: Number(expirationMonth),
      expirationYear: Number(expirationYear),
      cvv,
    };

    postApiCall(
      EndPoint.AddExternalAccountCard,
      dataToSend,
      (respData: AxiosResponse<any>) => {
        if (respData.data.statusCode === apiSuccessCode.success) {
          showAlert(1, respData.data.message);
        }
        setSubmitting(false);
        if (callback) callback();
        dispatch(getAllExternalAccounts(id, "card"));
        dispatch(setLoading(false));
      },
      (error: any) => {
        dispatch(setLoading(false));
        setSubmitting(false);
        showAlert(2, error.message);
      }
    );
  };

// Action to retrieve payments
export const getPayments =
  () => (dispatch: Dispatch<any>, getState: () => RootState) => {
    if (!navigator.onLine) {
      showAlert(2, "No internet connection!");
      return;
    }
    dispatch(setLoading(true));
    const { page, limit } = getState().fund.paymentQueryParameters;
    const params = `?page=${page}&limit=${limit}`;
    getApiCall(
      `${EndPoint.Payment}${params}`,
      "",
      (response: AxiosResponse<any>) => {
        const { data } = response.data;
        dispatch(setPaymentData(data));
        dispatch(setPaymentResult(data.result));
        dispatch(setLoading(false));
      },
      (error: any) => {
        dispatch(setLoading(false));
        showAlert(2, error.message);
      }
    );
  };

// Action to create a new payment
export const addPayment =
  ({
    values,
    setSubmitting,
    SCAID = "",
    SEAID = "",
    DCAID = "",
    DEAID = "",
    callback = "",
    handleReleaseToken = "",
  }: any) =>
  (dispatch: Dispatch<any>) => {
    dispatch(setLoading(true));

    const dataToSend = {
      funds: values.funds,
      comment: values.comment,
      useIsa: false,
    };

    if (SCAID !== "")
      Object.assign(dataToSend, { sourceCustodialAccountId: SCAID });
    if (SEAID !== "")
      Object.assign(dataToSend, { sourceExternalAccountId: SEAID });
    if (DCAID !== "")
      Object.assign(dataToSend, { desCustodialAccountId: DCAID });
    if (DEAID !== "")
      Object.assign(dataToSend, { desExternalAccountId: DEAID });

    postApiCall(
      EndPoint.Payment,
      dataToSend,
      (respData: AxiosResponse<any>) => {
        if (respData.data.statusCode === apiSuccessCode.success) {
          showAlert(1, respData.data.message);
          if (callback) callback();
        } else {
          handleReleaseToken();
        }
        setSubmitting(false);
        dispatch(setLoading(false));
      },
      (error: any) => {
        dispatch(setLoading(false));
        setSubmitting(false);
        handleReleaseToken();
        showAlert(2, error.message);
      }
    );
  };

// Action to retrieve the details of a specific payment
export const getPaymentDetails = (id: any) => (dispatch: Dispatch<any>) => {
  if (!navigator.onLine) {
    showAlert(2, "No internet connection!");
    return;
  }
  dispatch(setLoading(true));

  getApiCall(
    `${EndPoint.Payment}/${id}`,
    "",
    (response: AxiosResponse<any>) => {
      const { data } = response.data;

      dispatch(setPaymentDetails(data));
      dispatch(setLoading(false));
    },
    (error: any) => {
      dispatch(setLoading(false));
      showAlert(2, error.message);
    }
  );
};

// Action to create a Plaid external account
export const createPlaidExternalAccount =
  (id: string, processorToken: string, callback?: () => void) =>
  (dispatch: Dispatch<any>) => {
    dispatch(setLoading(true));

    const dataToSend = { identityId: id, processorToken };

    postApiCall(
      EndPoint.CreatePlaidExternalAccount,
      dataToSend,
      (respData: AxiosResponse<any>) => {
        if (respData.data.statusCode === apiSuccessCode.success) {
          showAlert(1, respData.data.message);
          if (callback) callback();
        }
        dispatch(setLoading(false));
      },
      (error: any) => {
        dispatch(setLoading(false));
        showAlert(2, error.message);
      }
    );
  };

// Action to retrieve all users' fund listing
export const getAllUsersExternalAccounts =
  (type: string, callback?: any) =>
  (dispatch: Dispatch<any>, getState: () => RootState) => {
    if (!navigator.onLine) {
      showAlert(2, "No internet connection!");
      return;
    }
    dispatch(setLoading(true));
    const { page, limit } = getState().fund.externalAccountQueryParameters;
    const params = `?type=${type}&page=${page}&limit=${limit}`;

    getApiCall(
      `${EndPoint.GetExternalAccountsTypes}${params}`,
      "",
      (response: AxiosResponse<any>) => {
        const { data } = response.data;
        dispatch(setAllUsersFundListing(data.result));
        if (callback) callback();
        dispatch(setLoading(false));
      },
      (error: any) => {
        dispatch(setLoading(false));
        showAlert(2, error.message);
      }
    );
  };

// Action to create processor token
export const createProcessorToken =
  (accountId: string, accessToken: string, handleCreatePlaid?: any) =>
  (dispatch: Dispatch<any>) => {
    dispatch(setLoading(true));

    const dataToSend = { accessToken, accountId };

    postApiCall(
      EndPoint.CreateProcessorToken,
      dataToSend,
      (respData: AxiosResponse<any>) => {
        if (respData.data.statusCode === apiSuccessCode.success2) {
          const { data } = respData.data;
          handleCreatePlaid(data.processor_token);
        }
        dispatch(setLoading(false));
      },
      (error: any) => {
        dispatch(setLoading(false));
        showAlert(2, error.message);
      }
    );
  };

// Action to public token
export const PublicTokenExchange =
  (token: string, metaData: any, handleCreatePlaid?: any) =>
  (dispatch: Dispatch<any>) => {
    dispatch(setLoading(true));

    const dataToSend = { publicToken: token };

    postApiCall(
      EndPoint.PublicTokenExchange,
      dataToSend,
      (respData: AxiosResponse<any>) => {
        if (respData.data.httpCode === apiSuccessCode.success) {
          showAlert(1, respData.data.message);
          dispatch(
            createProcessorToken(
              metaData.account_id,
              respData?.data?.data?.access_token,
              handleCreatePlaid
            )
          );
        }
        dispatch(setLoading(false));
      },
      (error: any) => {
        dispatch(setLoading(false));
        showAlert(2, error.message);
      }
    );
  };

// Action to create link token
export const createLinkToken = () => (dispatch: Dispatch<any>) => {
  dispatch(setLoading(true));

  const dataToSend = { countryCodes: ["US"] };
  postApiCall(
    EndPoint.CreateLinkToken,
    dataToSend,
    (respData: AxiosResponse<any>) => {
      if (respData.data.statusCode === apiSuccessCode.success2) {
        const { data } = respData.data;

        dispatch(setLinkToken(data.link_token));
      }
      dispatch(setLoading(false));
    },
    (error: any) => {
      dispatch(setLoading(false));
      showAlert(2, error.message);
    }
  );
};
