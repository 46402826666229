import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { RootState } from "../../../redux/store";
import { AssetsOnSellListing } from "../services/action";
import { setAssetOnSellParameters } from "../services/slice";

// Helper Component for Buy Property Component
const useBuyPropertyHelper = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { assetOnSellListing, assetOnSellParameters } = useAppSelector(
    (state: RootState) => state.property
  );

  /**
   * This Function is used to change page
   */
  const handlePageChange = (val: any) => {
    dispatch(setAssetOnSellParameters({ ...assetOnSellParameters, page: val }));
    dispatch(AssetsOnSellListing());
  };

  useEffect(() => {
    dispatch(AssetsOnSellListing());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return { navigate, dispatch, assetOnSellListing, handlePageChange };
};

export default useBuyPropertyHelper;
