import { Box, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";

import Schema from "../../common/constants/schema";
import Input from "../formControls/input";
import { styles } from "./styles";
import ContainedButton from "../button/contained-button";
import OutlinedButton from "../button/outlined-button";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { addPayment } from "../../pages/fund.card/services/action";
import { Paths } from "../../common/constants";

/**
 * Component for rendering the CardPaymentModal.
 *
 * @param {object} CId - The CId of payment.
 * @param {function} closeModal - The function to close the modal.
 * @param {function} externalId - The externalId of account.
 * @returns {JSX.Element} CardPaymentModal component.
 */
const CardPaymentModal = ({ CId, externalId, closeModal }: any) => {
  const initialValues = {
    funds: "",
    comment: "",
  };

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return (
    <Box sx={styles.modalStyle}>
      <Box>
        <Typography sx={styles.modalHeading}>Payment Procedure</Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={Schema.CardPaymentSchema}
          onSubmit={(values, { setSubmitting }) => {
            dispatch(
              addPayment({
                values,
                setSubmitting,
                SEAID: externalId,
                DCAID: CId,
                callback: () => {
                  closeModal();
                  navigate(
                    `${Paths.FORTRESS_CUSTODIAL_ACCOUNT_DETAIL}?id=${CId}`
                  );
                },
              })
            );
          }}
        >
          {(props) => {
            return (
              <Form>
                <Box sx={styles.formContainer}>
                  <Input
                    name="funds"
                    type="text"
                    label="Amount"
                    placeholder="Amount"
                    fullWidth
                    isRequired
                    externalStyles={styles.textField}
                  />
                  <Input
                    name="comment"
                    type="text"
                    label="Comment"
                    placeholder="Comment"
                    fullWidth
                    isRequired
                    externalStyles={styles.textField}
                    multiline
                  />
                </Box>

                <Box sx={styles.btnContainer}>
                  <ContainedButton
                    title={"Save"}
                    externalStyles={styles.btn}
                    size="large"
                    type="submit"
                    fullWidth
                    primary
                  />
                  <OutlinedButton
                    title="Cancel"
                    externalStyles={styles.btn}
                    size="large"
                    fullWidth
                    onClick={() => {
                      closeModal();
                    }}
                  />
                </Box>
              </Form>
            );
          }}
        </Formik>
      </Box>
    </Box>
  );
};

export default CardPaymentModal;
