import { useEffect } from "react";
import { Box, Typography } from "@mui/material";

import { getBusinessContainerFortress } from "../services/action";
import styles from "./styles";
import BusinessDetailHelper from "./helper";
import { getAllIdentities } from "../../fo.dashboard/services/action";

/**
 * Defines Business Id Detail Component
 * @returns The Business Id Detail Component
 */
const BusinessIDDetailFortress = () => {
  const { id, dispatch, businessDetails } = BusinessDetailHelper();
  const { address } = businessDetails;

  useEffect(() => {
    dispatch(getBusinessContainerFortress(id));
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch(getAllIdentities());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box sx={styles.accountSummary_mainContainer}>
      <Box sx={styles.accountSummary_upperCardContainer}>
        <Box sx={styles.accountSummary_cardContentContainer}>
          <Box width="100%">
            <Box sx={styles.accountDetailContainer}>
              <Box sx={styles.headingContainer}>
                <Typography sx={styles.accountName}>
                  {businessDetails?.companyName}
                </Typography>
                <Typography
                  sx={
                    businessDetails?.status !== "active"
                      ? { ...styles.accountStatus, ...styles.pending }
                      : { ...styles.accountStatus, ...styles.active }
                  }
                >
                  {businessDetails?.status}
                </Typography>
                <Typography
                  sx={
                    businessDetails?.kycLevel === "L0"
                      ? { ...styles.accountStatus, ...styles.pending }
                      : { ...styles.accountStatus, ...styles.active }
                  }
                >
                  {businessDetails?.kycLevel}
                </Typography>
              </Box>
            </Box>
            <Box sx={styles.accountSummary_detailsContainer}>
              <Box sx={styles.textContainer}>
                <Typography sx={styles.textHeading}>Identity Type</Typography>
                <Typography>{businessDetails.type}</Typography>
              </Box>
              <Box sx={styles.textContainer}>
                <Typography sx={styles.textHeading}>Identity ID</Typography>
                <Typography>{businessDetails.id}</Typography>
              </Box>
              <Box sx={styles.textContainer}>
                <Typography sx={styles.textHeading}>Email</Typography>
                <Typography>{businessDetails?.email}</Typography>
              </Box>
              <Box sx={styles.textContainer}>
                <Typography sx={styles.textHeading}>Website</Typography>
                <Typography>{businessDetails.website}</Typography>
              </Box>

              <Box sx={styles.textContainer}>
                <Typography sx={styles.textHeading}>Address</Typography>
                <Typography>{`${address.street1} , ${address.street2} , ${address.city} , ${address.state} , ${address.country} , ${address.postalCode}`}</Typography>
              </Box>

              <Box sx={styles.textContainer}>
                <Typography sx={styles.textHeading}>
                  Region Of Formation
                </Typography>
                <Typography>{businessDetails?.regionOfFormation}</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default BusinessIDDetailFortress;
