import { useField, useFormikContext } from "formik";
import { Box } from "@mui/material";
import OtpInput, { OtpInputProps } from "react18-input-otp";
import { FC, memo } from "react";
import { COLORS } from "../../../common/constants/colors";
import { FontFamily } from "../../../common/utils/common.fns";

// OTPInput component definition
const OTPInput: FC<{ name: string }> = ({ name }) => {
  // Use formik's `useField` hook to manage the input field's state and validation
  const [field] = useField(name);
  const { setFieldValue } = useFormikContext();

  // Define attributes for the OtpInput component
  const attributes: OtpInputProps = {
    numInputs: 4,
    id: "otp-input",
    isInputNum: true,
    value: field.value,
    shouldAutoFocus: true,
    className: "otp-input",
    inputStyle: {
      width: "48px",
      outline: "none",
      minHeight: "48px",
      marginRight: "16px",
      color: COLORS.primary,
      background: "transparent",
      font: FontFamily(400, 16, 16),
      border: `1px solid ${COLORS.primary}`,
    },
    onChange: (value: any) => {
      setFieldValue(name, value, true);
    },
  };

  // Render the component
  return (
    <Box>
      <OtpInput {...attributes} />
    </Box>
  );
};

export default memo(OTPInput);
