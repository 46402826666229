import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { RootState } from "../../../redux/store";
import {
  getAccountBalance,
  getCustodialAccountDetailFortress,
  getCustodialAccountListing,
  getTransactionListing,
} from "../services/action";
import {
  getAllExternalAccounts,
  getAllUsersExternalAccounts,
} from "../../fund.card/services/action";
import { setTransactionQueryParameters } from "../services/slice";

const CustodialAccountHelper = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const type = searchParams.get("type");
  const id = searchParams.get("id");

  const [openTransferSelfModal, setTransferSelfModal] = useState(false);
  const [openTransferExternalModal, setTransferExternalModal] = useState(false);
  const [openBalanceModal, setOpenBalanceModal] = useState(false);
  const [modalType, setModalType] = useState("");

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [anchorElTransfer, setAnchorElTransfer] =
    useState<HTMLButtonElement | null>(null);

  useEffect(() => {
    if (id) {
      dispatch(getCustodialAccountDetailFortress(id));
    }
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setModalType("");
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const {
    custodialAccountDetails: CD,
    transactionListing,
    custodialAccountListing,
    usersCustodialAccountListing,
    accountBalance,
    transactionQueryParameters,
  } = useAppSelector((state: RootState) => state.business);

  const { fundListing, usersFundListing } = useAppSelector(
    (state: RootState) => state.fund
  );

  useEffect(() => {
    if (CD.ownerIdentityId) dispatch(getTransactionListing(CD.ownerIdentityId));
  }, [CD]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickTransfer = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElTransfer(event.currentTarget);
  };

  const handleCloseTransfer = () => {
    setAnchorElTransfer(null);
  };

  const handleCloseSelfModal = () => {
    setTransferSelfModal(false);
  };

  const handleOpenSelfModal = () => {
    setTransferSelfModal(true);
  };

  const handleCloseExternalModal = () => {
    setTransferExternalModal(false);
  };

  const handleOpenExternalModal = () => {
    setTransferExternalModal(true);
  };

  const handleTransferBtnClick = (type: string) => {
    dispatch(getAllExternalAccounts(CD.ownerIdentityId, type));
    handleOpenSelfModal();
  };

  const handleModalType = (reqType: string) => {
    setModalType(reqType);
  };

  const handleTransferCusBtnClick = (reqType: string) => {
    dispatch(
      getCustodialAccountListing(() => {
        handleModalType(reqType);
        handleOpenExternalModal();
      })
    );
  };

  const handleTransferExtBtnClick = (reqType: string) => {
    if (CD) dispatch(getAllExternalAccounts(CD.ownerIdentityId, "financial"));
    dispatch(
      getAllUsersExternalAccounts("financial", () => {
        handleModalType(reqType);
        handleOpenExternalModal();
      })
    );
  };

  const handleExternalAccountsListing = () => {
    const reqFundList: any = fundListing.map((list) => {
      return list.externalAccountId;
    });

    return usersFundListing.filter(
      (list: any) => !reqFundList.includes(list.externalAccountId)
    );
  };

  const handleCustodialAccountListing = () =>
    usersCustodialAccountListing.filter((list) => list.accountId !== id);

  const handleCloseBalanceModal = () => {
    setOpenBalanceModal(false);
  };

  const handleOpenBalanceModal = () => {
    if (id) dispatch(getAccountBalance(id, () => setOpenBalanceModal(true)));
    // setOpenBalanceModal(true);
  };

  /**
   * This Function is used to change page
   */
  const handlePageChange = (val: any) => {
    dispatch(
      setTransactionQueryParameters({
        ...transactionQueryParameters,
        page: val,
      })
    );
    if (CD.ownerIdentityId) dispatch(getTransactionListing(CD.ownerIdentityId));
  };

  return {
    navigate,
    dispatch,
    id,
    type,
    CD,
    transactionListing,
    custodialAccountListing,
    fundListing,
    usersFundListing,
    anchorEl,
    anchorElTransfer,
    openTransferSelfModal,
    openTransferExternalModal,
    modalType,
    setAnchorEl,
    setAnchorElTransfer,
    handleClick,
    handleClose,
    handleClickTransfer,
    handleCloseTransfer,
    handleCloseSelfModal,
    handleCloseExternalModal,
    handleTransferBtnClick,
    handleTransferCusBtnClick,
    handleTransferExtBtnClick,
    handleExternalAccountsListing,
    handleCustodialAccountListing,

    openBalanceModal,
    handleOpenBalanceModal,
    handleCloseBalanceModal,
    accountBalance,
    handlePageChange,
  };
};

export default CustodialAccountHelper;
