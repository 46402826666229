import { FontFamily } from "../../../common/utils";

export const styles = {
  CD_mainContainer: {
    width: "85%",
    margin: "1rem auto",
  },
  accountSummary_cardContentContainer: {
    width: "100%",
    display: "flex",
  },
  accountDetailContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: "20px",
  },
  accountSummary_detailsContainer: {
    padding: "1rem 0",
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },

  FD_detailsContainer: {
    width: "100%",
    display: "grid",
    gridTemplateColumns: "50% 50%",
    gap: "1rem",
  },

  FD_heading: {
    font: FontFamily(700, 26, 30),
    letterSpacing: "1.5px",
    marginBottom: "1rem",
  },

  textContainer: {
    flexBasis: "25%",
    marginBottom: "1rem",
  },
  textHeading: {
    fontSize: "20px",
    fontWeight: 550,
  },
};
