// Import required dependencies from external libraries and local files
import { Button, ButtonProps } from "@mui/material";
import { OutlinedButtonProps } from "./outlined.type";
import styles from "./outlined.style";

// Define a custom OutlinedButton component
const OutlinedButton = ({
  title,
  externalStyles,
  primary = true,
  ...otherProps
}: OutlinedButtonProps & ButtonProps) => {
  // Render a Button component with variant "outlined" and apply styling based on primary prop
  return (
    <Button
      disableElevation
      variant="outlined"
      sx={
        primary
          ? { ...styles.btnPrimary, ...externalStyles }
          : { ...styles.btnNormal, ...externalStyles }
      }
      {...otherProps}
    >
      {title}
    </Button>
  );
};

// Export the OutlinedButton component as the default export
export default OutlinedButton;
