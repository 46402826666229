import { Page, Document, StyleSheet, View, Text } from "@react-pdf/renderer";

interface Props {
  details: any;
  invoiceData: any;
  isBuying?: boolean;
}
// PDF Styles Defination
const pdfStyles = StyleSheet.create({
  page: {
    backgroundColor: "#fff",
    fontFamily: "Helvetica",
    fontSize: 12,
    paddingTop: 30,
    paddingLeft: 50,
    paddingRight: 50,
    lineHeight: 1.5,
    flexDirection: "column",

    "& span": {
      fontSize: 16,
      fontWeight: 600,
    },
  },
  titleContainer: {
    marginTop: 10,
    marginBottom: 10,
  },
  reportTitle: {
    color: "#3393CF",
    letterSpacing: 1,
    fontSize: 24,
    fontWeight: 800,
    textAlign: "center",
    textTransform: "uppercase",
  },

  heading: {
    fontSize: 18,
    fontWeight: 600,
    marginTop: 10,
    marginBottom: 10,
  },
});

// Creating PDF View for Invoice Title
const InvoiceTitle = () => (
  <View style={pdfStyles.titleContainer}>
    <Text style={pdfStyles.reportTitle}>CERTIFICATE</Text>
    <Text style={pdfStyles.reportTitle}>OF</Text>
    <Text style={pdfStyles.reportTitle}>CONFIRMATION</Text>
  </View>
);

// Creating PDF View for Buy Invoice
const BuyInvoiceData = ({ details, token }: any) => (
  <View>
    <Text>
      {`I here by declare that I'm buying ${token} token of this property at a token value of ${details.tokenValue}`}
    </Text>

    <Text style={pdfStyles.heading}>Property Details</Text>

    <Text>{`Property Name: ${details?.name}`}</Text>
    <Text>{`Property Address: ${details?.address}`}</Text>

    <Text>{`Owner Name: ${details?.ownerName}`}</Text>
    <Text>{`Owner Identity Id: ${details?.ownerIdentityId}`}</Text>
  </View>
);

// Creating PDF View for Sell Invoice
const SellInvoiceData = ({ details, token, tokenValue }: any) => (
  <View>
    <Text>{`I here by declare that I'm selling ${token} token of this property at a token value of ${tokenValue}.`}</Text>

    <Text style={pdfStyles.heading}>Property Details</Text>

    <Text>{`Property Name: ${details?.name}`}</Text>
    <Text>{`Owner Identity Id: ${details?.identityId}`}</Text>
  </View>
);

// Creating PDF Document
const PdfDocument = ({ details, invoiceData, isBuying }: Props) => {
  return (
    <Document>
      <Page size="A5" style={pdfStyles.page}>
        <InvoiceTitle />
        {isBuying ? (
          <BuyInvoiceData details={details} token={invoiceData} />
        ) : (
          <SellInvoiceData
            details={details}
            token={invoiceData?.noOfTokenToSell}
            tokenValue={invoiceData?.tokenValue}
          />
        )}
      </Page>
    </Document>
  );
};

export default PdfDocument;
