import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import { Paths } from "../../../common/constants";
import { commonTableStyles as styles } from "../styles";
import moment from "moment";

/**
 * Component for rendering transaction table.
 *
 * @param {object} data - The data for the table.
 * @returns {JSX.Element} The rendered component.
 */
const TransactionListing = ({ listingData }: any) => {
  return (
    <TableContainer>
      <Table sx={styles.table}>
        <TableHead sx={{ ...styles.tableHeader }}>
          <TableRow sx={styles.headerRow}>
            <TableCell>Payment ID</TableCell>
            <TableCell>Date Created</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Source</TableCell>
            <TableCell>Destination</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Asset</TableCell>
            <TableCell>Amount</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {listingData && listingData.length > 0 ? (
            listingData.map((data: any) => (
              <TableRow key={data.id} sx={{ ...styles.row }}>
                <TableCell>
                  <Link
                    to={`${Paths.FORTRESS_TRANSACTION_DETAIL}?id=${data.id}`}
                  >
                    <Typography sx={{ ...styles.link }}>
                      {data?.paymentId}
                    </Typography>
                  </Link>
                </TableCell>
                <TableCell>
                  <Typography>
                    {moment(data?.createdAt).format("YYYY-MM-DD")}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography>{data?.paymentType}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>
                    {data?.source?.custodialAccountId ??
                      data?.source?.externalAccountId}
                  </Typography>
                </TableCell>

                <TableCell>
                  <Typography>
                    {data?.destination?.custodialAccountId}
                  </Typography>
                </TableCell>

                <TableCell>
                  <Typography>{data?.status}</Typography>
                </TableCell>

                <TableCell>
                  <Typography>{data?.currency}</Typography>
                </TableCell>

                <TableCell>
                  <Typography>{data?.amount}</Typography>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={14} align="center">
                <h3>No Records</h3>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TransactionListing;
