import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useAppDispatch } from "../../../hooks/useAppDispatch";

// Helper Complete Business KYC Component
const CompleteBusinessKYCFortressHelper = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const id = location.search.split("=")[1];

  const initialValues = {
    doc_type: "",
    front: "",
    back: "",
    self: "",
  };

  return {
    navigate,
    dispatch,
    initialValues,
    id,
  };
};

export default CompleteBusinessKYCFortressHelper;
