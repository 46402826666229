import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { getOAuthTokenFortress } from "../services/action";
import { getProfile } from "../../login/services/action";
import { RootState } from "../../../redux/store";

// Helper Component for Complete Fortress KYC Personal Component
const CompleteFortressKYCPersonalHelper = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { userDetails } = useAppSelector((state: RootState) => state.login);
  const initialValues = {
    doc_type: "",
    front: "",
    back: "",
    self: "",
  };

  useEffect(() => {
    dispatch(getOAuthTokenFortress());
    dispatch(getProfile());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    navigate,
    dispatch,
    initialValues,
    userDetails,
  };
};

export default CompleteFortressKYCPersonalHelper;
