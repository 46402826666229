import { PaperProps } from "@mui/material";
import styles from "./styles";

const ITEM_HEIGHT = 48;

const ITEM_PADDING_TOP = 8;

export const MenuAttributes = (onScroll: (evt: any) => void) => {
  return {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
      onScroll,
    } as PaperProps,
    sx: styles.select,
    autoFocus: false,
  };
};

export const GetKeyValue = (
  listItem: string | { [key: string]: any },
  index: number,
  valKey: string
) => {
  if (typeof listItem === "string") return `row-${index + 1}`;
  return listItem[valKey];
};

export const GetListValue = (listItem: string | { [key: string]: any }, valKey: string) => {
  if (typeof listItem === "string") return listItem;
  return listItem[valKey];
};

export const GetListText = (labelKey: string, listItem: string | { [key: string]: any }) => {
  if (typeof listItem === "string") return listItem;
  return labelKey ? listItem[labelKey] : "";
};
