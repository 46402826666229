import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../hooks/useAppDispatch";

// Helper Component for Reset Password Component
const ResetPasswordHelper = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const initialValue = {
    password: "",
  };

  return { navigate, initialValue, dispatch };
};

export default ResetPasswordHelper;
