import { Fragment } from "react";
import { Box, Tab, Typography } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";

import styles from "./styles";
import { useDashboardHelper } from "./helper";
import BusinessIdentities from "../../components/tables/businessIdentities";
import AccountDetailFortress from "../fo.forms/accountSummaryFortress";
import Cards from "../../components/card";
import AddCustodialAccount from "../../components/form/custodial-account/custodialAccountFortress";
import ContainedButton from "../../components/button/contained-button";
import { Paths } from "../../common/constants";

/**
 * Defines Documents Container Page
 * @returns The Documents Container Component
 */
const DocumentsContainer = ({ documents, navigate }: any) => {
  return (
    <Box sx={{ position: "relative" }}>
      <Typography sx={styles.docHeading}>Documents Uploaded</Typography>

      {documents && documents.length > 0 ? (
        documents.map((doc: any) => {
          return (
            <Box key={doc.id} sx={styles.docContainer}>
              <Box sx={styles.textContainer}>
                <Typography sx={styles.textHeading}>Document Type: </Typography>
                <Typography sx={styles.textData}>
                  {doc.personalDocumentType}
                </Typography>
              </Box>

              <Box sx={styles.textContainer}>
                <Typography sx={styles.textHeading}>
                  Document Verified:{" "}
                </Typography>
                <Typography sx={styles.textData}>
                  {doc.isVerified ? "YES " : "NO"}
                </Typography>
              </Box>

              <Box sx={styles.textContainer}>
                <Typography sx={styles.textHeading}>
                  Document Staus:{" "}
                </Typography>
                <Typography sx={styles.textData}>
                  {doc.documentCheckStatus}
                </Typography>
              </Box>
            </Box>
          );
        })
      ) : (
        <Fragment>
          <ContainedButton
            title="Complete your KYC"
            externalStyles={styles.KYCBtn}
            onClick={() => navigate(Paths.FORTRESS_COMPLETE_KYC)}
          />
          <Typography>No Documents Uploaded</Typography>
        </Fragment>
      )}
    </Box>
  );
};

/**
 * Defines Fortress Dashboard Page
 * @returns The Fortress Dashboard Component
 */
const FortressDashboard = () => {
  const { handleTypeChange, type, userDetails, personalDetails, navigate } =
    useDashboardHelper();

  return (
    <Box sx={styles.dashboard_mainContainer}>
      {!userDetails?.identityContainerId ? (
        <Box>
          <Typography sx={styles.heading}>Update your Details</Typography>
          <AddCustodialAccount />
        </Box>
      ) : (
        <TabContext value={type}>
          <Box>
            <TabList onChange={handleTypeChange} sx={styles.tabs}>
              <Tab label="Personal" value="1" sx={styles.tab} />
              <Tab label="Business" value="2" sx={styles.tab} />
            </TabList>
          </Box>
          <TabPanel value="1">
            <Box sx={styles.personalIdentityContainer}>
              {userDetails?.identityContainerId && <AccountDetailFortress />}
              <Cards
                childComp={
                  <DocumentsContainer
                    documents={personalDetails.documents}
                    navigate={navigate}
                  />
                }
              />
            </Box>
          </TabPanel>
          <TabPanel value="2" sx={{ position: "relative" }}>
            <Box sx={styles.businessIdentityContainer}>
              <Cards
                childComp={
                  <Fragment>
                    <ContainedButton
                      title="Create New Business Account"
                      onClick={() => {
                        navigate(Paths.FORTRESS_GET_BUSINESS_ID);
                      }}
                    />
                    <Typography sx={styles.heading}>
                      Your Business ID's
                    </Typography>
                    <BusinessIdentities />
                  </Fragment>
                }
              />
            </Box>
          </TabPanel>
        </TabContext>
      )}
    </Box>
  );
};

export default FortressDashboard;
