import { Box } from "@mui/material";

import styles from "./styles";
import WalletDetailsComp from "./walletDetails";

/**
 * Defines Wallet Details Page
 * @returns The Wallet Details Component
 */
const WalletDetails = () => {
  return (
    <Box sx={styles.mainContainer}>
      <WalletDetailsComp />
    </Box>
  );
};

export default WalletDetails;
