import { Box, Typography } from "@mui/material";
import { Form, Formik } from "formik";

import Schema from "../../common/constants/schema";
import Input from "../formControls/input";
import { styles } from "./styles";
import ContainedButton from "../button/contained-button";
import OutlinedButton from "../button/outlined-button";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { getExchangeAmount } from "../../pages/fo.property/services/action";
import { AssetOnSellDetails } from "../../pages/fo.property/services/type";
import { setNoOfToken } from "../../pages/fo.property/services/slice";

interface PropertyBuyModalType {
  details: AssetOnSellDetails;
  closeModal: () => void;
  handleOpenPaymentModal: () => void;
}

/**
 * Component for rendering the PropertyBuyModal.
 *
 * @param {object} details - The details of the property.
 * @param {function} closeModal - The function to close the modal.
 * @param {function} handleOpenPaymentModal - The function to open the payment modal.
 * @returns {JSX.Element} PropertyBuyModal component.
 */
const PropertyBuyModal = ({
  details,
  closeModal,
  handleOpenPaymentModal,
}: PropertyBuyModalType) => {
  const initialValues = {
    noOfTokenToBuy: "",
  };

  const dispatch = useAppDispatch();

  return (
    <Box sx={styles.modalStyle}>
      <Box>
        <Typography sx={styles.modalHeading}>
          Available Tokens : {details?.leftNoOfTokenToSell}
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={Schema.PropertyBuySchema(
            Number(details?.leftNoOfTokenToSell)
          )}
          onSubmit={(values) => {
            const dataToSend = {
              tokenValueInUsd: details?.tokenValue,
              totalToken: values.noOfTokenToBuy,
            };
            dispatch(setNoOfToken(values.noOfTokenToBuy));

            dispatch(
              getExchangeAmount(dataToSend, () => {
                closeModal();
                handleOpenPaymentModal();
              })
            );
          }}
        >
          {() => {
            return (
              <Form>
                <Box sx={styles.formContainer}>
                  <Input
                    name="noOfTokenToBuy"
                    type="text"
                    label="No. of tokens you want to buy"
                    placeholder="No. of tokens you want to buy"
                    fullWidth
                    isRequired
                    externalStyles={styles.textField}
                  />
                </Box>
                <Box sx={styles.btnContainer}>
                  <ContainedButton
                    title={"Save"}
                    externalStyles={styles.btn}
                    size="large"
                    type="submit"
                    fullWidth
                    primary
                  />
                  <OutlinedButton
                    title="Cancel"
                    externalStyles={styles.btn}
                    size="large"
                    fullWidth
                    onClick={() => {
                      closeModal();
                    }}
                  />
                </Box>
              </Form>
            );
          }}
        </Formik>
      </Box>
    </Box>
  );
};

export default PropertyBuyModal;
