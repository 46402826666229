import React from "react";
import {
  OutlinedInput,
  FormControl,
  Select,
  SelectChangeEvent,
  Box,
  MenuItem,
  Checkbox,
  ListItemText,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ErrorIcon from "@mui/icons-material/Error";
import { useField } from "formik";

import styles from "./styles";
import { MutipleSelectProps } from "./type";
import {
  RenderValue,
  MenuAttributes,
  HandleChange,
  GetCheckedValue,
  GetKeyValue,
  GetListValue,
  GetListText,
} from "./helper";
import { COLORS } from "../../common/constants";

/* eslint-disable react/no-unstable-nested-components */

// Define a custom Multi Select component
export default function MultipleSelectCheckmarks({
  name,
  placeholder,
  id,
  options,
  valKey = "",
  values,
  setValue,
  customStyle,
  onScroll,
  externalStyles = {},
  isRequired = true,
}: MutipleSelectProps) {
  // Use formik's `useField` hook to manage the input field's state and validation
  const [field, meta] = useField(name);

  return (
    <Box sx={{ width: "25%", marginBottom: "1rem" }}>
      <FormControl fullWidth sx={[styles.formControl, customStyle]}>
        {placeholder && (
          <Typography
            component="div"
            sx={{
              ...styles.label,
              ...((field.value.length === 0 || field.value === "") && {
                ...styles.none,
              }),
            }}
          >
            {`${placeholder}${isRequired ? "*" : ""}`}
          </Typography>
        )}
        <Select
          id={id}
          multiple
          name={name}
          displayEmpty
          value={values}
          error={meta.touched && Boolean(meta.error)}
          input={<OutlinedInput />}
          MenuProps={
            onScroll
              ? MenuAttributes(onScroll)
              : {
                  PaperProps: {
                    style: styles.paper,
                    sx: styles.select,
                  },
                }
          }
          inputProps={{ "aria-label": "Without label" }}
          onChange={(evt: SelectChangeEvent<typeof values>) =>
            HandleChange(options, evt, setValue)
          }
          renderValue={(selected: Array<string | { [key: string]: any }>) =>
            RenderValue(valKey, placeholder, selected)
          }
          IconComponent={(props) =>
            meta.touched && Boolean(meta.error) ? (
              <>
                <ErrorIcon {...props} sx={styles.errorIcon} />
                <ExpandMoreIcon {...props} />
              </>
            ) : (
              <ExpandMoreIcon {...props} />
            )
          }
        >
          {options?.map(
            (listItem: string | { [key: string]: any }, index: number) => {
              return (
                <MenuItem
                  key={GetKeyValue(listItem, index)}
                  value={GetListValue(listItem)}
                  autoFocus={index === 0}
                  sx={styles.menuItem}
                >
                  <Checkbox
                    style={{ color: COLORS.primary }}
                    checked={GetCheckedValue(values, listItem)}
                    onKeyDown={(evt: any) => evt.preventDefault()}
                  />
                  <ListItemText primary={GetListText(valKey, listItem)} />
                </MenuItem>
              );
            }
          )}
        </Select>
      </FormControl>
    </Box>
  );
}
