import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import moment from "moment";
import { Link } from "react-router-dom";
import { Paths } from "../../../common/constants";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { BusinessIdentitylisting } from "../../../pages/fo.dashboard/services/type";
import { RootState } from "../../../redux/store";
import { commonTableStyles as styles } from "../styles";

/**
 * Component for rendering business identities table.
 *
 * @param {object} data - The data for the table.
 * @returns {JSX.Element} BusinessIdentities component.
 */
export default function BusinessIdentities() {
  const { businessIdentityListing } = useAppSelector(
    (state: RootState) => state.dashboard
  );
  return (
    <>
      {businessIdentityListing && businessIdentityListing.length > 0 ? (
        <TableContainer>
          <Table sx={styles.table}>
            <TableHead sx={{ ...styles.tableHeader }}>
              <TableRow sx={styles.headerRow}>
                <TableCell>Business ID</TableCell>
                <TableCell>Company Name</TableCell>
                <TableCell>Created On</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>KYC Level</TableCell>
                <TableCell>Phone No</TableCell>
              </TableRow>
            </TableHead>
            {businessIdentityListing && businessIdentityListing.length > 0 && (
              <TableBody>
                {businessIdentityListing &&
                  businessIdentityListing.length > 0 &&
                  businessIdentityListing.map(
                    (data: BusinessIdentitylisting) => {
                      return (
                        <TableRow
                          key={data.businessIdentity}
                          sx={{ ...styles.row }}
                        >
                          <TableCell>
                            <Link
                              to={`${Paths.FORTRESS_BUSINESS_DETAILS}?id=${data.businessIdentity}`}
                            >
                              <Typography sx={{ ...styles.link }}>
                                {data.businessIdentity}
                              </Typography>
                            </Link>
                          </TableCell>
                          <TableCell>{data.companyName}</TableCell>
                          <TableCell>
                            {moment(data?.createdAt).format("YYYY-MM-DD")}
                          </TableCell>
                          <TableCell>{data.email}</TableCell>
                          <TableCell>{data.kycLevel}</TableCell>
                          <TableCell>{data.phoneNo}</TableCell>
                        </TableRow>
                      );
                    }
                  )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      ) : (
        <>
          <Typography>No Business Id added yet</Typography>
        </>
      )}
    </>
  );
}
